import React, { useState, useEffect } from "react";
import CustomDate from "./sub_components/date";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Scans from "../api/scans";
import { Tooltip } from "@mui/material";
import { ErrorOutlineSharp } from "@mui/icons-material";

const BlockScans = () => {
  const networkIds = [
    {
      network_id: "solana",
    },
    {
      network_id: "bitcoin",
    },
    {
      network_id: "ethereum",
    },
    {
      network_id: "tron",
    },
    {
      network_id: "polygon",
    },
  ];

  const [network_id, setNetworkId] = useState("solana");
  const [blockNumber, setBlockNumber] = useState(1);
  const [error, setError] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [tempPage, setTempPage] = useState(pageNumber);
  const [totalPages, setTotalPages] = useState(0);
  const [statusChanged, setStatusChanged] = useState(false);
  // {
  //   id: 1,
  //   created_at: "2023-03-21T07:14:06.635Z",
  //   updated_at: "2023-03-21T07:14:06.635Z",
  //   block_number: "1",
  //   network_id: "solana",
  //   status: "processing",
  //   remarks: null,
  //   total_transactions: null,
  //   bcw_transactions: null,
  // },
  const [dataToShow, setDataToShow] = useState([]);
  const handleBlockNumber = (value) => {
    setBlockNumber(value);
  };
  const handleChangeId = (value) => {
    setNetworkId(value);
  };
  const handleScan = () => {
    if (blockNumber < 0) {
      setError("ID must be greater than 0");
      return;
    } else {
      setError("");
      Scans.scan_block({
        block_scan: {
          block_number: blockNumber,
          network_id: network_id,
        },
      })
        .then((res) => {
          setError("Scan secessfull");
          setTimeout(() => {
            setError("");
          }, "3000");
          setStatusChanged(!statusChanged);
        })
        .catch((err) => {
          console.log(err.response.status);
          if(err.response.status === 422) {
            setError("Already Scan");
          }
        });
    }
  };
  const decrPage = () => {
    if (tempPage > 1) {
      setPageNumber(parseFloat(pageNumber) - 1);
      setTempPage(pageNumber);
      setTempPage(parseFloat(tempPage) - 1);
    }
  };
  const handleChange = (event) => {
    setTempPage(event.target.value);
  };
  const assignPage = (e) => {
    if (e.key === "Enter") {
      setPageNumber(e.target.value);
    }
  };
  const increasePage = () => {
    if (tempPage < totalPages) {
      setPageNumber(parseFloat(pageNumber) + 1);
      setTempPage(pageNumber);
      setTempPage(parseFloat(tempPage) + 1);
    }
  };
  useEffect(() => {
    setDataLoading(true);
    Scans.get_blocks(pageNumber)
      .then((res) => {
        setDataToShow(res.data.block_scans);
        setTotalPages(res.data.total_pages);
        setPageNumber(res.data.current_page);
        setDataLoading(false);
      })
      .catch((err) => {
        console.log(err.response.status);
      });
  }, [statusChanged,pageNumber]);
  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Block Scan</h1>
        </div>

        <div className="page-filters mb-4">
          <div className="row">
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                Network
              </label>
              <label
                style={{
                  marginLeft: "50px",
                }}
                htmlFor="basic-url"
                className="form-label"
              >
                Block ID
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={network_id}
                  onChange={(e) => {
                    handleChangeId(e.target.value);
                  }}
                >
                  {networkIds.map((network_id) => (
                    <option value={network_id.network_id}>
                      {network_id.network_id}
                    </option>
                  ))}
                </select>
                <input
                  type="number"
                  className="form-control white"
                  id="basic-url"
                  Faria-describedby="basic-addon3"
                  value={blockNumber}
                  onChange={(e) => {
                    handleBlockNumber(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="col-lg-12 col-xl-9">
              <label htmlFor="" className="form-label">
                &nbsp;
              </label>
              <div>
                <Tooltip title="Scan" placement="bottom" arrow>
                  <button
                    className={`primary-btn relative mb-3 me-3`}
                    style={{
                      padding: "8px 11px 7px",
                    }}
                    type="button"
                    onClick={handleScan}
                  >
                    Scan
                  </button>
                </Tooltip>
                {error && <span className="error">{error}</span>}
              </div>
            </div>
          </div>
        </div>
        <div className="text-center rounded p-4 table-bg mb-4">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Block Number</th>
                  <th scope="col">Network ID</th>
                  <th scope="col">Status</th>
                  <th scope="col">Remarks</th>
                  <th scope="col">Total Transactions</th>
                  <th scope="col">BCW Transactions</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Update at</th>
                </tr>
              </thead>
              <tbody>
                {dataLoading ? (
                  <tr>
                    <td colspan="9">
                      <div className="lds-ring">
                        <div></div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  dataToShow.map(
                    ({
                      id,
                      created_at,
                      updated_at,
                      block_number,
                      network_id,
                      status,
                      remarks,
                      total_transactions,
                      bcw_transactions,
                    }) => {
                      return (
                        <tr>
                          <td>{id}</td>
                          <td>{block_number}</td>
                          <td>{network_id}</td>
                          <td>{status}</td>
                          <td>{remarks || "NA"}</td>
                          <td>{total_transactions || "NA"}</td>
                          <td>{bcw_transactions || "NA"}</td>
                          <td>
                            <CustomDate date={created_at} />
                          </td>
                          <td>
                            <CustomDate date={updated_at} />
                          </td>
                        </tr>
                      );
                    }
                  )
                )}
              </tbody>
            </table>
            <div className="pagination-buttons-container width my_row end">
              <div className="next-pre-btn my_row align_center space-between width-15">
                <div className="pre-btn">
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                    className="white"
                    onClick={decrPage}
                  >
                    <ArrowBackIosNewIcon />
                  </button>
                </div>
                <span className="white">
                  <input
                    type="number"
                    value={tempPage}
                    style={{
                      maxWidth: "45px",
                      heigh: "10px",
                      background: "transparent",
                      border: "none",
                      color: "white",
                      textAlign: "center",
                    }}
                    onChange={handleChange}
                    onKeyPress={assignPage}
                  />{" "}
                  / {totalPages}
                </span>
                <div className="next-btn">
                  <button
                    className="white"
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                    onClick={increasePage}
                  >
                    <ArrowForwardIosIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockScans;
