import React from "react";
const CustomDate = (props) => {
  return (
    <>
      {props.date
        ? new Date(props.date).toLocaleString("en-US", {
            timeZone: "Asia/Shanghai",
          })
        : null}
    </>
  );
};
export default CustomDate;
