import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import DepositsAPI from "../api/deposits";
import { useSelector } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Tooltip } from "@mui/material";
import DatePicker from "react-datepicker";
import CustomDate from "./sub_components/date";
import "react-datepicker/dist/react-datepicker.css";

const Deposits = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const [dataToShow, setDataToShow] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [tempPage, setTempPage] = useState(pageNumber);
  const [filter, setFilter] = useState(false);
  const [filterId, setFilterId] = useState("");
  const [idPredicate, setIdPredicate] = useState("eq");
  const [filterUsername, setFilterUsername] = useState("");
  const [usernamePredicate, setUsernamePredicate] = useState("contains");
  const [filterNetwork, setFilterNetwork] = useState("");
  const [networkPredicate, setNetworkPredicate] = useState("cont_all");
  const [filterHash, setFilterHash] = useState("");
  const [hashPredicate, setHashPredicate] = useState("contains");
  const [totalData, setTotalData] = useState(0);
  const [tokenPredicate, setTokenPredicate] = useState("in");
  const [filterToken, setFilterToken] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    setDataLoading(true);
    DepositsAPI.getDeposits(
      pageNumber,
      filter,
      idPredicate,
      filterId,
      usernamePredicate,
      filterUsername,
      filterNetwork,
      networkPredicate,
      filterHash,
      hashPredicate,
      tokenPredicate,
      filterToken,
      startDate,
      endDate
    )
      .then((response) => {
        setDataLoading(true);
        setDataToShow(response.data.list);
        setTotalPages(response.data.total_pages);
        setTotalData(response.data.total_count);
        setDataLoading(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .catch((error) => console.log(error));
  }, [
    pageNumber,
    filter,
    filter && idPredicate,
    filter && filterId,
    filter && usernamePredicate,
    filter && filterUsername,
    filter && filterNetwork,
    filter && networkPredicate,
    filter && filterHash,
    filter && hashPredicate,
    filter && tokenPredicate,
    filter && filterToken,
    filter && startDate,
    filter && endDate,
  ]);
  const increasePage = () => {
    if (tempPage < totalPages) {
      setPageNumber(parseFloat(pageNumber) + 1);
      setTempPage(pageNumber);
      setTempPage(parseFloat(tempPage) + 1);
    }
  };

  const decrPage = () => {
    if (tempPage > 1) {
      setPageNumber(parseFloat(pageNumber) - 1);
      setTempPage(pageNumber);
      setTempPage(parseFloat(tempPage) - 1);
    }
  };
  const handleChange = (event) => {
    setTempPage(event.target.value);
  };

  const assignPage = (e) => {
    if (e.key === "Enter") {
      setPageNumber(e.target.value);
    }
  };
  const tokenStatus = () => {
    if (tokenPredicate === "not_false") {
      setFilterToken(1);
    } else if (tokenPredicate === "false") {
      setFilterToken(1);
    } else if (tokenPredicate === "in") {
      setFilterToken([0,1]);
    } };
  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Deposits</h1>
        </div>
        <div className="page-filters">
          <div className="row">
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                Id
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={idPredicate}
                  onChange={(e) => {
                    setIdPredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="gteq">Greater & =</option>
                  <option value="lteq">Less & =</option>
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterId}
                  onChange={(e) => {
                    setFilterId(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                Username
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={usernamePredicate}
                  onChange={(e) => {
                    setUsernamePredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="contains">contains</option>
                  <option value="start">Start with</option>
                  <option value="end">End with</option>
                  {/* <option value="lteq">Less & =</option> */}
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterUsername}
                  onChange={(e) => {
                    setFilterUsername(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                Network Id
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={networkPredicate}
                  onChange={(e) => {
                    setNetworkPredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="cont_all">contains</option>
                  <option value="start">Start with</option>
                  <option value="end">End with</option>
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterNetwork}
                  onChange={(e) => {
                    setFilterNetwork(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                Tx Hash
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={hashPredicate}
                  onChange={(e) => {
                    setHashPredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="cont_all">contains</option>
                  {/* <option value="lteq">Less & =</option> */}
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterHash}
                  onChange={(e) => {
                    setFilterHash(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                Created At
              </label>
              <div className="input-group mb-3">
                <DatePicker
                  placeholderText="From"
                  selected={startDate}
                  placeholder="from"
                  onChange={(date) => setStartDate(date ? date : "")}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="" className="form-label">
                &nbsp;
              </label>
              <div className="input-group mb-3">
                <DatePicker
                  placeholderText="To"
                  selected={endDate}
                  onChange={(date) => setEndDate(date ? date : "")}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                Token
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text form-control"
                  id="basic-addon3"
                  value={tokenPredicate}
                  onChange={(e) => {
                    setTokenPredicate(e.target.value);
                    // activeStatus();
                  }}
                >
                  <option value="in">Any</option>
                  <option value="not_false">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="" className="form-label">
                &nbsp;
              </label>
              <div>
              <Tooltip
                title={filter ? "Filter Enabled" : "Filter Disabled"}
                placement="right"
                arrow
              >
                <button
                  className={`${filter ? "primary-btn-success" : "primary-btn-disable"} relative primary-btn mb-3`}
                  style={{
                    padding: "8px 11px 7px",
                  }}
                  type="button"
                  onClick={() => {
                    filter ? setFilter(false) : setFilter(true);
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      background: filter ? "rgb(59 193 23)" : "rgb(237, 99, 0)",
                      borderRadius: "50%",
                      right: "-3px",
                      top: "-3px",
                    }}
                    className="filter-status-indicator absolute"
                  ></div>
                  {filter ? "Active" : "Inactive"}
                </button>
              </Tooltip>
              </div>
            </div>
          </div>
          <div className="mb-3">
            Total Deposits {totalData}
          </div>
        </div>
        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Username</th>
                  <th scope="col">Network ID</th>
                  <th scope="col">Token</th>
                  <th scope="col">Top Up</th>
                  <th scope="col">Previous Balance</th>
                  <th scope="col">Transaction Type</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Tx Hash</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {dataLoading ? (
                  <tr>
                    <td colspan="10">
                      <div className="loader-container">
                        <div className="lds-ring">
                          <div></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  dataToShow.map((obj) => {
                    return (
                      <tr>
                        <td>{obj.id}</td>
                        <td>{obj.username}</td>
                        <td>
                          {obj.network_id === "bcd" ? "bcw" : obj.network_id}
                        </td>
                        <td>
                          {obj.network_id == "usdt"
                            ? "USDT"
                            : obj.token
                            ? "USDT"
                            : "False"}
                        </td>

                        <td>{obj.top_up}</td>
                        <td>{obj.previous_balance}</td>
                        <td>{obj.transaction_type}</td>
                        <td>
                          <CustomDate date={obj.created_at} />
                        </td>
                        <td><span className="hash-key-scroll">{obj.tx_hash}</span></td>
                        <td>
                          <Link
                            to={`/deposit-details/${obj.id}`}
                            className="view primary-btn"
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            <div className="pagination-buttons-container width my_row end">
              <div className="next-pre-btn my_row align_center space-between width-15">
                <div className="pre-btn">
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                    className="white"
                    onClick={decrPage}
                  >
                    <ArrowBackIosNewIcon />
                  </button>
                </div>
                <span className="white">
                  <input
                    type="number"
                    value={tempPage}
                    style={{
                      maxWidth: "45px",
                      heigh: "10px",
                      background: "transparent",
                      border: "none",
                      color: "white",
                      textAlign: "center",
                    }}
                    onChange={handleChange}
                    onKeyPress={assignPage}
                  />
                  / {totalPages}
                </span>
                <div className="next-btn">
                  <button
                    className="white"
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                    onClick={increasePage}
                  >
                    <ArrowForwardIosIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // );
};

export default Deposits;