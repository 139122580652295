import axiosClient from ".";

class UserToPoolOperations {
  static failed_user_to_pool(page) {
    return axiosClient.get(
      `/super_admin/api/blockchain_user_pool_transactions.json?page=${page}`
    );
  }
  static transferFunds(id, data) {
    return axiosClient.put(
      `/super_admin/api/blockchain_user_pool_transactions/${id}`,
      data
    );
  }
}

export default UserToPoolOperations;
