import axiosClient from ".";

class AdminUsersAPI {
  static getAdminUser(page, filter, filterId, idPredicate) {
    return axiosClient.get(
      `/super_admin/api/admin_users.json?page=${page}/${
        filter && `&q[id_${idPredicate}]=${filterId}`
      }`
    );
    //   (`/super_admin/api/admin_users/${data.id}.json`);
  }
  static createAdminUser(data) {
    return axiosClient.post(`/super_admin/api/admin_users.json`, data);
    //   (`/super_admin/api/admin_users/${data.id}.json`);
  }
  static viewAdmin(data) {
    return axiosClient.get(`/super_admin/api/admin_users/${data.id}.json`);
  }
  static adminCreditLogs(
    pageNumber,
    filter,
    filterId,
    idPredicate,
    filterEmail,
    emailPredicate,
    startDate,
    endDate
  ) {
    return axiosClient.get(
    `/super_admin/api/fake_credit_logs.json?page=${pageNumber}/${
        filter &&
        `&q[user_id_${idPredicate}]=${filterId}&q[admin_${emailPredicate}]=${filterEmail}&q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}`
      }`
    );
  }
}
export default AdminUsersAPI;
