import React, { useState, useEffect } from "react";
import CustomDate from "./sub_components/date";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Scans from "../api/scans";
import { Tooltip } from "@mui/material";

const TransactionScan = () => {
  const networkIds = [
    {
      network_id: "solana",
    },
    {
      network_id: "bitcoin",
    },
    {
      network_id: "ethereum",
    },
    {
      network_id: "tron",
    },
    {
      network_id: "polygon",
    },
  ];

  const [network_id, setNetworkId] = useState("solana");
  const [txID, setTxID] = useState("");
  const [error, setError] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [tempPage, setTempPage] = useState(pageNumber);
  const [totalPages, setTotalPages] = useState(0);
  const [statusChanged, setStatusChanged] = useState(false);

  // {
  //   id: 1,
  //   created_at: "2023-03-21T07:14:06.635Z",
  //   updated_at: "2023-03-21T07:14:06.635Z",
  //   block_number: "1",
  //   network_id: "solana",
  //   status: "processing",
  //   remarks: null,
  //   to_public_key: null,
  //   from_public_key: null,
  // },
  const [dataToShow, setDataToShow] = useState([]);
  const handleTxID = (value) => {
    setTxID(value);
  };
  const handleChangeId = (value) => {
    setNetworkId(value);
  };
  const handleScan = () => {
    setError("");
    Scans.create_transactions_scan({
      transaction_scan: {
        txid: txID,
        network_id: network_id,
      },
    })
      .then((res) => {
        setStatusChanged(!statusChanged);
        setError("Scan Successfully");
        setTimeout(() => {
          setError("");
        }, "3000");
      })
      .catch((err) => {
        console.log(err.response.status);
          if(err.response.status === 422) {
            setError("Already Scan");
          }
        
      });
  };
  const decrPage = () => {
    if (tempPage > 1) {
      setPageNumber(parseFloat(pageNumber) - 1);
      setTempPage(pageNumber);
      setTempPage(parseFloat(tempPage) - 1);
    }
  };
  const handleChange = (event) => {
    setTempPage(event.target.value);
  };
  const assignPage = (e) => {
    if (e.key === "Enter") {
      setPageNumber(e.target.value);
    }
  };
  const increasePage = () => {
    if (tempPage < totalPages) {
      setPageNumber(parseFloat(pageNumber) + 1);
      setTempPage(pageNumber);
      setTempPage(parseFloat(tempPage) + 1);
    }
  };
  useEffect(() => {
    setDataLoading(true);
    Scans.get_transactions_scans(pageNumber)
      .then((res) => {
        setDataToShow(res.data.transaction_scans);
        setTotalPages(res.data.total_pages);
        setPageNumber(res.data.current_page);
        setDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [statusChanged,pageNumber]);
  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Transaction Scan</h1>
        </div>
        <div className="page-filters">
          <div className="row">
            <div className="col-xl-3 col-lg-6">
              <label htmlFor="basic-url" className="form-label">
                Network
              </label>
              <label
                style={{
                  marginLeft: "60px",
                }}
                htmlFor="basic-url"
                className="form-label"
              >
                TxID
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={network_id}
                  onChange={(e) => {
                    handleChangeId(e.target.value);
                  }}
                >
                  {networkIds.map((network_id) => (
                    <option value={network_id.network_id}>
                      {network_id.network_id}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  F
                  aria-describedby="basic-addon3"
                 
                  value={txID}
                  onChange={(e) => {
                    handleTxID(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="col-xl-9 col-lg-9">
              <label htmlFor="basic-url" className="form-label">
                &nbsp;
              </label>
              <div>
              <Tooltip title="Scan" placement="bottom" arrow>
                <button
                  className={`primary-btn relative me-3 mb-3`}
                  style={{
                    padding: "8px 11px 7px",
                  }}
                  type="button"
                  onClick={handleScan}
                >
                  Scan
                </button>
              </Tooltip>
              {error && <span className="error">{error}</span>}
              </div>
            </div>
          </div>
        </div>
        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Tx-ID</th>
                  <th scope="col">Network ID</th>
                  <th scope="col">Status</th>
                  <th scope="col">Remarks</th>
                  <th scope="col">To Public Key</th>
                  <th scope="col">From Public Key</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Update at</th>
                </tr>
              </thead>
              <tbody>
                {dataLoading ? (
                <tr>
                  <td colspan="9">
                  <div className="loader-container">
                    <div className="lds-ring">
                      <div></div>
                    </div>
                  </div>
                  </td>
                </tr>
                ) : (
                  dataToShow.map(
                    ({
                      id,
                      created_at,
                      updated_at,
                      txid,
                      network_id,
                      status,
                      remarks,
                      from_public_key,
                      to_public_key,
                    }) => {
                      return (
                        <tr>
                          <td>{id}</td>
                          <td><span className="hash-key-scroll">{txid}</span></td>
                          <td>{network_id}</td>
                          <td>{status}</td>
                          <td>{remarks || "NA"}</td>
                          <td><span className="hash-key-scroll">{from_public_key || "NA"}</span></td>
                          <td><span className="hash-key-scroll" >{to_public_key || "NA"}</span></td>
                          <td>
                            <CustomDate date={created_at} />
                          </td>
                          <td>
                            <CustomDate date={updated_at} />
                          </td>
                        </tr>
                      );
                    }
                  )
                )}
              </tbody>
            </table>
            <div className="pagination-buttons-container width my_row end">
              <div className="next-pre-btn my_row align_center space-between width-15">
                <div className="pre-btn">
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                    className="white"
                    onClick={decrPage}
                  >
                    <ArrowBackIosNewIcon />
                  </button>
                </div>
                <span className="white">
                  <input
                    type="number"
                    value={tempPage}
                    style={{
                      maxWidth: "45px",
                      heigh: "10px",
                      background: "transparent",
                      border: "none",
                      color: "white",
                      textAlign: "center",
                    }}
                    onChange={handleChange}
                    onKeyPress={assignPage}
                  />{" "}
                  / {totalPages}
                </span>
                <div className="next-btn">
                  <button
                    className="white"
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                    onClick={increasePage}
                  >
                    <ArrowForwardIosIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionScan;
