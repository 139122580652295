import consumer from "./consumer";
import { store } from "../store";
import { clearBetData } from "../store/bet";
import { setTotalBetAmount } from "../store/bet";
const walletStatusSubscribe = () => {
  consumer.subscriptions.create(
    {
      channel: "WalletStatusChannel",
    },
    {
      received: (data) => {},
    }
  );
};
export { walletStatusSubscribe };
