import React, { useEffect, useState } from "react";
import PlayersAPI from "../api/players";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import UserWalletAPI from "../api/user_wallets";
import FreezeAssers from "../api/freeze_assets";
import CreditModal from "./CreditModal";
import ReferalAPI from "../api/referal";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import CustomDate from "./sub_components/date";

const UserDetail = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const [dataToShow, setDataToShow] = useState([]);
  const [userStats, setUserStats] = useState([]);
  const [userWallets, setUsserWallets] = useState([]);
  const [freezeTriggered, setFreezeTriggered] = useState(false);
  const [creditTrigger, setCreditTrigger] = useState(false);
  const [betHistory, setBetHistory] = useState([]);
  const adminRole = useSelector((state) => state.auth.role);

  const [open, setOpen] = React.useState(false);
  const [referalStats, setReferalStats] = useState([]);
  const [editableEmail, setEditableEmail] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [userNameField, setUserNameField] = useState("");
  const handleEditable = () => {
    setEditableEmail(!editableEmail);
  };

  useEffect(() => {
    editableEmail ? setIsDisable(false) : setIsDisable(true);
  }, [editableEmail]);
  let params = useParams();
  // useEffect(() => {}, []);
  useEffect(() => {
    PlayersAPI.viewPlayer(params)
      .then((response) => {
        setDataToShow(response.data.user);
        setUserNameField(response.data.user.email);
        setUserStats(response.data);
        setUsserWallets(response.data.user_wallets);
        setBetHistory(response.data.bets_history);
      })
      .catch((error) => console.log(error));
  }, [freezeTriggered, creditTrigger]);
  useEffect(() => {
    // Temperory calling the referals logs for that user (IN A BAD WAY)
    if (userStats?.user?.id) {
      ReferalAPI.referal_stats({ id: userStats.user.id })
        .then((response) => {
          setReferalStats(response.data.referal_logs);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userStats]);

  const handleAssetStatus = (id, status) => {
    FreezeAssers.freeze_user_assets(id, {
      player: {
        freeze_assets: status ? false : true,
      },
    })
      .then((res) => {
        freezeTriggered ? setFreezeTriggered(false) : setFreezeTriggered(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const disableAuthentication = (id) => {
    PlayersAPI.disableTwoFactor(id)
      .then((res) => {
        setFreezeTriggered(!freezeTriggered);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateUserEmail = (id) => {
    if (userNameField !== dataToShow.email) {
      PlayersAPI.changeUserEmail(id, {
        player: {
          email: userNameField,
        },
      })
        .then((res) => {
          setFreezeTriggered(!freezeTriggered);
        })
        .catch((err) => {
          setFreezeTriggered(!freezeTriggered);
        });
    }
  };

  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>User # {dataToShow.id}</h1>
        </div>

        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds-detail">
              <tbody>
                <tr>
                  <td width="280">User Id</td>
                  <td>{dataToShow.id}</td>
                </tr>
                <tr>
                  <td width="280">First Name</td>
                  <td>
                    {dataToShow.first_name
                      ? dataToShow.first_name
                      : "First name not set"}
                  </td>
                </tr>
                <tr>
                  <td width="280">Last Name</td>
                  <td>
                    {dataToShow.last_name
                      ? dataToShow.last_name
                      : "Last name not set"}
                  </td>
                </tr>
                <tr>
                  <td width="280">Username</td>
                  <td>{dataToShow.username}</td>
                </tr>
                <tr>
                  <td width="280">Email Verified</td>
                  <td>{userStats.confirmed ? "Verified" : "Not Verified"}</td>
                </tr>
                <tr>
                  <td width="280">Email</td>
                  <div className="my_row space-between width">
                    <td>
                      <div style={{ display: "flex" }}>
                        <input
                          className="form-control"
                          type="text"
                          value={userNameField}
                          disabled={isDisable}
                          onChange={(e) => {
                            setUserNameField(e.target.value);
                          }}
                        />
                        {!isDisable && (
                          <button
                            style={{
                              marginLeft: "10px",
                            }}
                            className={`${
                              userNameField !== dataToShow.email
                                ? "primary-btn view"
                                : "primary-btn primary-btn-disable view"
                            }  `}
                            onClick={() => updateUserEmail(dataToShow.id)}
                          >
                            Update
                          </button>
                        )}
                      </div>
                    </td>
                    <td>
                      <button
                        style={{
                          background: "none",
                          border: "none",
                        }}
                        onClick={handleEditable}
                      >
                        <Tooltip title="Edit Email" placement="left">
                          <EditIcon
                            style={{
                              color: "#dee2e6",
                            }}
                          />
                        </Tooltip>
                      </button>
                    </td>
                  </div>
                </tr>
                <tr>
                  <td width="280">Referred By</td>
                  <td>
                    {userStats.referred_by ? userStats.referred_by : "NA"}
                  </td>
                </tr>
                {/* <tr>
                  <td width="280">Last Seen at</td>
                  <td>
                    <CustomDate date={dataToShow.last_seen_at} />
                  </td>
                </tr> */}
                <tr>
                  <td width="280">Created at</td>
                  <td>
                    <CustomDate date={dataToShow.created_at} />
                  </td>
                </tr>
                <tr>
                  <td width="280">Google 2FA Status</td>
                  <div className="my_row space-between windth">
                    <td className="my_row space-between">
                      {dataToShow.two_fa_enabled ? "Enabled" : "Disabled"}
                    </td>
                    <td className="my_row space-between">
                      {dataToShow.two_fa_enabled && (
                        <button
                          style={{
                            padding: "4px 10px",
                            marginLeft: "10px",
                            borderRadius: "5%",
                          }}
                          className="buttons-primary"
                          onClick={() => disableAuthentication(dataToShow.id)}
                        >
                          Disable 2FA
                        </button>
                      )}
                    </td>
                  </div>
                </tr>
                <tr>
                  <td width="280">Total bets placed</td>
                  <td className="my_row space-between">
                    {userStats.total_bets}
                  </td>
                </tr>
                <tr>
                  <td width="280">Total bets won</td>
                  <td className="my_row space-between">
                    {userStats.total_bets_won}
                  </td>
                </tr>
                <tr>
                  <td width="280">Total bets lost</td>
                  <td className="my_row space-between">
                    {userStats.total_bets_lost}
                  </td>
                </tr>
                <tr>
                  <td width="280">Total amount won</td>
                  <td className="my_row space-between">
                    {userStats.total_amount_won}
                  </td>
                </tr>
                <tr>
                  <td width="280">Total amount lost</td>
                  <td className="my_row space-between">
                    {userStats.total_amount_lost}
                  </td>
                </tr>
                <tr>
                  <td width="280">Total deposit</td>
                  <td className="my_row space-between">
                    {userStats.total_deposit}
                  </td>
                </tr>
                <tr>
                  <td width="280">Total withdraw</td>
                  <td className="my_row space-between">
                    {userStats.total_withdrawn}
                  </td>
                </tr>
                <tr>
                  <td width="280">Total Wager</td>
                  <td className="my_row space-between">
                    {userStats.total_waggered}
                  </td>
                </tr>
                <tr>
                  <td width="280">Last Seen at</td>
                  <td className="my_row space-between">
                    <CustomDate date={userStats.last_sign_in_at} />
                  </td>
                </tr>
                <tr>
                  <td width="280">Current Signin ip</td>
                  <td className="my_row space-between">
                    {userStats.current_sign_in_ip}
                  </td>
                </tr>
                <tr>
                  <td width="280">Last Signin ip</td>
                  <td className="my_row space-between">
                    {userStats.last_sign_in_ip}
                  </td>
                </tr>

                <tr>
                  <td width="280">Asset status</td>
                  <td className="my_row space-between">
                    {dataToShow.freeze_assets ? "Freezed" : "Unfreezed"}
                    {adminRole === "super_admin" && (
                      <button
                        style={{
                          padding: "4px 10px",
                          marginLeft: "10px",
                          borderRadius: "5%",
                        }}
                        className="buttons-primary"
                        onClick={() => {
                          handleAssetStatus(
                            dataToShow.id,
                            dataToShow.freeze_assets
                          );
                        }}
                      >
                        {dataToShow.freeze_assets ? "Unfreeze" : "Freeze"}
                      </button>
                    )}
                  </td>
                </tr>

                {userWallets.map((wallet) => {
                  return (
                    <tr key={wallet.id}>
                      <td width="280">{wallet.network_id}</td>
                      <td className="my_row space-between">
                        <span>
                          Current Balance:
                          <span className="gc">{" " + wallet.fake_amount}</span>
                        </span>
                        {adminRole === "super_admin" ? (
                          <CreditModal
                            networkId={wallet.network_id}
                            userId={params}
                            creditTrigger={creditTrigger}
                            setCreditTrigger={setCreditTrigger}
                          />
                        ) : (
                          wallet.network_id === "tron" || wallet.network_id === "thai_baht" ? (
                            <CreditModal
                              networkId={wallet.network_id}
                              userId={params}
                              creditTrigger={creditTrigger}
                              setCreditTrigger={setCreditTrigger}
                            />
                          ) : ""
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mt-4">
          <div className="page-title">
            <h1 className="mb-0">Referrals</h1>
          </div>
        </div>

        <div className="mt-4">
          <div className="text-center rounded p-4 table-bg">
            <div className="table-responsive">
              <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds">
                <Box>
                  <div
                    style={{
                      padding: "10px",
                    }}
                    className=" my_row space-between"
                  ></div>
                  {referalStats?.length > 0 && (
                    <div className="player-referal-stats-data-content">
                      <div
                        style={{
                          paddingBottom: "20px",
                        }}
                        className="player-referal-stats-data-content-container justify text-center"
                      >
                        <div className="head-inner">
                          <div className="space-between my_row">
                            <div className="my_row_item_4">
                              <p>User Name</p>
                            </div>
                            <div className="my_row_item_4">
                              <p>Total Wager</p>
                            </div>
                            <div className="my_row_item_4">
                              <p>Total Commission</p>
                            </div>
                            <div className="my_row_item_4">
                              <p>Status</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div style={{}} className="playere-referal-stats-data-main">
                    <div className="player-referal-stats-data-inner m-0 p-0">
                      {referalStats?.length ? (
                        referalStats.map(
                          ({
                            username,
                            total_usd_wager,
                            total_usd_commision,
                            claimed,
                          }) => {
                            return (
                              <div className="players-data-content">
                                <div className="players-data-content-container justify">
                                  <div
                                    style={{
                                      padding: "10px",
                                    }}
                                    className="head-inner my_row_item"
                                  >
                                    <div className="space-between my_row my_row">
                                      <div className="my_row_item_4">
                                        <p>{username}</p>
                                      </div>
                                      <div className="my_row_item_4">
                                        <p>{total_usd_wager}</p>
                                      </div>
                                      <div className="my_row_item_4">
                                        <p>{total_usd_commision}</p>
                                      </div>
                                      <div className="my_row_item_4">
                                        <p>
                                          {claimed ? "Claimed" : "Not Claimed"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <div className="no-data width auto">
                          <div className="no-data-text-container width">
                            <img
                              src="/img/empty.png"
                              className="mw-100 w-auto"
                              alt=""
                            />
                            <br />
                            <br />
                            <div>
                              <span style={{ fontSize: "14px" }}>
                                Oops! There is no data yet!
                              </span>
                            </div>
                            <br />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Box>
              </table>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="page-title">
            <h1 className="mb-0">Bets History</h1>
          </div>
        </div>
        <div className="mt-4">
          <div className="text-center rounded p-4 table-bg">
            <div className="table-responsive">
              <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds">
                <thead>
                  <tr>
                    <th scope="col">Bet ID</th>
                    <th scope="col">Bet Round ID</th>
                    <th scope="col">Bet Amount</th>
                    <th scope="col">Network ID</th>
                    <th scope="col">Cashout</th>
                    <th scope="col">Profit</th>
                    <th scope="col">Status</th>
                    <th scope="col">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {betHistory.length ? (
                    betHistory.map((user) => {
                      return (
                        <tr>
                          <td>{user.id}</td>
                          {/* <td>{user.user_id}</td> */}

                          <td>{user.bet_round_id}</td>
                          <td>{user.amount}</td>
                          <td>{user.network_id}</td>
                          <td>{user.cash_out}x</td>
                          <td>{user.profit}x</td>
                          <td>{user.win_status ? "Won" : "Lost"}</td>
                          <td>
                            <CustomDate date={user.created_at} />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colspan="8">
                        <img
                          src="/img/empty.png"
                          className="mw-100 w-auto"
                          alt=""
                        />
                        <br />
                        <br />
                        <p className="mb-0">No Bets Placed</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
