import axiosClient from ".";

class WithdrawalLimitApi {
  static set_withdrawal_limit(data) {
    return axiosClient.post("/super_admin/api/general_configurations", data);
  }
  static get_withdrawal_limit() {
    return axiosClient.get("/super_admin/api/general_configurations");
  }
}

export default WithdrawalLimitApi;
