import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import BotUsersAPI from "../api/bots";
import { Button } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip } from "@mui/material";
import CustomDate from "./sub_components/date";

const Bots = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const [nofBots, setNofBots] = useState("");
  const [empty, setEmpty] = useState(false);
  const [dataToShow, setDataToShow] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [totalData, setTotalData] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [tempPage, setTempPage] = useState(pageNumber);
  const [totalPages, setTotalPages] = useState(0);
  const [statusChanged, setStatusChanged] = useState(false);
  const [allBotBet, setAllBotBet] = useState(null);
  const [botActivationPerPage, setBotActivationPerPage] = useState(null);

  const [filter, setFilter] = useState(false);
  const [filterId, setFilterId] = useState("");
  const [idPredicate, setIdPredicate] = useState("eq");
  const [filterUsername, setFilterUsername] = useState("");
  const [usernamePredicate, setUsernamePredicate] = useState("contains");
  const [filterNetwork, setFilterNetwork] = useState("");
  const [networkPredicate, setNetworkPredicate] = useState("contains");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // ++++++++++++++++++++++++++++++ Get Bots Data +++++++++++++++++++++++++++++++++
  useEffect(() => {
    setDataLoading(true);
    BotUsersAPI.getBots(
      pageNumber,
      filter,
      idPredicate,
      filterId,
      usernamePredicate,
      filterUsername,
      filterNetwork,
      networkPredicate,
      startDate,
      endDate
    )
      .then((response) => {
        setDataToShow(response.data.list);
        setTotalPages(response.data.total_pages);
        setDataLoading(false);
        setAllBotBet(response.data.bot_status);
        setBotActivationPerPage(response.data.bot_status_per_page);
        setTotalData(response.data.total_count);
      })
      .catch((error) => {
        console.log(error);
      })
      .catch((error) => console.log(error));
  }, [
    pageNumber,
    statusChanged,
    filter,
    filter && idPredicate,
    filter && filterId,
    filter && usernamePredicate,
    filter && filterUsername,
    filter && filterNetwork,
    filter && networkPredicate,
    filter && startDate,
    filter && endDate,
  ]);

  const increasePage = () => {
    if (tempPage < totalPages) {
      setPageNumber(parseFloat(pageNumber) + 1);
      setTempPage(pageNumber);
      setTempPage(parseFloat(tempPage) + 1);
    }
  };

  const decrPage = () => {
    if (tempPage > 1) {
      setPageNumber(parseFloat(pageNumber) - 1);
      setTempPage(pageNumber);
      setTempPage(parseFloat(tempPage) - 1);
    }
  };
  const handleChange = (event) => {
    setTempPage(event.target.value);
  };

  const assignPage = (e) => {
    if (e.key === "Enter") {
      setPageNumber(e.target.value);
    }
  };
  const status = () => {
    console.log(
      dataToShow.filter((task) => {
        return task.bot_can_bet === false ? false.length : true;
      })
    );
  };
  // +++++++++++++++++++++++++++++++++ Create Bots +++++++++++++++++++++++++++++++++++++
  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "noBots") {
      setNofBots(value);
    }
  };
  const handleBot = () => {
    if (!nofBots) {
      setEmpty(true);
    } else
      BotUsersAPI.createBots({
        bot: {
          no_of_bots: nofBots,
        },
      }).then((res) => {
        BotUsersAPI.getBots(pageNumber)
          .then((response) => {
            setDataToShow(response.data.list);
            setTotalPages(response.data.total_pages);
            setDataLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      });
  };
  const allBotsActivation = (value) => {
    BotUsersAPI.setAllBotActive({
      bot: {
        activate_bots: allBotBet ? false : true,
      },
    })
      .then((res) => {
        setStatusChanged(statusChanged === true ? false : true);
        setBotActivationPerPage(allBotBet ? false : true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const botActivation = (id, value) => {
    BotUsersAPI.setActiveBot(id, {
      bot: {
        bot_can_bet: value,
      },
    })
      .then((res) => {
        setStatusChanged(statusChanged ? false : true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const botsActivationPage = (value) => {
    BotUsersAPI.setCurrentPageBot(pageNumber, {
      bot: {
        bot_can_bet: botActivationPerPage ? false : true,
      },
    })
      .then((res) => {
        // setBotActivationPerPage(botActivationPerPage ? false : true);

        setStatusChanged(statusChanged === true ? false : true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Bots</h1>
        </div>
        <div className="page-filters mb-4">
          <div className="row">
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                User Id
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={idPredicate}
                  onChange={(e) => {
                    setIdPredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="gteq">Greater & =</option>
                  <option value="lteq">Less & =</option>
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterId}
                  onChange={(e) => {
                    setFilterId(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                Username
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={usernamePredicate}
                  onChange={(e) => {
                    setUsernamePredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="contains">contains</option>
                  {/* <option value="lteq">Less & =</option> */}
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterUsername}
                  onChange={(e) => {
                    setFilterUsername(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                Created At
              </label>
              <div className="input-group mb-3">
                {" "}
                <DatePicker
                  placeholderText="From"
                  selected={startDate}
                  onChange={(date) => setStartDate(date ? date : "")}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="" className="form-label">
                &nbsp;
              </label>
              <div className="input-group mb-3">
                <DatePicker
                  placeholderText="To"
                  selected={endDate}
                  onChange={(date) => setEndDate(date ? date : "")}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                Network Id
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={networkPredicate}
                  onChange={(e) => {
                    setNetworkPredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="contains">contains</option>
                  <option value="start">start</option>
                  <option value="end">end</option>
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterNetwork}
                  onChange={(e) => {
                    setFilterNetwork(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-9 col-xl-9">
              <label htmlFor="" className="form-label">
                &nbsp;
              </label>
              <div>
              <Tooltip
                title={filter ? "Filter Enabled" : "Filter Disabled"}
                placement="bottom"
                arrow
              >
                <button
                  className={`${filter ? "primary-btn-success" : "primary-btn-disable"} relative me-3 mb-3 primary-btn`}
                  style={{
                    padding: "8px 11px 7px",
                  }}
                  type="button"
                  onClick={() => {
                    filter ? setFilter(false) : setFilter(true);
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      background: filter ? "rgb(59 193 23)" : "rgb(237, 99, 0)",
                      borderRadius: "50%",
                      right: "-3px",
                      top: "-3px",
                    }}
                    className="filter-status-indicator absolute"
                  ></div>
                  {filter ? "Active" : "Inactive"}
                </button>
              </Tooltip>
              Total Bots {totalData}
              </div>
            </div>
            {/* <div className="col-md-3">
              <label htmlFor="basic-url" className="form-label">
                <DatePick />
              </label>
              <div className="input-group mb-3"></div>
            </div> */}
          </div>
          <div>
          </div>
        </div>
        <div className="page-title">
          <h1>Create Bots</h1>
        </div>
        <div className="page-filters mb-4">
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="basic-url" className="form-label">
                Number of bots
              </label>
              <div className="input-group mb-3">
                <input
                  type="number"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  name="noBots"
                  value={nofBots}
                  onChange={(e) => {
                    setNofBots(e.target.value);
                  }}
                />
              </div>
            </div>
            {/*<div className="col-6 col-md-2 text-center">
              <label htmlFor="" className="form-label mb-3">
                Active
              </label>
              <label for="active-activeAll" class="active-activeAll">
                  <input type="checkbox" id="active-activeAll" /> <span class="active-activeAll-slide"></span>
              </label>
            </div>
            <div className="col-6 col-md-2 text-center">
              <label htmlFor="" className="form-label mb-3">
                Active All
              </label>
              <label for="active-activeAll-1" class="active-activeAll-1">
                  <input type="checkbox" id="active-activeAll-1" /> <span class="active-activeAll-1-slide"></span>
              </label>
            </div>*/}
            <div className="col-md-3">
              <label htmlFor="" className="form-label">
                &nbsp;
              </label>
              <div>
              <button
                className="primary-btn relative mb-3"
                style={{
                  padding: "8px 11px 7px",
                }}
                type="button"
                onClick={handleBot}
              >
                Create Bots
              </button>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Username</th>
                  <th scope="col">Min Bet</th>
                  <th scope="col">Max Bet</th>
                  <th scope="col">Cashout</th>
                  <th scope="col">Network</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Action</th>

                  <th scope="col">
                    <input style={{ position: "relative", top: "1px", marginRight: "3px", }}
                      type="checkbox"
                      onChange={(e) => {
                        allBotsActivation(e.target.value);
                      }}
                      // value={allBotBet}
                      checked={allBotBet ? true : false}
                    /> Activate All
                  </th>
                  <th scope="col">
                    <input style={{ position: "relative", top: "1px", marginRight: "3px", }}
                      type="checkbox"
                      onChange={(e) => {
                        botsActivationPage(e.target.value);
                      }}
                      // value={allBotBet}
                      checked={botActivationPerPage ? true : false}
                    /> Active
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataLoading ? (
                  <tr>
                    <td colspan="10">
                      <div className="loader-container">
                        <div className="lds-ring">
                          <div></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  dataToShow.map((obj) => {
                    return (
                      <tr key={obj.id}>
                        <td>{obj.id}</td>
                        <td>{obj.username}</td>
                        <td>{obj.bot_min_bet}</td>
                        <td>{obj.bot_max_bet}</td>
                        <td>{obj.bot_cashout}</td>

                        <td>{obj.active_wallet?.network_id}</td>
                        <td>
                          <CustomDate date={obj.created_at} />
                        </td>
                        <td>
                          <Link to={`/bot-details/${obj.id}`} className="view primary-btn">
                            View
                          </Link>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              botActivation(obj.id, e.target.value);
                              setBotActivationPerPage(
                                obj.bot_can_bet
                                  ? false
                                  : true
                                  ? allBotBet == true
                                  : false
                              );
                            }}
                            value={obj.bot_can_bet ? false : true}
                            checked={obj.bot_can_bet}
                          />
                        </td>
                        {/* <td>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              botActivation(obj.id, e.target.value);
                              setBotActivationPerPage(
                                obj.bot_can_bet
                                  ? false
                                  : true
                                  ? allBotBet == true
                                  : false
                              );
                            }}
                            value={obj.bot_can_bet ? false : true}
                            checked={obj.bot_can_bet}
                          />
                        </td> */}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            <div className="pagination-buttons-container width my_row end">
              <div className="next-pre-btn my_row align_center space-between width-15">
                <div className="pre-btn">
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                    className="white"
                    onClick={decrPage}
                  >
                    <ArrowBackIosNewIcon />
                  </button>
                </div>
                <span className="white">
                  <input
                    type="number"
                    value={tempPage}
                    style={{
                      maxWidth: "45px",
                      heigh: "10px",
                      background: "transparent",
                      border: "none",
                      color: "white",
                      textAlign: "center",
                    }}
                    onChange={handleChange}
                    onKeyPress={assignPage}
                  />{" "}
                  / {totalPages}
                </span>
                <div className="next-btn">
                  <button
                    className="white"
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                    onClick={increasePage}
                  >
                    <ArrowForwardIosIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br/>
      </div>
    </div>
  );
  // );
};

export default Bots;