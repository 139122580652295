import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  security: "ok",
};

const phraseSlice = createSlice({
  name: "phrase",
  initialState,
  reducers: {
    setSecurityEnabled: (state, action) => {
      state.security = action.payload.security;
    },
  },
});

export const { setSecurityEnabled } = phraseSlice.actions;

export default phraseSlice.reducer;
