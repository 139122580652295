import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  currentUser: null,
  token: null,
  role: null,
};  

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      state.currentUser = action.payload.currentUser;
      state.token = action.payload.token;
      state.role = action.payload.role;
    },
  },
});
export const { setAuthData } = authSlice.actions;
export default authSlice.reducer;
