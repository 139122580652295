import React, { useState } from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import SecurityPhraseApi from "../api/security_phrase";
import Alert from "./Alert";
import { setSecurityEnabled } from "../store/security_phrase";
import UserWalletAPI from "../api/user_wallets";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  background: "#24262b",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};

export default function CreditModal({
  networkId,
  userId,
  setCreditTrigger,
  creditTrigger,
}) {
  const [open, setOpen] = useState(false);

  const phraseActive = useSelector((state) => state.phrase.security);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [creditAmount, setCreditAmount] = useState();
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [depositing, setDepositing] = useState(false);
  const handleCredit = () => {
    if (!creditAmount) {
      setEmpty(true);
      setTimeout(() => {
        setEmpty(false);
      }, 3000);
    } else {
      setDepositing(true);
      UserWalletAPI.addFakeCredits(userId.id, {
        player: {
          network_id: networkId,
          fake_credits: creditAmount,
        },
      })
        .then((res) => {
          creditTrigger ? setCreditTrigger(false) : setCreditTrigger(true);

          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            // handleClose();
          setDepositing(false);

          }, 3000);
        })
        .catch((err) => {
          setFailed(true);
          setTimeout(() => {
            setFailed(false);
          }, 3000);
        });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      
      <button
        style={{
          padding: "4px 10px",
          marginLeft: "10px",
          borderRadius: "5%",
        }}
        className="buttons-primary"
        onClick={handleOpen}
      >
        Add
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="security-phrase-main">
            <div className="security-phrase-inner">
              <div className="security-phrase-content">
                <div
                  style={{
                    width: "80%",
                  }}
                  className="alert-container absolute"
                >
                  {success && (
                    <Alert
                      type="success"
                      message="Credit added successfully."
                    />
                  )}
                  {failed && (
                    <Alert type="error" message="Unable to add credit." />
                  )}
                  {empty && (
                    <Alert type="error" message="Field must be filled" />
                  )}
                </div>

                <p className="white text-center">Add credit to user wallet</p>

                <div className="security-phrase width">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Enter Credit Amount"
                    value={creditAmount}
                    onChange={(e) => {
                      setCreditAmount(e.target.value);
                    }}
                  />
                </div>

                <div className="security-phrase width justified">
                  {depositing ? (
                    <button
                      name={phraseActive === true ? "update" : "create"}
                      style={{
                        padding: "10px 25px",
                      }}
                      className="buttons-disabled"
                    >
                      Depositing
                    </button>
                  ) : (
                    <button
                      name={phraseActive === true ? "update" : "create"}
                      style={{
                        padding: "10px 25px",
                      }}
                      className="buttons-primary"
                      onClick={handleCredit}
                    >
                      Add Credit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
