import React, { useState } from "react";
import { useEffect } from "react";

function Table({ data, dataLoading, reportTypes }) {
  const [tableHead, setTableHead] = useState([]);
  const [headerKeys, setHeaderKeys] = useState([]);

  useEffect(() => {
    if (data[0]) {
      setHeaderKeys(Object.keys(data[0]));
    }
  }, [data]);

  let updatedheader = {
    created_at: "Date",
    usdt: "USDT",
    bcd: "BCD",
    bitcoin: "BITCOIN",
    ethereum: "ETHEREUM",
    tron: "TRON",
    polygon: "POLYGON",
    solana: "SOLANA",
    wb: "WB",
    usd: "USD",
    indonesian_rupiah:"Indonesian Rupiah",
    philippine_peso:"Philippine Peso",
    thai_baht:"	Thai Baht",
    united_states_dollar:"USD",
    new_registrations: "New Registrations",
    total_recharge: "Total Recharge",
    no_of_recharges: "No of Recharges",
    admin_recharge: " Total Admin Recharge",
    no_of_admin_recharges: "No of Admin Recharges",
    total_withdrawals: "Total Withdrawals",
    no_of_withdrawals: "No of Withdrawals",
    bcd_claim: "Total bcd Claim",
    referal_claim: "Referal Claim",
    from_network_id: "From Network id",
    to_network_id: "To Network id",
    first_recharge: "Total First Recharge",
    no_of_first_recharges: "No of First Recharges",
  };
  let defaultValues = {
    created_at: "NA",
    new_registrations: 0,
    total_recharge: 0,
    no_of_recharges: 0,
    admin_recharge: 0,
    no_of_admin_recharges: 0,
    total_withdrawals: 0,
    no_of_withdrawals: 0,
    bcd_claim: 0,
    referal_claim: 0,
    first_recharge: 0,
    no_of_first_recharges: 0,
  };

  let formattingFunctions = {
    summary: {
      new_registrations: toFixed,
      total_recharge: toFixed,
      no_of_recharges: toFixed,
      admin_recharge: toFixed,
      no_of_admin_recharges: toFixed,
      total_withdrawals: toFixed,
      no_of_withdrawals: toFixed,
      bcd_claim: toFixed,
      referal_claim: toFixed,
      first_recharge: toFixed,
      no_of_first_recharges: toFixed,
    },
    swap: {
      usdt: toFixed,
      bcd: toFixed,
      bitcoin: toFixed,
      ethereum: toFixed,
      tron: toFixed,
      polygon: toFixed,
      solana: toFixed,
      wb: toFixed,
      usd: toFixed,
    },
    withdrawal: {

    }, 
    deposit: {}
  };

  formattingFunctions = formattingFunctions[reportTypes]
  function formatvalue(key, value) {
    if(!formattingFunctions) {
      return value
    }
    if (formattingFunctions.hasOwnProperty(key)) {
      let formattingFunction = formattingFunctions[key];
      value = formattingFunction(value);
    }
    return value;
  }

  function toFixed(val) {
    return Number.parseFloat(val).toFixed(2);
  }
  const newData = headerKeys.map((key) => {
    let header = key;
    if (updatedheader.hasOwnProperty(key)) {
      header = updatedheader[key];
    }
    return header;
  });

  useEffect(() => {
    if (data[0]) {
      setTableHead(Object.keys(data[0]));
    }
  }, [data]);
  const Header = ({ array }) => {
    return array?.map((x) => {
      return (
        <th id={x} key={x}>
          {x}
        </th>
      );
    });
  };
  const tdData = () => {
    return data.length ? (
      data.map((obj, index) => {
        return (
          <tr>
            {headerKeys.map((v, index) => {
              let value = obj[v] ?? defaultValues[v];
              value = formatvalue(v, value);
              return <td>{value}</td>;


            })}
          </tr>
        );
      })
    ) : (
      <span>No Record Found!</span>
    );
  };
  return (
    <div className="text-center rounded p-4 table-bg">
      <div className="table-responsive">
          <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds">
            {dataLoading ? (
              <tbody>
              <tr>
                <td colSpan="10">
                  <div className="loader-container align-middle ">
                    <div className="lds-ring">
                      <div></div>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            ) : (
            <>
            <thead>
              <tr>
                <Header array={newData} />
              </tr>
            </thead>

            <tbody>
              {tdData()}
            </tbody>
            </>
            )}
          </table>
      </div>
    </div>
  );
}

export default Table;
