import consumer from "./consumer";
import { store } from "../store";
import {
  clearBetData,
  setSingleBet,
  updateSingleBet,
  setTotalBetAmount,
} from "../store/bet";
const betStatusSubscribe = () => {
  consumer.subscriptions.create(
    {
      channel: "BetStatusChannel",
    },
    {
      received: (data) => {
        if (data.bet_status === "bet_created") {
          const {
            user_id,
            username,
            cash_out,
            amount,
            profit,
            network_id,
            user_img,
            total_bet_amount,
          } = data;

          store.dispatch(
            setSingleBet({
              data: data,
            })
          );
          store.dispatch(
            setTotalBetAmount({ total_bet_amount: total_bet_amount })
          );
        } else if (data.bet_status === "bet_updated") {
          const {
            user_id,
            username,
            cash_out,
            amount,
            profit,
            network_id,
            user_img,
          } = data;
          store.dispatch(
            updateSingleBet({
              data: data,
            })
          );
        }
      },
    }
  );
};
export { betStatusSubscribe };
