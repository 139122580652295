import axiosClient from ".";

class DepositsAPI {
  static getDeposits(
    page,
    filter,
    idPredicate,
    filterId,
    usernamePredicate,
    filterUsername,
    filterNetwork,
    networkPredicate,
    filterHash,
    hashPredicate,
    tokenPredicate,
    filterToken,
    startDate,
    endDate
  ) {
    return axiosClient.get(
      `/super_admin/api/deposit_histories.json?page=${page}/${
        filter &&
        `&q[id_${idPredicate}]=${filterId}&q[user_username_${usernamePredicate}]=${filterUsername}&q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}&q[network_id_${networkPredicate}]=${filterNetwork}&q[tx_hash_${hashPredicate}]=${filterHash}&q[token_${tokenPredicate}]=${filterToken}`
      }`
    );
  }
  static viewDeposit(data) {
    return axiosClient.get(
      `/super_admin/api/deposit_histories/${data.id}.json`
    );
  }
}
export default DepositsAPI;
