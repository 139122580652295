import React, { useEffect, useState } from "react";
import WithdrawalsAPI from "../api/withdrawal";
import { Link, useNavigate, useParams } from "react-router-dom";
import WithdrawalRequestAPI from "../api/withdrawal_request";
import Alert from "./Alert";
import { useSelector } from "react-redux";

const WithdrawalApproval = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const [loadingData, setLoadingData] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(
    {
      amount: "",
      created_at: "",
      id: "",
      network_id: "",
      public_key: "",
      status: "",
      status_changed: "",
      token: "",
      updated_at: "",
      user_id: "",
      withdrawal_history_id: "",
    },
    ""
  );
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [withdrawStatus, setWithdrawalStatus] = useState(null);
  const [stateUpdate, setStateUpdate] = useState(false);
  let params = useParams();

  useEffect(() => {
    setLoadingData(true);
    WithdrawalRequestAPI.view_withdrawal_request(params.id)
      .then((response) => {
        setLoadingData(false);

        setSuccess(false);

        setCurrentRequest(response.data.withdrawal_req);
      })
      .catch((error) => console.log(error));
  }, [params, stateUpdate]);

  const changeStatus = () => {
    if (withdrawStatus === "pending") {
      setFailed(true);
      setTimeout(() => {
        setFailed(false);
      }, 3000);
    } else {
      WithdrawalRequestAPI.update_withdrawal_request(currentRequest.id, {
        withdrawal_request: {
          status: withdrawStatus,
        },
      })
        .then((res) => {
          setStateUpdate(stateUpdate ? false : true);
        })
        .catch((err) => {
          setFailed(true);
          setTimeout(() => {
            setFailed(false);
          }, 3000);
          console.log(err);
        });
    }
  };

  return loadingData ? (
    <>
      <div className="content">
        <br />
        <br />
        <div class="text-center">
          <div class="lds-ring">
            <div></div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Withdrawal # {currentRequest.id}</h1>
          <div
            style={{
              width: "76%",
              top: "20px",
            }}
            className="alert-container absolute"
          >
            {success && <Alert type="success" message="Updated Successfully" />}
            {failed && (
              <Alert
                type="error"
                message="Please change the withdrawal status"
              />
            )}
          </div>
        </div>
        {/* <form onSubmit={this.handleSubmit}> */}
        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds-detail">
              <tbody>
                <tr>
                  <td width="280">User Id</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      className="form-control disabled-inputs"
                      value={currentRequest?.user_id}
                      disabled="true"
                    />
                  </td>
                </tr>
                <tr>
                  <td width="280">Network Id</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      className="form-control disabled-inputs"
                      value={currentRequest?.network_id}
                      disabled="true"
                    />
                    {/* {currentRequest?.network_id} */}
                  </td>
                </tr>
                <tr>
                  <td width="280">Amount</td>
                  <td>
                    <input
                      type="number"
                      name=""
                      className="form-control disabled-inputs"
                      value={currentRequest?.amount}
                      disabled="true"
                    />
                  </td>
                </tr>

                <tr>
                  <td width="280">Status</td>
                  <td>
                    <select
                      style={{
                        background:
                          currentRequest.status === "pending"
                            ? "white"
                            : "#3a3a3a",
                        border: "none",
                      }}
                      className="form-control status"
                      value={
                        currentRequest.status === "pending"
                          ? withdrawStatus
                          : currentRequest.status
                      }
                      onChange={(e) => {
                        setWithdrawalStatus(e.target.value);
                      }}
                      disabled={
                        currentRequest.status !== "pending" ? true : false
                      }
                    >
                      <option value="pending">pending</option>
                      <option value="approved">approved</option>
                      <option value="rejected">rejected</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="text-end page-filters m-0 p-0">
            {currentRequest.status === "pending" && (
              <button
                type="button"
                className="primary-btn primary-btn-success m-2"
                onClick={changeStatus}
              >
                Save
              </button>
            )}
            {currentRequest.status === "pending" && (
              <Link
                to="/withdrawal-requests"
                type="button"
                className="primary-btn primary-btn-disable m-2"
              >
                Cancel
              </Link>
            )}
          </div>
        </div>
        {/* </form> */}
      </div>
    </div>
  );
};

export default WithdrawalApproval;
