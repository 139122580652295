import React, { useState } from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import SecurityPhraseApi from "../api/security_phrase";
import Alert from "./Alert";
import { setSecurityEnabled } from "../store/security_phrase";
import PoolAmountAPI from "../api/pool_amount";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    background: "#24262b",
    border: "2px solid #000",
    boxShadow: 24,
    p: 3,
};

export default function PoolAmountModal({
    poolAmountId,
    setPoolTrigger,
    poolTrigger,

}) {
    const [open, setOpen] = useState(false);

    const phraseActive = useSelector((state) => state.phrase.security);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [poolAmount, setPoolAmount] = useState();
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);
    const [empty, setEmpty] = useState(false);
    const [dataToShow, setDataToShow] = useState({});

    const handlePoolAmount = () => {
        if (!poolAmount) {
            setEmpty(true);
            setTimeout(() => {
                setEmpty(false);
            }, 3000);
        } else {
            PoolAmountAPI.setPoolAmount(poolAmountId, {
                pool_amount: {
                    fake_pool_value: poolAmount
                },
            })
                .then((res) => {
                    PoolAmountAPI.viewPoolAmount({ id: poolAmountId })
                        .then((response) => {
                            setDataToShow(response.data);
                        })
                        .catch((error) => console.log(error));
                    poolTrigger ? setPoolTrigger(false) : setPoolTrigger(true);
                    setSuccess(true);
                    setTimeout(() => {
                        setSuccess(false);
                        handleClose();
                    }, 3000);


                })
                .catch((err) => {
                    setFailed(true);
                    setTimeout(() => {
                        setFailed(false);
                    }, 3000);
                });
        }
    };
    return (
        <div>
            <button
                style={{
                    padding: "4px 10px",
                    marginLeft: "10px",
                    borderRadius: "5%",
                }}
                className="buttons-primary"
                onClick={handleOpen}
            >
                Edit
            </button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="security-phrase-main">
                        <div className="security-phrase-inner">
                            <div className="security-phrase-content">
                                <div
                                    style={{
                                        width: "80%",
                                    }}
                                    className="alert-container absolute"
                                >
                                    {success && (
                                        <Alert
                                            type="success"
                                            message="Pool Amount updated successfully."
                                        />
                                    )}
                                    {failed && (
                                        <Alert type="error" message="Unable to set Pool Amount." />
                                    )}
                                    {empty && (
                                        <Alert type="error" message="Field must be filled" />
                                    )}
                                </div>

                                <p className="white">Add Pool Amount </p>

                                <div className="security-phrase width">
                                    <input
                                        className="security-phrase-inputs form-control"
                                        type="number"
                                        placeholder="Enter Pool Amount "
                                        value={poolAmount}
                                        onChange={(e) => {
                                            setPoolAmount(e.target.value);
                                        }}
                                    />
                                </div>

                                <div className="security-phrase width justified">
                                    <button
                                        // name={phraseActive === true ? "update" : "create"}
                                        style={{
                                            padding: "10px 25px",
                                        }}
                                        className="buttons-primary"
                                        onClick={handlePoolAmount}
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
