import React, { useEffect, useState } from "react";
import AdminUsersAPI from "../api/admin_users";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CustomDate from "./sub_components/date";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Tooltip } from "@mui/material";

const AdminCreditLogs = () => {
  const [dataToShow, setDataToShow] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [tempPage, setTempPage] = useState(pageNumber);
  const [totalPages, setTotalPages] = useState(0);
  const [idPredicate, setIdPredicate] = useState("eq");
  const [filterId, setFilterId] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [emailPredicate, setEmailPredicate] = useState("contains");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filter, setFilter] = useState(false);
  useEffect(() => {
    setDataLoading(true);
    AdminUsersAPI.adminCreditLogs(
      pageNumber,
      filter,
      filterId,
      idPredicate,
      filterEmail,
      emailPredicate,
      startDate,
      endDate)
      .then((response) => {
        setDataToShow(response.data.list);
        setTotalPages(response.data.total_pages);
        setDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    pageNumber,
    filter,
    filter && filterId,
    filter && idPredicate,
    filter && filterEmail,
    filter && emailPredicate,
    filter && startDate,
    filter && endDate,
  ]);
  const increasePage = () => {
    if (tempPage < totalPages) {
      setPageNumber(parseFloat(pageNumber) + 1);
      setTempPage(pageNumber);
      setTempPage(parseFloat(tempPage) + 1);
    }
  };

  const decrPage = () => {
    if (tempPage > 1) {
      setPageNumber(parseFloat(pageNumber) - 1);
      setTempPage(pageNumber);
      setTempPage(parseFloat(tempPage) - 1);
    }
  };
  const handleChange = (event) => {
    setTempPage(event.target.value);
  };

  const assignPage = (e) => {
    if (e.key === "Enter") {
      setPageNumber(e.target.value);
    }
  };
  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Admin Credit Logs</h1>
        </div>
        <div className="page-filters">
          <div className="row">
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                User Id
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={idPredicate}
                  onChange={(e) => {
                    setIdPredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="gteq">Greater & =</option>
                  <option value="lteq">Less & =</option>
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterId}
                  onChange={(e) => {
                    setFilterId(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                 Email
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={emailPredicate}
                  onChange={(e) => {
                    setEmailPredicate(e.target.value);
                  }}
                >
                  <option value="contains">Contains</option>
                  <option value="eq">Equals To</option>
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterEmail}
                  onChange={(e) => {
                    setFilterEmail(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                Created At
              </label>
              <div className="input-group mb-3">
                <DatePicker
                  placeholderText="From"
                  selected={startDate}
                  placeholder="from"
                  onChange={(date) => setStartDate(date ? date : "")}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <div className="input-group mb-3">
                <label htmlFor="basic-url" className="form-label">
                  &nbsp;
                </label>
                <DatePicker
                  placeholderText="To"
                  selected={endDate}
                  onChange={(date) => setEndDate(date ? date : "")}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-xl-9">
            
            <div className="mt-3">
            <Tooltip
              title={filter ? "Filter Enabled" : "Filter Disabled"}
              placement="bottom"
              arrow
            >
              <button
                className={`${filter ? "primary-btn-success" : "primary-btn-disable"} relative mb-3 primary-btn`} type="button"
                onClick={() => {
                  filter ? setFilter(false) : setFilter(true);
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    background: filter ? "rgb(59 193 23)" : "rgb(237, 99, 0)",
                    borderRadius: "50%",
                    right: "-3px",
                    top: "-3px",
                  }}
                  className="filter-status-indicator absolute"
                ></div>
                {filter ? "Active" : "Inactive"}
              </button>
            </Tooltip>
          </div>
          </div>
        </div>
        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Admin Id</th>
                  <th scope="col">Admin Email</th>
                  <th scope="col">Credit Amount</th>
                  <th scope="col">Network Id </th>
                  <th scope="col">User Id</th>
                  <th scope="col">Role</th>
                  <th scope="col">Credit At</th>
                </tr>
              </thead>
              <tbody>
                {dataLoading ? (
                  <tr>
                    <td colspan="8">
                      <div className="loader-container">
                        <div className="lds-ring">
                          <div></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  dataToShow.map((obj) => {
                    return (
                      <tr>
                        <td>{obj.id}</td>
                        <td>{obj.admin_user_id}</td>
                        <td>{obj.admin}</td>
                        <td>{obj.fake_credit}</td>
                        <td>{obj.network_id}</td>
                        <td>{obj.user_id}</td>
                        <td>{obj.role}</td>
                        <td>
                          <CustomDate date={obj.created_at} />
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            <div className="pagination-buttons-container width my_row end">
              <div className="next-pre-btn my_row align_center space-between width-15">
                <div className="pre-btn">
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                    className="white"
                    onClick={decrPage}
                  >
                    <ArrowBackIosNewIcon />
                  </button>
                </div>
                <span className="white">
                  <input
                    type="number"
                    value={tempPage}
                    style={{
                      maxWidth: "45px",
                      heigh: "10px",
                      background: "transparent",
                      border: "none",
                      color: "white",
                      textAlign: "center",
                    }}
                    onChange={handleChange}
                    onKeyPress={assignPage}
                  />
                  / {totalPages}
                </span>
                <div className="next-btn">
                  <button
                    className="white"
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                    onClick={increasePage}
                  >
                    <ArrowForwardIosIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCreditLogs;
