import MainBody from "./Components/MainBody";
import { BrowserRouter } from "react-router-dom";
import { persistor, store } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { betStatusSubscribe } from "./channels/bet_status_channel";
import { gameStatusSubscribe } from "./channels/game_status_channel";
import { superAdminSubscribe } from "./channels/super_admin_channnel";
import { walletStatusSubscribe } from "./channels/wallet_status_channel";
import { useEffect } from "react";


const App = () => {
  useEffect(() => {
    betStatusSubscribe();
    gameStatusSubscribe();
    superAdminSubscribe();
    walletStatusSubscribe();

  }, [])
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <MainBody />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};



export default App;
