import axiosClient from ".";

class PoolAmountAPI {
  static getPoolAmounts(pageNumber) {
    return axiosClient.get(
      `/super_admin/api/pool_amounts.json?page=${pageNumber}`
    );
  }
  static viewPoolAmount(data) {
    return axiosClient.get(`/super_admin/api/pool_amounts/${data.id}.json`);
  }
  static setPoolAmount(id, data) {
    return axiosClient.patch(`/super_admin/api/pool_amounts/${id}.json`, data);
  }

  static getCryptoPool(pageNumber) {
    return axiosClient.get(
      `/super_admin/api/pool_operations.json?page=${pageNumber}`
    );
  }
}
export default PoolAmountAPI;
