import { useState } from "react";

function DatePick() {
  const [date, setDate] = useState("");
  return (
    <div className="row">
      <div className="">
        <h1 className="white font">From date {date}</h1>
        <input type="date" onChange={(e) => setDate(e.target.value)} />
        <h1 className="white font">TO date {date}</h1>
        <input type="date" onChange={(e) => setDate(e.target.value)} />
      </div>
    </div>
  );
}

export default DatePick;
