import React, { useEffect, useState } from "react";
import AdminUsersAPI from "../api/admin_users";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomDate from "./sub_components/date";
const AdminDetails = () => {
  const [data, setDataToShow] = useState([]);
  let params = useParams();

  useEffect(() => {
    AdminUsersAPI.viewAdmin(params)
      .then((response) => {
        setDataToShow(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);

  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Admin User # {data.id}</h1>
        </div>
        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds-detail">
              <tbody>
                <tr>
                  <td width="280">Email</td>
                  <td>{data.email}</td>
                </tr>
                <tr>
                  <td width="280">Created_at</td>
                  <td>
                    <CustomDate date={data.created_at} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDetails;