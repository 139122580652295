import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  withdrawalNotification: [],
  notificationCount: 0,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setWithdrawalNotification: (state, action) => {
      const data = action.payload.withdrawalNotification;
      state.withdrawalNotification.unshift(data);
    },
    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload.notificationCount;
    },

    clearAllNotifications: (state, action) => {
      state.withdrawalNotification = action.payload.withdrawalNotification;
    },
    deleteWithdrawalNotification: (state, action) => {
      let toDelete = state.withdrawalNotification.find((item) => {
        return item.withdrawal_request_id === action.payload.id;
      });
      const index = state.withdrawalNotification.indexOf(toDelete);
      state.withdrawalNotification.splice(index, 1);
    },
  },
});

export const {
  setWithdrawalNotification,
  setNotificationCount,
  deleteWithdrawalNotification,
  clearAllNotifications,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
