import React, { useEffect, useState } from "react";
import ServerClientHashesAPI from "../api/server_client_hashes";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const ServerClientHashDetail = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const [dataToShow, setDataToShow] = useState([]);
  let params = useParams();

  useEffect(() => {
    ServerClientHashesAPI.viewServerClientHashes(params)
      .then((response) => {
        setDataToShow(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Hash # {dataToShow.id}</h1>
        </div>

        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds-detail">
              <tbody>
                <tr>
                  <td width="280">Server Seed</td>
                  <td>{dataToShow.server_seed}</td>
                </tr>
                <tr>
                  <td width="280">Client Seed</td>
                  <td>{dataToShow.client_seed}</td>
                </tr>

                <tr>
                  <td width="280">Multiplier no</td>
                  <td>{dataToShow.multiplier_no}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerClientHashDetail;
