import { store } from "../store";
import {
  setWithdrawalNotification,
  setNotificationCount,
} from "../store/notifications";
import { clearBetData } from "../store/bet";
import { setTotalBetAmount } from "../store/bet";
import { fetchPlayersData } from "../store/player";

import consumer from "./consumer";

const superAdminSubscribe = () => {
  consumer.subscriptions.create(
    {
      channel: "AdminChannel",
    },
    {
      received: (data) => {
        if (data.type === "withdrawal_request") {
          const notificationcount =
            store.getState().notifications.notificationCount;
          store.dispatch(
            setWithdrawalNotification({ withdrawalNotification: data.data })
          );
          store.dispatch(
            setNotificationCount({
              notificationCount: notificationcount + 1,
            })
          );
        } else if (data.type === "round_end") {
          store.dispatch(clearBetData());
          store.dispatch(setTotalBetAmount({ total_bet_amount: 0 }));
          store.dispatch(fetchPlayersData({ total_player: 0 }));
        } else if (data.type === "betting_data") {
          store.dispatch(
            fetchPlayersData({ total_player: data.data.total_betting_players })
          );
        }
      },
    }
  );
};
export { superAdminSubscribe };
