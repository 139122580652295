import axiosClient from ".";

class SecurityPhraseApi {
  static get_current_phrase() {
    return axiosClient.get("/super_admin/api/get-passphrase");
  }

  static edit_phrase(data) {
    return axiosClient.put("/super_admin/api/edit-passphrase", data);
  }

  static validate_phrase(data) {
    return axiosClient.post("/super_admin/api/validate-passphrase", data);
  }

  static create_phrase(data) {
    return axiosClient.post("/super_admin/api/create-passphrase", data);
  }
}

export default SecurityPhraseApi;
