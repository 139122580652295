import axiosClient from ".";
class Scans {
  static scan_block(data) {
    return axiosClient.post("/super_admin/api/block_scans.json", data);
  }
  static get_blocks(pageNumber) {
    return axiosClient.get(`/super_admin/api/block_scans.json?page=${pageNumber}`);
  }
  static get_transactions_scans(pageNumber) {
    return axiosClient.get(`/super_admin/api/transaction_scans.json?page=${pageNumber}`);
  }
  static create_transactions_scan(data) {
    return axiosClient.post("/super_admin/api/transaction_scans.json", data);
  }
}
export default Scans;
