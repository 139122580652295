import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import PoolAmountAPI from "../api/pool_amount";
import PoolBalanceWithdrawal from "../api/pool_balance_withdrawal";
import VerifyPhraseModal from "./VerifyPhrase";

import SecurityPhraseApi from "../api/security_phrase";

import BasicAlerts from "./Alert";

const WithdrawComponent = ({
  ammountSent,
  setAmmountSet,
  failed,
  setFaild,
}) => {
  const [wallets, setWallets] = useState([]);

  useEffect(() => {
    PoolAmountAPI.getCryptoPool().then((res) => {
      setWallets(res.data.pool_operations);
    });
  }, [ammountSent]);
  const [optionState, setOptionState] = useState("ethereum");

  const [activated, setActivated] = useState(null);

  useEffect(() => {
    setActivated(
      wallets.find((currency) => currency.network_id == optionState)
    );
  }, [wallets, optionState]);

  const [imgURL, setImgURL] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [phraseToVerify, setPhraseToVerify] = useState("");
  const [emptyAdress, setEmptyAdress] = useState(false);
  const [walletAmount, setWalletAmount] = useState(
    activated?.network_id == "usdt"
      ? activated?.token_balance
      : activated?.pool_value
  );
  const [balance, setBalance] = useState(
    activated?.network_id == "usdt"
      ? activated?.token_balance
      : activated?.pool_value
  );

  //   const [ammountSent, setAmmountSet] = useState(false);

  const [crypto, setCrypto] = useState(true);
  const dropdownHandle = (e) => {
    setOptionState(e.target.value);
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const [withdrawing, setwithdrawing] = useState(false);

  const [Polygon, setPolygon] = useState(true);
  const [Solana, setSolana] = useState(false);
  const [Ether, setEther] = useState(false);
  const [Tron, setTron] = useState(false);
  const [open, setOpen] = useState(false);
  const phraseActive = useSelector((state) => state.phrase.security);

  const handlePolygon = () => {
    setPolygon(true);
    setSolana(false);
    setEther(false);
    setTron(false);
  };

  const handleSolana = () => {
    setSolana(true);
    setPolygon(false);
    setEther(false);
    setTron(false);
  };
  const handleEther = () => {
    setEther(true);
    setPolygon(false);
    setSolana(false);
    setTron(false);
  };
  const handleTron = () => {
    setTron(true);
    setPolygon(false);
    setSolana(false);
    setEther(false);
  };

  useEffect(() => {
    setWalletAmount(
      activated?.network_id == "usdt"
        ? activated?.token_balance
        : activated?.pool_value
    );
    setBalance(
      activated?.network_id == "usdt"
        ? activated?.token_balance
        : activated?.pool_value
    );
    if (optionState == "wb") {
      setImgURL("/img/AcceptedNetworks/BetCoin.png");
    }
    if (optionState === "ethereum") {
      setImgURL("/img/AcceptedNetworks/ethClr.png");
    }
    if (optionState === "polygon") {
      setImgURL("/img/AcceptedNetworks/polyClr.png");
    }
    if (optionState === "tron") {
      setImgURL("/img/AcceptedNetworks/trxClr.png");
    }
    if (optionState === "solana") {
      setImgURL("/img/AcceptedNetworks/solClr.png");
    }
    if (optionState === "bitcoin") {
      setImgURL("/img/AcceptedNetworks/btcClr.webp");
    }
    if (optionState === "usdt") {
      setImgURL("/img/AcceptedNetworks/usdt.webp");
    }
    setWalletAmount(
      activated?.network_id == "usdt"
        ? activated?.token_balance
        : activated?.pool_value
    );
  }, [optionState, activated]);

  const handleWithDrawal = () => {
    if (walletAddress && phraseActive == true) {
      setOpen(true);
      setEmptyAdress(false);
    } else {
      if (!walletAddress) {
        setEmptyAdress(true);
      } else {
        setwithdrawing(true);

        PoolBalanceWithdrawal.pool_balance_withdrawal({
          pool_withdraw_amount: {
            network_id:
              optionState === "usdt" && Ether
                ? "ethereum"
                : optionState === "usdt" && Polygon
                ? "polygon"
                : optionState === "usdt" && Solana
                ? "solana"
                : optionState === "usdt" && Tron
                ? "tron"
                : activated.network_id,
            publicKey: walletAddress,
            amount: walletAmount,
            token: optionState === "usdt" ? true : false,
          },
        })
          .then((res) => {
            setwithdrawing(false);
            setAmmountSet(true);
            setTimeout(() => {
              setAmmountSet(false);
            }, 3000);
          })
          .catch((err) => {
            console.log(err);
            setwithdrawing(false);
            setFaild(true);
            setTimeout(() => {
              setFaild(false);
            }, 2000);
          });
      }
    }
  };

  const [verifyingPhrase, setVerifyingPhrase] = useState(false);
  const [phraseError, setPhraseError] = useState(false);
  const handleVerifyPhrase = () => {
    setVerifyingPhrase(true);
    SecurityPhraseApi.validate_phrase({
      passphrase: {
        passphrase: phraseToVerify,
      },
    })
      .then((res) => {
        setVerifyingPhrase(false);

        if (res.data.message === "passphrase accessed") {
          setwithdrawing(true);
          setOpen(false);
          setPhraseToVerify("");
          PoolBalanceWithdrawal.pool_balance_withdrawal({
            pool_withdraw_amount: {
              network_id:
                optionState === "usdt" && Ether
                  ? "ethereum"
                  : optionState === "usdt" && Polygon
                  ? "polygon"
                  : optionState === "usdt" && Solana
                  ? "solana"
                  : optionState === "usdt" && Tron
                  ? "tron"
                  : activated.network_id,
              publicKey: walletAddress,
              amount: walletAmount,
              token: optionState === "usdt" ? true : false,
            },
          })
            .then((res) => {
              setwithdrawing(false);
              setAmmountSet(true);
              setTimeout(() => {
                setAmmountSet(false);
              }, 3000);
            })
            .catch((err) => {
              console.log(err);
              setwithdrawing(false);
              setFaild(true);
              setTimeout(() => {
                setFaild(false);
              }, 2000);
            });
        } else {
          setVerifyingPhrase(false);
          setPhraseError(true);
          setTimeout(() => {
            setPhraseError(false);
          }, 2000);
        }
      })
      .catch((err) => {
        setVerifyingPhrase(false);
        setPhraseError(true);
        setTimeout(() => {
          setPhraseError(false);
        }, 2000);
      });
  };

  return (
    <>
      <VerifyPhraseModal
        open={open}
        setOpen={setOpen}
        handleVerifyPhrase={handleVerifyPhrase}
        phraseError={phraseError}
        verifyingPhrase={verifyingPhrase}
        phraseToVerify={phraseToVerify}
        setPhraseToVerify={setPhraseToVerify}
      />
      <div
        style={{
          width: "80%",
          margin: "0 auto",
        }}
        className="withdraw-main-container"
      >
        <div className="withdraw-content-container">
          {crypto ? (
            <div className="crypto-set-content">
              {/* To paste here */}
              <div
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="wallet-crypto-dropdown-bottom my_row width"
              >
                <div className="wallet-coin-dropdown width-45">
                  <form className="relative">
                    <div className="dropDown-Coin-Img absolute">
                      <img src={imgURL} alt="" />
                    </div>
                    <select
                      style={{
                        textTransform: "uppercase",
                      }}
                      className="coinsSelect"
                      name="coins"
                      id="coins"
                      value={optionState}
                      onChange={dropdownHandle}
                    >
                      {wallets.map((currency, index) => {
                        return (
                          <option
                            key={index}
                            style={{
                              textTransform: "uppercase",
                            }}
                            value={currency.network_id}
                          >
                            {currency.network_id == "polygon"
                              ? "Matic"
                              : currency.network_id}
                          </option>
                        );
                      })}
                    </select>
                  </form>
                </div>
                <div className="wallet-coin-balance width-45 my_row end">
                  <div
                    style={{
                      marginRight: "5px",
                    }}
                  >
                    <span className="text_color">Balance</span>
                  </div>
                  <span className="bold white">
                    {activated?.network_id == "usdt"
                      ? activated?.token_balance
                      : activated?.pool_value}
                  </span>
                </div>
              </div>
              {activated?.network_id === "usdt" && (
                <div className="wallet-crypto-network-container">
                  <div className="wallet-crypto-network-content">
                    <div className="network-wallet-crypto-top">
                      <span className="text_color">Choose Network</span>
                    </div>
                    <div
                      style={{
                        margin: "20px 0",
                      }}
                      className="network-wallet-crypto-bottom"
                    >
                      <div className="crypto-wallet-network-slider">
                        <Slider {...settings}>
                          <div
                            className={`crypto-wallet-network justified ${
                              Polygon && "activeNetworkButton"
                            }`}
                            onClick={handlePolygon}
                          >
                            <span
                              style={{
                                textTransform: "uppercase",
                              }}
                              className={`${
                                Polygon ? "white" : "secondary_text"
                              } bold`}
                            >
                              Polygon
                            </span>
                          </div>
                          <div
                            className={`crypto-wallet-network justified ${
                              Solana && "activeNetworkButton"
                            }`}
                            onClick={handleSolana}
                          >
                            <span
                              style={{
                                textTransform: "uppercase",
                              }}
                              className={`${
                                Solana ? "white" : "secondary_text"
                              } bold`}
                            >
                              Solana
                            </span>
                          </div>
                          <div
                            className={`crypto-wallet-network justified ${
                              Ether && "activeNetworkButton"
                            }`}
                            onClick={handleEther}
                          >
                            <span
                              style={{
                                textTransform: "uppercase",
                              }}
                              className={`${
                                Ether ? "white" : "secondary_text"
                              } bold`}
                            >
                              ETHEREUM
                            </span>
                          </div>
                          <div
                            className={`crypto-wallet-network justified ${
                              Tron && "activeNetworkButton"
                            }`}
                            onClick={handleTron}
                          >
                            <span
                              style={{
                                textTransform: "uppercase",
                              }}
                              className={`${
                                Tron ? "white" : "secondary_text"
                              } bold`}
                            >
                              TRON
                            </span>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {optionState !== "wb" && (
                <div className="withdraw-performe">
                  <div className="withdraw-performer-content">
                    <div className="withdraw-adress-container">
                      <div
                        style={{
                          margin: "0 1.625rem .375rem 1.25rem",
                        }}
                        className="withdraw-adress text_color"
                      >
                        <span
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Withdraw address
                        </span>
                      </div>

                      <div className="withdraw-adress-field">
                        <form>
                          <input
                            style={{
                              background: "#17181b",
                              border: "none",
                              boxSizing: "border-box",
                              padding: "20px",
                            }}
                            className="width white bold"
                            type="text"
                            placeholder="Fill in carefully according to the specific currency"
                            value={walletAddress}
                            onChange={(e) => {
                              if (emptyAdress) {
                                setEmptyAdress(false);
                              }
                              setWalletAddress(e.target.value);
                            }}
                          />
                        </form>
                      </div>
                    </div>

                    <div className="withdraw-ammount-container">
                      <div
                        style={{
                          margin: ".375rem 1.625rem .375rem 1.25rem",
                          fontSize: "!2px",
                        }}
                        className="withdraw-amount text_color my_row space-between"
                      >
                        <span
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Withdraw amount
                        </span>
                      </div>
                      <div className="withdraw-amount-field relative">
                        <form>
                          <input
                            style={{
                              background: "#17181b",
                              border: "none",
                              boxSizing: "border-box",
                              padding: "20px 209px 20px 20px",
                            }}
                            className="width bold white"
                            type="number"
                            value={walletAmount}
                            onChange={(e) => {
                              setWalletAmount(e.target.value);
                            }}
                            onBlur={(e) => {
                              if (activated.network_id == "usdt") {
                                e.target.value >
                                parseFloat(activated?.token_balance)
                                  ? setWalletAmount(activated?.token_balance)
                                  : setWalletAmount(e.target.value);
                              } else {
                                e.target.value >
                                parseFloat(activated?.pool_value)
                                  ? setWalletAmount(activated?.pool_value)
                                  : setWalletAmount(e.target.value);
                              }
                            }}
                          />
                        </form>
                      </div>
                    </div>

                    <div
                      style={{
                        margin: "15px 0",
                      }}
                      className="withdraw-action-container justified"
                    >
                      {failed ? (
                        <button
                          style={{
                            border: "none",
                            padding: "20px",
                            width: "150px",
                          }}
                          className="buttons-err"
                        >
                          Failed
                        </button>
                      ) : ammountSent ? (
                        <button
                          style={{
                            border: "none",
                            padding: "20px",
                            width: "150px",
                            background:
                              "linear-gradient(to right, rgb(55, 145, 55) 50%, rgb(57, 173, 55) 50%)",
                          }}
                          className="ammount-sent white bold"
                        >
                          In Process
                        </button>
                      ) : parseFloat(walletAmount) > parseFloat(balance) ||
                        parseFloat(balance) == 0 ||
                        parseFloat(walletAmount) <= 0 ? (
                        <button className="primary-btn">Invalid Amount</button>
                      ) : (
                        <button
                          style={{
                            border: "none",
                            padding: "20px",
                            width: "150px",
                          }}
                          className="white buttons-primary"
                          onClick={() => {
                            handleWithDrawal();
                          }}
                        >
                          {withdrawing ? (
                            <div
                              style={{
                                top: "-40px",
                                height: "5px",
                              }}
                              className="lds-ellipsis"
                            >
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          ) : (
                            "Confirm"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                  {emptyAdress && (
                    <div className="invalid-adress-container centered_text">
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        Please Enter Withdraw Address
                      </p>
                    </div>
                  )}
                </div>
              )}

              {optionState == "wb" && (
                <div className="wb-info-container-main my-global">
                  <div className="jb-info-container-content">
                    <div className="about-wb">
                      <span className="secondary_text">About WB</span>
                    </div>

                    <div
                      style={{
                        margin: "15px 0",
                      }}
                      className="about-wb-typo"
                    >
                      <span className="text_color">
                        WB is the proprietary game currency (in-house game
                        currency) of BCW.CLUB platform. It can be acquired by
                        VIP level-up, lucky spin and other event rewards. In
                        addition to being used for games,WB can also be used to
                        boost daily Rakeback and substituted for withdrawal
                        fees. More amazing WB functions coming soon ..Stay
                        Tuned!
                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div className="security-purpose">
                <span
                  style={{
                    fontSize: "13px",
                  }}
                  className="text_color text-center d-block mb-5"
                >
                  For security purposes, large or suspicious withdrawal may take
                  1-6 hours for audit process. We appreciate your patience!
                </span>
              </div>
            </div>
          ) : (
            <div className="nft-set">
              <div className="wallet-crypto-dropdown-bottom justified width">
                <div className="wallet-coin-dropdown width-45">
                  <form className="relative">
                    <div className="dropDown-Coin-Img absolute">
                      <img src={imgURL} alt="" />
                    </div>
                    <select
                      style={{
                        textTransform: "uppercase",
                      }}
                      className="coinsSelect"
                      name="coins"
                      id="coins"
                      value={optionState}
                      onChange={dropdownHandle}
                    >
                      {wallets.map((currency, index) => {
                        return (
                          currency.network_id !== "wb" && (
                            <option
                              key={index}
                              style={{
                                textTransform: "uppercase",
                              }}
                              value={currency.network_id}
                            >
                              {currency.network_id}
                            </option>
                          )
                        );
                      })}
                    </select>
                  </form>
                </div>
                <div className="wallet-coin-balance width-45 my_row end">
                  <div>
                    <span className="text_color">Balance</span>
                  </div>
                  <span className="bold white">
                    {activated?.network_id == "usdt"
                      ? activated?.token_balance
                      : activated?.pool_value}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WithdrawComponent;
