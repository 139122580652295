import axiosClient from ".";

class WithdrawalsAPI {
  static getWithdrawals(page, filter, idPredicate, filterId, usernamePredicate, filterUsername, filterNetwork, networkPredicate, filterHash, hashPredicate, filterToken, tokenPredicate, startDate, endDate) {
    return axiosClient.get(`/super_admin/api/withdrawals.json?page=${page}/${filter && `&q[id_${idPredicate}]=${filterId}&q[user_username_${usernamePredicate}]=${filterUsername}&q[network_id_${networkPredicate}]=${filterNetwork}&q[tx_hash_${hashPredicate}]=${filterHash}&q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}&q[token_${tokenPredicate}]=${filterToken}`
      }`);
  }
  static viewWithdrawal(data) {
    return axiosClient.get(`/super_admin/api/withdrawals/${data.id}.json`);
  }
  static withdrawalStatus(data) {
    return axiosClient.put(`/super_admin/api/withdrawals/${data.id}`, { "status": data.status });
  }

}
export default WithdrawalsAPI;
