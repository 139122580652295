import axiosClient from ".";

class SignInAPI {
  static signIn(data) {
    return axiosClient.post(`/super_admin/api/sign_ins.json`, data);
  }
  static logout() {
    return axiosClient.delete(`/super_admin/api/logouts.json`);
  }
  static createAdminUser(data) {
    return axiosClient.post(`/super_admin/api/admin_users`, data);
  }
}
export default SignInAPI;
