import axiosClient from ".";

class AvatarAPI {
  static getAvatar(page) {
    return axiosClient.get(`/super_admin/api/avatars.json?page=${page}`);
  }
  // static createAdminUser(data) {
  //     return axiosClient.post(`/super_admin/api/admin_users.json`, data);
  //   //   (`/super_admin/api/admin_users/${data.id}.json`);
  // }

  static viewAvatar(data) {
    return axiosClient.get(`/super_admin/api/avatars/${data.id}.json`);
  }
}
export default AvatarAPI;
