import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import CustomDate from "./sub_components/date";
import FiatWithdrawAPI from "../api/fiatwithdraw"
const FiatWithdrawRequest = () => {
  const [dataToShow, setDataToShow] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  useEffect(() => {
    setDataLoading(true);
    FiatWithdrawAPI.getWithdrawals()
      .then((response) => {
        setDataToShow(response.data.list)
        setDataLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);
  let params = useParams();
  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Fiat Withdraw Request</h1>
        </div>
        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Username</th>
                  <th scope="col">Network Id</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Status</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {dataLoading ? (
                  <tr>
                    <td colspan="8">
                      <div className="loader-container">
                        <div className="lds-ring">
                          <div></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  dataToShow.map((request) => {
                    return (
                      <tr>
                        <td>{request.id}</td>
                        <td>{request.username}</td>
                        <td>{request.network_id}</td>
                        <td>{request.amount}</td>
                        <td>{request.status}</td>
                        <td>
                          <CustomDate date={request.created_at} />
                        </td>
                        <td>
                          <Link
                            to={`/fiatwithdrawal-edits/${request.id}`}
                            className="view primary-btn"
                          >
                            Edit
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiatWithdrawRequest;
