import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import SwapCurrenciesAPI from "../api/swap_currencies";
import { useSelector } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Tooltip } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomDate from "./sub_components/date";

const SwapCurrencies = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const [dataToShow, setDataToShow] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [tempPage, setTempPage] = useState(pageNumber);
  const [filter, setFilter] = useState(false);
  const [filterId, setFilterId] = useState("");
  const [idPredicate, setIdPredicate] = useState("eq");
  const [filterUsername, setFilterUsername] = useState("");
  const [usernamePredicate, setUsernamePredicate] = useState("contains");
  const [filterFromNetwork, setFilterFromNetwork] = useState("");
  const [networkFromPredicate, setFromNetworkPredicate] = useState("cont_all");
  const [filterToNetwork, setFilterToNetwork] = useState("");
  const [networkToPredicate, setToNetworkPredicate] = useState("cont_all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalData, setTotalData] = useState("");

  useEffect(() => {
    setDataLoading(true);
    SwapCurrenciesAPI.getSwapCurrencies(
      pageNumber,
      filter,
      idPredicate,
      filterId,
      usernamePredicate,
      filterUsername,
      filterFromNetwork,
      networkFromPredicate,
      filterToNetwork,
      networkToPredicate,
      startDate,
      endDate
    )
      .then((response) => {
        setDataToShow(response.data.list);
        setTotalPages(response.data.total_pages);
        setDataLoading(false);
        setTotalData(response.data.total_count);
      })
      .catch((error) => {
        console.log(error);
      })
      .catch((error) => console.log(error));
  }, [
    pageNumber,
    filter,
    filter && idPredicate,
    filter && filterId,
    filter && usernamePredicate,
    filter && filterUsername,
    filter && filterFromNetwork,
    filter && networkFromPredicate,
    filter && filterToNetwork,
    filter && networkToPredicate,
    filter && startDate,
    filter && endDate,
  ]);

  const increasePage = () => {
    if (tempPage < totalPages) {
      setPageNumber(parseFloat(pageNumber) + 1);
      setTempPage(pageNumber);
      setTempPage(parseFloat(tempPage) + 1);
    }
  };

  const decrPage = () => {
    if (tempPage > 1) {
      setPageNumber(parseFloat(pageNumber) - 1);
      setTempPage(pageNumber);
      setTempPage(parseFloat(tempPage) - 1);
    }
  };
  const handleChange = (event) => {
    setTempPage(event.target.value);
  };

  const assignPage = (e) => {
    if (e.key === "Enter") {
      setPageNumber(e.target.value);
    }
  };

  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Swap Currencies</h1>

          {/* <select className="form-control">
            <option>Batch Actions</option>
          </select> */}
        </div>
        <div className="page-filters">
          <div className="row">
            <div className="col-xl-3 col-lg-6">
              <label htmlFor="basic-url" className="form-label">
                Id
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={idPredicate}
                  onChange={(e) => {
                    setIdPredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="contains">Greater & =</option>
                  <option value="start">Less & =</option>
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterId}
                  onChange={(e) => {
                    setFilterId(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <label htmlFor="basic-url" className="form-label">
                Username
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={usernamePredicate}
                  onChange={(e) => {
                    setUsernamePredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="contains">contains</option>
                  <option value="start">Start with</option>
                  <option value="end">End with</option>
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterUsername}
                  onChange={(e) => {
                    setFilterUsername(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <label htmlFor="basic-url" className="form-label">
                From Network
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={networkFromPredicate}
                  onChange={(e) => {
                    setFromNetworkPredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="cont_all">contains</option>
                  <option value="start">start</option>
                  <option value="end">end</option>
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterFromNetwork}
                  onChange={(e) => {
                    setFilterFromNetwork(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <label htmlFor="basic-url" className="form-label">
                To Network
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={networkToPredicate}
                  onChange={(e) => {
                    setToNetworkPredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="cont_all">contains</option>
                  <option value="start">start</option>
                  <option value="end">end</option>
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterToNetwork}
                  onChange={(e) => {
                    setFilterToNetwork(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <label htmlFor="basic-url" className="form-label">
                Created At
              </label>
              <div className="input-group mb-3">
                {" "}
                <DatePicker
                  placeholderText="From"
                  selected={startDate}
                  placeholder="from"
                  onChange={(date) => setStartDate(date ? date : "")}
                />
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <label htmlFor="" className="form-label">
                &nbsp;
              </label>
              <div className="input-group mb-3">
                {" "}
                <DatePicker
                  placeholderText="To"
                  selected={endDate}
                  onChange={(date) => setEndDate(date ? date : "")}
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <label htmlFor="" className="form-label">
                &nbsp;
              </label>
              <div>
              <Tooltip
                title={filter ? "Filter Enabled" : "Filter Disabled"}
                placement="bottom"
                arrow
              >
                <button
                  className={`${filter ? "primary-btn-success" : "primary-btn-disable"} relative me-3 mb-3 primary-btn`}
                  style={{
                    padding: "8px 11px 7px",
                  }}
                  type="button"
                  onClick={() => {
                    filter ? setFilter(false) : setFilter(true);
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      background: filter ? "rgb(59 193 23)" : "rgb(237, 99, 0)",
                      borderRadius: "50%",
                      right: "-3px",
                      top: "-3px",
                    }}
                    className="filter-status-indicator absolute"
                  ></div>
                  {filter ? "Active" : "Inactive"}
                </button>
              </Tooltip>
              Total Swap Currencies {totalData}
              </div>
            </div>
          </div>
        </div>
        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Username</th>
                  <th scope="col">From Network</th>
                  <th scope="col">To Network</th>
                  <th scope="col">From Fake Amount</th>
                  <th scope="col">To Fake Amount</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {dataLoading ? (
                  <tr>
                    <td colspan="8">
                      <div className="loader-container">
                        <div className="lds-ring">
                          <div></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  dataToShow.map((obj) => {
                    return (
                      <tr>
                        <td>{obj.id}</td>
                        <td>{obj.username}</td>
                        <td>
                          {obj.from_network_id === "bcd"
                            ? "bcw"
                            : obj.from_network_id}
                        </td>
                        <td>
                          {obj.to_network_id === "bcd"
                            ? "bcw"
                            : obj.to_network_id}
                        </td>

                        <td>{obj.from_fake_amount}</td>
                        <td>{obj.to_fake_amount}</td>
                        <td>
                          <CustomDate date={obj.created_at} />
                        </td>
                        <td>
                          <Link
                            to={`/swap-details/${obj.id}`}
                            className="view primary-btn"
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            <div className="pagination-buttons-container width my_row end">
              <div className="next-pre-btn my_row align_center space-between width-15">
                <div className="pre-btn">
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                    className="white"
                    onClick={decrPage}
                  >
                    <ArrowBackIosNewIcon />
                  </button>
                </div>
                <span className="white">
                  <input
                    type="number"
                    value={tempPage}
                    style={{
                      maxWidth: "45px",
                      heigh: "10px",
                      background: "transparent",
                      border: "none",
                      color: "white",
                      textAlign: "center",
                    }}
                    onChange={handleChange}
                    onKeyPress={assignPage}
                  />
                  / {totalPages}
                </span>
                <div className="next-btn">
                  <button
                    className="white"
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                    onClick={increasePage}
                  >
                    <ArrowForwardIosIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // );
};

export default SwapCurrencies;
