import React, { useEffect, useState } from "react";
import UserWalletAPI from "../api/user_wallets";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomDate from "./sub_components/date";

const UserWalletDetail = () => {
  const navigate = useNavigate();
  const [fakeCreditAmount, setFakeCreditAmount] = useState();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const [dataToShow, setDataToShow] = useState([]);
  let params = useParams();

  useEffect(() => {
    UserWalletAPI.viewUserWallets(params)
      .then((response) => {
        setDataToShow(response.data.user_wallet);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Wallet # {dataToShow.id}</h1>
        </div>
        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds-detail">
              <tbody>
                <tr>
                  <td width="280">User Id</td>
                  <td>{dataToShow.user_id}</td>
                </tr>
                <tr>
                  <td width="280">User Name</td>
                  <td>{dataToShow.username}</td>
                </tr>
                <tr>
                  <td width="280">Amount</td>
                  <td>{dataToShow.amount}</td>
                </tr>
                <tr>
                  <td width="280">Token Balance</td>
                  <td>{dataToShow.token_balance}</td>
                </tr>
                <tr>
                  <td width="280">Network Id</td>
                  <td>{dataToShow.network_id}</td>
                </tr>
                <tr>
                  <td width="280">Default Wallet</td>
                  <td>{dataToShow.default_wallet ? "true" : "false"}</td>
                </tr>
                <tr>
                  <td width="280">Is USDT</td>
                  <td>{dataToShow.is_usdt ? "true" : "false"}</td>
                </tr>
                <tr>
                  <td width="280">Blockchain account</td>
                  <td>{dataToShow.bc_account}</td>
                </tr>
                <tr>
                  <td width="280">Fake Amount</td>
                  <td>{dataToShow.fake_amount}</td>
                </tr>
                <tr>
                  <td width="280">Token Balance</td>
                  <td>{dataToShow.token_balance}</td>
                </tr>
                <tr>
                  <td width="280">Fake Token Balance</td>
                  <td>{dataToShow.fake_token_balance}</td>
                </tr>
                <tr>
                  <td width="280">Created at</td>
                  <td>
                    <CustomDate date={dataToShow.created_at} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserWalletDetail;
