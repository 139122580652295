import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IconButton, Tooltip } from "@mui/material";
import UserToPoolOperations from "../api/pool_operations";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  background: "#24262b",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};

export default function ProceedUtoP({
  id,
  token,
  user_to_pool_status,
  pool_to_user_status,
  setStautsChanged,
  statusChanged,
}) {
  const [open, setOpen] = useState(false);

  const [feeToTransfer, setFeeToTransfer] = useState("");
  const [feeError, setFeeError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [requestDispatched, setRequestDispatched] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFeeToTransfer("");
    setFeeError(false);
    setApiError(false);
    setSuccess(false);
  };

  const transferFundsHandle = (e) => {
    if (!feeToTransfer || feeToTransfer == 0) {
      setFeeError(true);
    } else {
      setRequestDispatched(true);
      const { name, id } = e.target;

      UserToPoolOperations.transferFunds(id, {
        retry_fee: {
          fee: feeToTransfer,
          transaction_type: name === "u2pTransfer" ? 1 : 2,
        },
      })
        .then((res) => {
          if (feeError) {
            setFeeError(false);
          }
          statusChanged ? setStautsChanged(false) : setStautsChanged(true);
          setSuccess(true);
          setTimeout(() => {
            handleClose();
          }, 2000);
        })
        .catch((err) => {
          if (feeError) {
            setFeeError(false);
          }
          console.log(err);
          setApiError(true);
          setTimeout(() => {
            handleClose();
          }, 2000);
        });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {token ? (
        pool_to_user_status === "p2u_not_initiated" ||
        pool_to_user_status === "p2u_failed" ? (
          <Tooltip title="Initiate pool to user" placement="top">
            <button
              style={{
                padding: "4px 10px",
                marginLeft: "10px",
                borderRadius: "5%",
              }}
              className="buttons-primary primary-btn-danger"
              onClick={setOpen}
            >
              Retry
            </button>
          </Tooltip>
        ) : pool_to_user_status === "p2u_initiated" ? (
          <Tooltip title="Pool to user initiated please wait" placement="top">
            <button
              style={{
                padding: "4px 10px",
                marginLeft: "10px",
                borderRadius: "5%",
              }}
              className="buttons-primary"
            >
              Processing
            </button>
          </Tooltip>
        ) : (pool_to_user_status === "p2u_success" &&
            user_to_pool_status === "u2p_not_initiated") ||
          (pool_to_user_status === "p2u_success" &&
            user_to_pool_status === "u2p_failed") ? (
          <Tooltip title="Initiate user to pool" placement="top">
            <button
              style={{
                padding: "4px 10px",
                marginLeft: "10px",
                borderRadius: "5%",
              }}
              className="buttons-primary"
              onClick={setOpen}
            >
              Initiate
            </button>
          </Tooltip>
        ) : pool_to_user_status === "p2u_success" &&
          user_to_pool_status === "u2p_initiated" ? (
          <Tooltip title="User to pool initiated" placement="top">
            <button
              style={{
                padding: "4px 10px",
                marginLeft: "10px",
                borderRadius: "5%",
              }}
              className="buttons-primary"
            >
              processing
            </button>
          </Tooltip>
        ) : pool_to_user_status === "p2u_success" &&
          user_to_pool_status === "u2p_success" ? (
          <Tooltip title="All done successfylly" placement="top">
            <button
              style={{
                padding: "4px 10px",
                marginLeft: "10px",
                borderRadius: "5%",
              }}
              className="buttons-primary"
            >
              Done
            </button>
          </Tooltip>
        ) : (
          "a"
        )
      ) : user_to_pool_status === "u2p_not_initiated" ||
        user_to_pool_status === "u2p_failed" ? (
        <Tooltip title="User to pool failed please retry" placement="top">
          <button
            style={{
              padding: "4px 10px",
              marginLeft: "10px",
              borderRadius: "5%",
            }}
            className="buttons-primary primary-btn-danger"
            onClick={setOpen}
          >
            Retry
          </button>
        </Tooltip>
      ) : user_to_pool_status === "u2p_initiated" ? (
        <Tooltip title="User to pool initiated please wait" placement="top">
          <button
            style={{
              padding: "4px 10px",
              marginLeft: "10px",
              borderRadius: "5%",
            }}
            className="buttons-primary"
          >
            processing
          </button>
        </Tooltip>
      ) : (
        <Tooltip title="All done successfully" placement="top">
          <button
            style={{
              padding: "4px 10px",
              marginLeft: "10px",
              borderRadius: "5%",
            }}
            className="buttons-primary primary-btn-success"
          >
            Done
          </button>
        </Tooltip>
      )}
      {/* <Tooltip title="Pool to user initiated" placement="top">
          <button
            style={{
              padding: "4px 10px",
              marginLeft: "10px",
              borderRadius: "5%",
            }}
            className="buttons-primary"
          >
            processing...
          </button>
        </Tooltip> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="security-phrase-main">
            <div className="security-phrase-inner">
              <div className="security-phrase-content">
                <p className="white mb-2">
                  User to pool transaction processing
                </p>

                {!token && (
                  <div className="fee-capture width justified">
                    <div className="fee-capture-input-container width">
                      <input
                        className="fee-capture-input security-phrase-inputs form-control"
                        type="number"
                        placeholder="Enter gas fee"
                        value={feeToTransfer}
                        onChange={(e) => {
                          setFeeToTransfer(e.target.value);
                        }}
                      />
                      {feeError && (
                        <span
                          style={{
                            color: "red",
                          }}
                          className="rd"
                        >
                          Please enter fee
                        </span>
                      )}
                      {apiError && (
                        <span
                          style={{
                            color: "red",
                          }}
                          className="rd"
                        >
                          Something went wrnog
                        </span>
                      )}
                      {success && (
                        <span
                          style={{
                            color: "green",
                          }}
                          className="rd"
                        >
                          Success
                        </span>
                      )}
                    </div>
                    <br/><br/>
                    <div
                      style={{
                        marginTop: "5px",
                      }}
                      className="transfer-fee-button-container width justified"
                    >
                      {!requestDispatched ? (
                        <button
                          id={id}
                          name="u2pTransfer"
                          style={{
                            padding: "10px 25px",
                          }}
                          className="buttons-primary"
                          onClick={(e) => transferFundsHandle(e)}
                        >
                          Initiate User to Pool
                        </button>
                      ) : (
                        <button
                          id={id}
                          name="u2pTransfer"
                          style={{
                            padding: "10px 25px",
                          }}
                          className="buttons-disabled"
                        >
                          Request Dispatched
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {token && (
                  <div className="fee-capture width justified">
                    <div className="fee-capture-input-container width my_row align_center space-between">
                      <div className="field-container width">
                        <input
                          className="fee-capture-input security-phrase-inputs form-control"
                          type="number"
                          placeholder="Enter gas fee"
                          value={feeToTransfer}
                          onChange={(e) => {
                            setFeeToTransfer(e.target.value);
                          }}
                        />
                        {feeError && (
                          <span
                            style={{
                              color: "red",
                            }}
                            className="rd"
                          >
                            Please enter fee
                          </span>
                        )}
                        {apiError && (
                          <span
                            style={{
                              color: "red",
                            }}
                            className="rd"
                          >
                            Something went wrnog
                          </span>
                        )}
                        {success && (
                          <span
                            style={{
                              color: "green",
                            }}
                            className="rd"
                          >
                            Success
                          </span>
                        )}
                      </div>
                      <br/><br/>
                      <div className="transfer-button-container mt-2 text-center width">
                        {!requestDispatched ? (
                          <button
                            id={id}
                            name={
                              pool_to_user_status === "p2u_success"
                                ? "u2pTransfer"
                                : "p2uTransfer"
                            }
                            style={{
                              padding: "10px 25px",
                            }}
                            className="buttons-primary"
                            onClick={(e) => transferFundsHandle(e)}
                          >
                            Transfer
                          </button>
                        ) : (
                          <button
                            id={id}
                            name="u2pTransfer"
                            style={{
                              padding: "10px 25px",
                            }}
                            className="buttons-disabled"
                          >
                            Request Dispatched
                          </button>
                        )}
                      </div>
                    </div>
                    {pool_to_user_status !== "p2u_success" && (
                      <div className="transfer-fee-button-container">
                        <p className="mt-2">
                          First initiate pool to user to initiate user to pool
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
