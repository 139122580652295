import * as React from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useDispatch, useSelector } from "react-redux";
import { Link, to } from "react-router-dom";
import {
  setNotificationCount,
  deleteWithdrawalNotification,
  clearAllNotifications,
} from "../store/notifications";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  background: "#24262b",
  boxShadow: 24,
  p: 4,
};

export default function NotificationsModal() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    dispatch(
      setNotificationCount({
        notificationCount: 0,
      })
    );
    setOpen(true);
  };
  const handleClose = () => {
    dispatch(
      setNotificationCount({
        notificationCount: 0,
      })
    );
    setOpen(false);
  };
  const notifications = useSelector(
    (state) => state.notifications.withdrawalNotification
  );
  const notificationCount = useSelector(
    (state) => state.notifications.notificationCount
  );
  const deleteNotification = (id) => {
    dispatch(
      deleteWithdrawalNotification({
        id: id,
      })
    );
  };
  const clearNotifications = () => {
    dispatch(
      clearAllNotifications({
        withdrawalNotification: [],
      })
    );
  };

  return (
    <>
      <button
        className="notifications primary-btn"
        onClick={handleOpen}
      >
        {notificationCount !== 0 && (
          <div className="notifications-count absolute">
            {notificationCount > 9 ? "9+" : notificationCount}
          </div>
        )}
        <i className="fas fa-bell"></i> Notifications
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="heading-contaner my_row space-between" style={{ alignItems: "start" }}>
            <div className="heading-container">
              <p className="white">Withdrawal Notifications</p>
            </div>
            <div
              style={{
                flexDirection: "column",
                alignItems: "end",
              }}
              className="close-clear-container my_row"
            >
              <CloseIcon className="white font-size-20px pointer" onClick={handleClose} />
              <div className="clear-notifications mt-3 mb-2">
                <button
                  className="text_color font-size-14px border-0 bg-transparent"
                  onClick={clearNotifications}
                >
                  Clear All
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              height: "360px",
              overflowY: "auto",
            }}
            className="pe-1 position-relative withdrawal-notifications-container"
          >
            <div className="withdrawal-notifications">
              {notifications.length
                ? notifications.map(
                    (
                      {
                        network_id,
                        status,
                        user_id,
                        withdrawal_request_id,
                        amount,
                        created_at,
                      },
                      index
                    ) => {
                      return (
                        <Link
                          className="mb-2 d-block"
                          key={index}
                          to={`/withdrawal-edits/${withdrawal_request_id}`}
                          onClick={() => {
                            deleteNotification(withdrawal_request_id);
                            handleClose();
                          }}
                        >
                          <div
                            key={withdrawal_request_id}
                            className="bg-dark font-size-14px p-2 white withdrawal-notification rounded-2"
                          >
                            User
                            <span className="gc bold">
                              {" " + user_id + " "}
                            </span>
                            made a withdrawal request of
                            <span className="gc bold">
                              {" " + amount + " "}
                            </span>
                            <span
                              style={{
                                color: "rgb(170 123 255)",
                              }}
                            >
                              {network_id}
                            </span>
                            <div
                              className="font-size-12px mt-2 notification-date-time-container text-end text_color"
                            >
                              {new Date(created_at).toLocaleDateString("en-US")}
                              &nbsp;
                              {new Date(created_at).toLocaleTimeString("en-US")}
                            </div>
                          </div>
                        </Link>
                      );
                    }
                  )
                : <div className="align-items-center d-flex h-100 justify-content-center position-absolute w-100">No request available</div>}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
