import React, { useEffect, useState } from "react";
import BetsAPI from "../api/bets";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomDate from "./sub_components/date";
const BetDetail = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const [dataToShow, setDataToShow] = useState([]);
  let params = useParams();

  useEffect(() => {
    BetsAPI.viewBet(params)
      .then((response) => {
        setDataToShow(response.data.bet);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Bet # {dataToShow.id}</h1>
        </div>

        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds-detail">
              <tbody>
                <tr>
                  <td width="280">User Id</td>
                  <td>{dataToShow.user_id}</td>
                </tr>
                <tr>
                  <td width="280">Amount</td>
                  <td>{dataToShow.amount}</td>
                </tr>
                <tr>
                  <td width="280">Cash Out</td>
                  <td>{dataToShow.cash_out}</td>
                </tr>
                <tr>
                  <td width="280">Profit</td>
                  <td>{dataToShow.profit}</td>
                </tr>

                <tr>
                  <td width="280">Network Id</td>
                  <td>{dataToShow.network_id}</td>
                </tr>
                <tr>
                  <td width="280">Bet Round Id</td>
                  <td>{dataToShow.bet_round_id}</td>
                </tr>
                <tr>
                  <td width="280">Created at</td>
                  <td>
                    <CustomDate date={dataToShow.created_at} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetDetail;
