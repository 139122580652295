import React, { useEffect, useState } from "react";
import SwapCurrenciesAPI from "../api/swap_currencies";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const SwapCurrenciesDetails = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const [dataToShow, setDataToShow] = useState([]);
  let params = useParams();

  useEffect(() => {
    SwapCurrenciesAPI.viewSwapCurrencies(params)
      .then((response) => {
        setDataToShow(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Swap # {dataToShow.id}</h1>
        </div>

        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds-detail">
              <tbody>
                <tr>
                  <td width="280">User Id</td>
                  <td>{dataToShow.user_id}</td>
                </tr>
                <tr>
                  <td width="280">From Network Id</td>
                  <td>{dataToShow.from_network_id}</td>
                </tr>
                <tr>
                  <td width="280">To Network Id</td>
                  <td>{dataToShow.to_network_id}</td>
                </tr>
                <tr>
                  <td width="280">From Amount</td>
                  <td>{dataToShow.from_fake_amount}</td>
                </tr>
                <tr>
                  <td width="280">To Fake Amount</td>
                  <td>{dataToShow.to_fake_amount}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwapCurrenciesDetails;
