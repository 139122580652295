import React, { useEffect, useState } from "react";
import PoolAmountAPI from "../api/pool_amount";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PoolAmountModal from "./PoolAmountModal";

const PoolAmountDetail = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const [dataToShow, setDataToShow] = useState([]);
  const [poolTrigger, setPoolTrigger] = useState(false);
  const role = useSelector((state) => state.auth.role);

  let params = useParams();

  useEffect(() => {
    PoolAmountAPI.viewPoolAmount(params)
      .then((response) => {
        setDataToShow(response.data.crypto_pool);
        console.log("response.data here is the pool amount details response.data",response.data)
      })
      .catch((error) => {
        console.log(error);
      })
      .catch((error) => console.log(error));
  }, [poolTrigger]);

  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Pool Amount # {dataToShow.id}</h1>
        </div>

        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds-detail">
              <tbody>
                <tr>
                  <td width="280">Pool Value</td>
                  <td>{dataToShow.pool_value}</td>
                </tr>
                <tr>
                  <td width="280">Network ID</td>
                  <td>{dataToShow.network_id}</td>
                </tr>
                {role === "super_admin" && (
                  <tr>
                    <td width="280">Front Pool Value</td>
                    <td className="my_row space-between">
                      {dataToShow.fake_pool_value}
                      <PoolAmountModal
                        poolAmountId={dataToShow.id}
                        poolTrigger={poolTrigger}
                        setPoolTrigger={setPoolTrigger}
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <td width="280">Token Balance</td>
                  <td>{dataToShow.token_balance}</td>
                </tr>
                <tr>
                  <td width="280">Wallet Address</td>
                  <td>{dataToShow.public_key}</td>
                </tr>
                <tr>
                  <td width="280">Created At</td>
                  <td>{dataToShow.created_at}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoolAmountDetail;
