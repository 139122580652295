import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Table from "./Table";
import ReportAPI from "../api/report";

function Reports() {
  let curr = new Date(); // get current date
  let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
  let last = first - 6; // last day is the first day + 6

  let firstday = new Date(curr.setDate(first)).toUTCString();
  let lastday = new Date(curr.setDate(last)).toUTCString();
  let convertedDate = new Date(lastday);
  const [filterName, setFilterName] = useState("per_network");
  const [dataLoading, setDataLoading] = useState(false);
  const [dataToShow, setDataToShow] = useState([]);
  const [startDate, setStartDate] = useState(convertedDate);
  const [endDate, setEndDate] = useState(new Date());
  const [stateChanged, setStateChanged] = useState(false);
  const [reportType, setReportType] = useState("withdrawal");
  const [hardReportType, setHardReportType] = useState(reportType);

  useEffect(() => {
    setHardReportType(reportType);
  }, []);

  const handleReportType = (value) => {
    setReportType(value);
  };
  const handleNamechage = (value) => {
    setFilterName(value);
  };
  const handleStateChange = () => {
    setStateChanged(!stateChanged);
  };
  let filterNames = [
    {
      type: "All",
      value: "all",
    },
    {
      type: "Per Network Native",
      value: "per_network_native",
    },
    {
      type: "Per Network USD",
      value: "per_network",
    },
  ];
  let reportTypes = [
    {
      type: "withdrawal",
    },
    {
      type: "deposit",
    },
    {
      type: "summary",
    },
    {
      type: "swap",
    },
  ];
  useEffect(() => {
    setDataLoading(true);
    setHardReportType(reportType);

    ReportAPI.getReports({
      report: {
        report_type: reportType,
        currency: filterName,
        start_date: new Date(startDate).toLocaleDateString("en-US"),
        end_date: new Date(endDate).toLocaleDateString("en-US"),
      },
    })
      .then((response) => {
        setDataToShow(response.data);
        setDataLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [stateChanged]);

  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Reports</h1>
        </div>
        <div className="page-filters">
          <div className="row">
            <div className="col-xl-2 col-lg-6">
              <label htmlFor="basic-url" className="form-label">
                Report types
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text form-control"
                  id="basic-addon3"
                  value={reportType}
                  onChange={(e) => handleReportType(e.target.value)}
                >
                  {reportTypes.map(({ type }) => (
                    <option value={type}>{type}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-xl-2 col-lg-6">
              <label htmlFor="basic-url" className="form-label">
                Currency
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text form-control w-100"
                  id="basic-addon3"
                  value={filterName}
                  onChange={(e) => handleNamechage(e.target.value)}
                >
                  {filterNames.map(({ type, value }) => (
                    <option value={value}>{type}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-xl-2 col-lg-6">
              <label htmlFor="basic-url" className="form-label">
                From
              </label>
              <div className="input-group text_color ">
                <div className="input-group mb-3">
                  <DatePicker
                    placeholderText="From"
                    selected={startDate}
                    placeholder="from"
                    onChange={(date) => setStartDate(date)}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-6">
              <label htmlFor="basic-url" className="form-label text_color">
                To
              </label>
              <div className="input-group mb-3">
                <DatePicker
                  placeholderText="To"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </div>
            <div className="col-xl-2 col-lg-6">
              <label htmlFor="" className="form-label">
                &nbsp;
              </label>
              <div>
                <button
                  className="primary-btn logout"
                  style={{ padding: "8px 11px 7px" }}
                  onClick={handleStateChange}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
        <Table
          data={dataToShow}
          dataLoading={dataLoading}
          reportTypes={hardReportType}
        />
      </div>
    </div>
  );
}

export default Reports;
