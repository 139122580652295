import React, { useEffect, useState } from "react";
import BotUsersAPI from "../api/bots";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CashoutModal from "./CashoutModal";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CustomDate from "./sub_components/date";

import UpgradeIcon from "@mui/icons-material/Upgrade";
const BotDetail = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  let params = useParams();
  const [dataToShow, setDataToShow] = useState({});
  const [botWallet, setBotWallet] = useState({});
  const [minBet, setMinBet] = useState("");
  const [maxBet, setMaxBet] = useState("");
  const [cashoutTrigger, setCashoutTrigger] = useState(false);
  const [username, setUsername] = useState("");
  const [editable, setEditable] = useState(false);

  const [isActive, setIsActive] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [stateChanged, setStateChanged] = useState(false);

  const [editableAvatar, setEditableAvatar] = useState(false);
  const [preview, setPreview] = useState();
  const [updatedImage, setUpdatedImage] = useState();

  useEffect(() => {
    BotUsersAPI.getBotWallets(params)
      .then((response) => {
        setBotWallet(response.data.list);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    BotUsersAPI.viewBot(params)
      .then((response) => {
        setDataToShow(response.data.bot);
        setUsername(response.data.bot.username);
      })
      .catch((error) => console.log(error));
  }, [cashoutTrigger, stateChanged]);

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "minBet") {
      setMinBet(value);
    }
    if (name === "maxBet") {
      setMaxBet(value);
    }
  };
  const handleBetRange = () => {
    BotUsersAPI.setBetRange(dataToShow.id, {
      bot: {
        min_bet: minBet,
        max_bet: maxBet,
      },
    }).then((res) => {
      BotUsersAPI.viewBot(params)
        .then((response) => {
          setDataToShow(response.data["bot"]);
        })
        .catch((error) => console.log(error));
    });
  };
  const activateWallet = (wallet_id) => {
    BotUsersAPI.setActiveWallets(dataToShow.id, {
      bot: {
        wallet_id: wallet_id,
      },
    })
      .then((res) => {
        BotUsersAPI.getBotWallets(params)
          .then((response) => {
            setBotWallet(response.data["bot_wallets"]);
          })
          .catch((error) => console.log(error));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    editable ? setIsActive(false) : setIsActive(true);
  }, [editable]);
  const handleUsername = () => {
    if (username !== dataToShow.username) {
      BotUsersAPI.setBotUsername(dataToShow.id, {
        bot: {
          username: username,
        },
      })
        .then((response) => {
          stateChanged ? setStateChanged(false) : setStateChanged(true);
          setUpdated(true);

          setTimeout(() => {
            setUpdated(false);
          }, 2000);
        })
        .catch((error) => console.log(error));
    }
  };

  const [selectedFile, setSelectedFile] = useState();
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    // setIsSelected(true);
    if (!event.target.files || event.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    // setSelectedFile(e.target.files[0])
  };

  const handleAvatarUpdate = () => {
    if (selectedFile) {
      let formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("_method", "patch");

      BotUsersAPI.updateBotAvatar(dataToShow.id, formData)
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Bot # {dataToShow.id}</h1>
        </div>
        <div className="page-filters">
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="basic-url" className="form-label">
                Minimum Bet
              </label>
              <div className="input-group mb-3">
                <input
                  type="number"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  name="minBet"
                  value={minBet}
                  onChange={(e) => {
                    setMinBet(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <label htmlFor="basic-url" className="form-label">
                Maximum Bet
              </label>
              <input
                  type="number"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  name="maxBet"
                  value={maxBet}
                  onChange={(e) => {
                    setMaxBet(e.target.value);
                  }}
                />
            </div>
            <div className="col-md-3">
              <label htmlFor="" className="form-label">
                &nbsp;
              </label>
              <div>
                <button
                  className="primary-btn"
                  style={{
                    padding: "8px 11px 7px",
                  }}
                  type="button"
                  onClick={handleBetRange}
                >
                  Set Bet Range
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds-detail">
              <tbody>
                <tr>
                  <td width="280">Avatar</td>
                  <td>
                    <div className="avatar container my_row space-between p-0">
                      {selectedFile ? (
                        <img
                          style={{
                            width: "50px",
                            height: "50px",
                          }}
                          src={preview}
                        />
                      ) : (
                        <img
                          style={{
                            maxWidth: "15%",
                            borderRadius: "50%",
                          }}
                          src={dataToShow?.bot_avatar?.url}
                          alt=""
                        />
                      )}
                      {editableAvatar && (
                        <input
                          type="file"
                          name="file"
                          value={updatedImage}
                          onChange={changeHandler}
                          accept="image/*"
                        />
                      )}

                      {selectedFile ? (
                        <button
                          style={{
                            color: editableAvatar ? "white" : "#8f8e8d",
                            background: "transparent",
                            border: "none",
                          }}
                          onClick={handleAvatarUpdate}
                        >
                          <UpgradeIcon />
                        </button>
                      ) : (
                        <button
                          style={{
                            color: editableAvatar ? "white" : "#8f8e8d",
                            background: "transparent",
                            border: "none",
                          }}
                          onClick={() => {
                            setEditableAvatar(editableAvatar ? false : true);
                          }}
                        >
                          <EditIcon />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="280">Email</td>
                  <td>{dataToShow.email}</td>
                </tr>
                <tr>
                  <td width="280">Username</td>
                  <td>
                    <div style={{ display: "flex", minWidth: "100%", justifyContent: "space-between", }}>
                    <div className="input-update-container relative">
                      <input
                        className="form-control"
                        type="text"
                        value={dataToShow.username}
                        onChange={(e) => setUsername(e.target.value)}
                        disabled={isActive}
                      />
                      {editable && (
                        <div
                          style={{
                            right: "5px",
                            bottom: "6px",
                          }}
                          className="update-icon-container absolute"
                        >
                          <button
                            style={{
                              background: "transparent",
                              border: "none",
                              color: updated
                                ? "#61f31d"
                                : username !== dataToShow.username
                                ? "white"
                                : "#757575",
                            }}
                            onClick={handleUsername}
                          >
                            <DoneIcon />
                          </button>
                        </div>
                      )}
                    </div>
                    <button
                      style={{
                        color: editable ? "white" : "#8f8e8d",
                        background: "transparent",
                        border: "none",
                      }}
                      onClick={() => {
                        setEditable(editable ? false : true);
                      }}
                    >
                      <EditIcon />
                    </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="280">No of Wallets</td>
                  <td>{dataToShow.no_of_wallets}</td>
                </tr>
                <tr>
                  <td width="280">Freeze Assets</td>
                  <td>
                    {dataToShow.freeze_assets ? (
                      <span>Yes</span>
                    ) : (
                      <span>No</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td width="280">Minimum Bet</td>
                  <td>{dataToShow.min_bet}</td>
                </tr>
                <tr>
                  <td width="280">Maximum Bet</td>
                  <td>{dataToShow.max_bet}</td>
                </tr>

                <tr>
                  <td width="280">Cashout</td>
                  <td className="my_row space-between">
                    {dataToShow.bot_cashout}
                    <CashoutModal
                      botId={dataToShow.id}
                      cashoutTrigger={cashoutTrigger}
                      setCashoutTrigger={setCashoutTrigger}
                    />
                  </td>
                </tr>
                <tr>
                  <td width="280">Created at</td>

                  <td>
                    <CustomDate date={dataToShow.created_at} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Bot Wallets</h1>
        </div>
        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Bot Id</th>
                  <th scope="col">Network Id</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Bc Account</th>
                  <th scope="col">Status Wallet</th>
                  <th scope="col">Activate Wallet</th>
                </tr>
              </thead>
              <tbody
                style={{
                  position: "relative",
                  height: "115px",
                }}
              >
                {botWallet?.length ? (
                  botWallet.map((obj) => {
                    return (
                      <tr>
                        <td>{obj.id}</td>
                        <td>{obj.user_id}</td>
                        <td>{obj.network_id}</td>
                        <td>{obj.fake_amount}</td>
                        <td>{obj.bc_account}</td>
                        <td>
                          {obj.default_wallet ? (
                            <span>Activated</span>
                          ) : (
                            <span>Deactivated</span>
                          )}
                        </td>
                        <td>
                          {obj.default_wallet ? (
                            <span>Activated</span>
                          ) : (
                            <button
                              style={{
                                padding: "4px 10px",
                                marginLeft: "10px",
                                borderRadius: "5%",
                              }}
                              className="buttons-primary"
                              onClick={() => {
                                activateWallet(obj.id);
                              }}
                            >
                              Activate
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="7">No Wallets Found </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BotDetail;
