import consumer from "./consumer";
import { store } from "../store";
import { clearBetData } from "../store/bet";
import { setTotalBetAmount } from "../store/bet";
import { fetchPlayersData } from "../store/player";
const gameStatusSubscribe = () => {
  consumer.subscriptions.create(
    {
      channel: "GameStatusChannel",
    },
    {
      
      received: (data) => {
        if (data.game_status === "countdown_start") {
          store.dispatch(clearBetData());
          store.dispatch(setTotalBetAmount({ total_bet_amount: 0 }));
        } else if (data.game_status === "total_players") {
        }
      },
    }
  );
};
export { gameStatusSubscribe };
