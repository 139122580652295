import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import SecurityPhraseApi from "../api/security_phrase";
import Alert from "./Alert";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: "#24262b",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};

export default function VerifyPhraseModal({
  open,
  setOpen,
  handleVerifyPhrase,
  phraseError,
  verifyingPhrase,
  phraseToVerify,
  setPhraseToVerify,
}) {
  const handleClose = () => setOpen(false);

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                width: "80%",
              }}
              className="alert-container absolute"
            >
              {phraseError && (
                <Alert type="error" message="Invalid Security Phrase" />
              )}
            </div>

            <div className="verify-passPhrase-main">
              <div className="verify-passPhrase-inner">
                <div className="verify-passPhrase-content">
                  <p>Verify security phrase to process withdraw request.</p>

                  <div className="security-phrase-capture-container">
                    <p>Security Phrase</p>
                    <input
                      className="security-phrase-inputs"
                      type="text"
                      placeholder="Your Security Phrase"
                      value={phraseToVerify}
                      onChange={(e) => {
                        setPhraseToVerify(e.target.value);
                      }}
                    />
                    {phraseError ? (
                      <button
                        style={{
                          margin: "0px auto",
                          display: "flex",
                          padding: "10px 31px",
                          marginTop: "15px",
                        }}
                        className="buttons-err"
                      >
                        Invalid
                      </button>
                    ) : (
                      <button
                        style={{
                          margin: "0px auto",
                          display: "flex",
                          padding: "10px 31px",
                          marginTop: "15px",
                        }}
                        className="buttons-primary"
                        onClick={handleVerifyPhrase}
                      >
                        {verifyingPhrase ? (
                          <div
                            style={{
                              top: "-30px",
                              height: "20px",
                              left: "5px",
                            }}
                            className="lds-ellipsis"
                          >
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        ) : (
                          "Verify"
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}
