import React, { useEffect, useState } from "react";
import WithdrawalsAPI from "../api/withdrawal";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomDate from "./sub_components/date";

const WithdrawalDetails = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const [dataToShow, setDataToShow] = useState([]);
  let params = useParams();

  useEffect(() => {
    WithdrawalsAPI.viewWithdrawal(params)

      .then((response) => {
        setDataToShow(response.data.withdrawal_history);
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Withdrawal # {dataToShow.id}</h1>
        </div>
        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds-detail">
              <tbody>
                <tr>
                  <td width="280">User Id</td>
                  <td>{dataToShow.user_id}</td>
                </tr>
                <tr>
                  <td width="280">Username</td>
                  <td>{dataToShow.username}</td>
                </tr>
                <tr>
                  <td width="280">Network Id</td>
                  <td>{dataToShow.network_id}</td>
                </tr>
                <tr>
                  <td width="280">Amount</td>
                  <td>
                    {dataToShow.status === "failed" ? "" : dataToShow.amount}
                  </td>
                </tr>
                <tr>
                  <td width="280">Token</td>
                  <td>{dataToShow.token ? "USDT" : "False"}</td>
                </tr>
                <tr>
                  <td width="280">Wallet Address</td>
                  <td>{dataToShow.public_key}</td>
                </tr>
                <tr>
                  <td width="280">TxID</td>
                  <td>{dataToShow.tx_hash}</td>
                </tr>
                <tr>
                  <td width="280">Balance before withdraw</td>
                  <td>
                    {dataToShow.status === "failed"
                      ? dataToShow.updated_balance
                      : parseFloat(dataToShow.updated_balance) +
                        parseFloat(dataToShow.amount)}
                  </td>
                </tr>
                <tr>
                  <td width="280">Balance after withdraw</td>
                  <td>{dataToShow.updated_balance}</td>
                </tr>
                <tr>
                  <td width="280">Status</td>
                  <td>{dataToShow.status}</td>
                </tr>
                <tr>
                  <td width="280">Created at</td>
                  <td>
                    <CustomDate date={dataToShow.created_at} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalDetails;