import axiosClient from ".";

class BetsAPI {
  static getBets(page, filter, idPredicate, filterId, usernamePredicate, filterUsername, filterNetwork, networkPredicate, startDate, endDate) {
    return axiosClient.get(`/super_admin/api/bets.json?page=${page}/${filter && `&q[id_${idPredicate}]=${filterId}&q[user_username_${usernamePredicate}]=${filterUsername}&q[currency_name_${networkPredicate}]=${filterNetwork}&q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}`
      }`
    );
  }
  static viewBet(data) {
    return axiosClient.get(`/super_admin/api/bets/${data.id}.json`);
  }
}
export default BetsAPI;
