import "../../src/index.css";
import React, { useEffect, useState } from "react";
import { Routes, Route, NavLink, useNavigate } from "react-router-dom";

import Signin from "./Signin";
import Home from "./Home";
import AdminUser from "./AdminUser";
import BetRounds from "./BetRounds";
import AdminDetails from "./AdminDetails";
import BetRoundDetails from "./BetRoundDetails";
import Withdrawals from "./Withdrawals";
import WithdrawalDetails from "./WithdrawalDetail";
import WithdrawalApproval from "./WithdrawalApproval";
import Deposits from "./Deposits";
import FiatWithdrawApproval from "./FiatWithdrawApproval";
import Reports from "./Reports";
import DepositDetail from "./DepositDetail";
import PoolAmounts from "./PoolAmounts";
import ServerClientHashes from "./ServerClientHashes";
import ServerClientHashDetail from "./ServerClientHashDetail";
import UserWallets from "./UserWallets";
import UserWalletDetail from "./UserWalletDetail";
import FiatWithdrawRequest from "./FiatWithdrawRequest";
import SwapCurrencies from "./SwapCurrencies";
import SwapCurrenciesDetails from "./SwapCurrenciesDetails";
import Users from "./Users";
import UserDetail from "./UserDetail";
import Bets from "./Bets";
import BetDetail from "./BetDetail";
import Bots from "./Bots";
import BotDetail from "./BotDetails";
import Avatars from "./Avatars";
import AvatarDetail from "./AvatarDetails";
import PoolAmountDetail from "./PoolAmountDetail";
import ConversionRates from "./ConversionRates";
import ConversionRateDetails from "./ConversionRatesDetails";
import PoolOperations from "./PoolOperations";
import SecurityPhrase from "./SecurityPhraseModa";
import SecurityPhraseApi from "../api/security_phrase";
import { useDispatch, useSelector } from "react-redux";
import { setSecurityEnabled } from "../store/security_phrase";
import WithdrawalRequests from "./WithdrawalRequests";
import { setAuthData } from "../store/auth";
import NotificationsModal from "./NotificationsModal";
import AdminCreditLogs from "./AdminCreditLogs";
import CreateAdmin from "./CreateAdmin";
import UserToPool from "./UserToPool";
import Feedbacks from "./Feedbacks";
import BlockScans from "./BlockScans";
import TransactionScan from "./TransactionScan";

const MainBody = (children) => {
  const dispatch = useDispatch();
  useEffect(() => {
    SecurityPhraseApi.get_current_phrase()
      .then((res) => {
        dispatch(
          setSecurityEnabled({
            security: res.data,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [headerLogout, setHeaderLogout] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const role = useSelector((state) => state.auth.role);

  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const handleLogout = () => {
    dispatch(
      setAuthData({
        currentUser: null,
      })
    );
    navigate("/signin");
  };
  
  return (
    <>
      {currentUser && (
        <nav className="navbar navbar-expand navbar-light sticky-top px-3 py-0 top-nav">
          <button className="sidebar-toggler flex-shrink-0 sidebar-show-hide" onClick={() => setOpen(!open)}>
            <img src="/img/sidebar-show-hide.svg" alt="" />
          </button>
          <NavLink activeclassname="active" to="/" className="d-flex ms-3 logo">
            <img src="https://bcw.club/imgs/logo/logo.png" alt="logo" />
          </NavLink>
          {currentUser !== null && (
            <div className="navbar-nav align-items-center ms-auto">
              <div className={headerLogout ? "header-profile-box" : "header-profile-box header-profile-box-toggle"}>
                <div className="nav-item top-right-user d-flex">
                  {role === "super_admin" && <SecurityPhrase />}
                  {role !== "agent" && <NotificationsModal />}
                  <button
                    className="primary-btn logout"
                    onClick={handleLogout}
                  >
                    <i className="fas fa-sign-in-alt"></i> Logout
                  </button>
                </div>
              </div>
              <a href="#" className="header-profile-box-icon" onClick={() => setHeaderLogout(!headerLogout)}>
                <i className="fas fa-sort-down"></i>
              </a>
            </div>
          )}
        </nav>
      )}

      <div className={open ? "container-xxl position-relative d-flex p-0" : "container-xxl position-relative d-flex p-0 leftbar-content-toggle"}>
        {currentUser !== null && (
          <div className="sidebar pe-2 ps-2 pb-1">
            <nav className="navbar navbar-light">
              <div className="d-flex align-items-center py-2 w-100 dashboard-user-box">
                <div className="position-relative">
                  <img
                    className="rounded-circle user-profile-pic"
                    src="/img/user.jpg"
                    alt=""
                  />
                  <div className="bg-success rounded-circle border border-1 border-white position-absolute end-0 bottom-0 p-1"></div>
                </div>
                <div className="ms-3">
                  <h6 className="mb-0 color-white mt-1 font-size-14px font-weight-normal">
                    Welcome
                  </h6>
                  <span className="color-white font-size-11px">
                    {role === "super_admin"
                      ? "Super Admin"
                      : role === "manager"
                      ? "Manager"
                      : "Agent"}
                  </span>
                </div>
              </div>
              <div className="navbar-nav w-100">
                <NavLink activeClassName="active" to="/" className="nav-item nav-link">
                   <img src="/img/leftbaricon/dashboard.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/dashboard-white.png" alt="" className="sidebar-icon-white"/>
                   Dashboard
                </NavLink>
                <hr className="dropdown-divider" />
                <NavLink activeClassName="active" to="/admin-credit-logs" className="nav-item nav-link">
                   <img src="/img/leftbaricon/admin-credit-logs.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/admin-credit-logs-white.png" alt="" className="sidebar-icon-white"/>
                   Admin Credit Logs
                </NavLink>
                <NavLink activeClassName="active" to="/admins" className="nav-item nav-link">
                   <img src="/img/leftbaricon/admin-users.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/admin-users-white.png" alt="" className="sidebar-icon-white"/>
                   Admin Users
                </NavLink>
                <NavLink activeClassName="active" to="/avatars" className="nav-item nav-link">
                   <img src="/img/leftbaricon/avatars.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/avatars-white.png" alt="" className="sidebar-icon-white"/>
                   Avatars
                </NavLink>
                <NavLink activeClassName="active" to="/bet-rounds" className="nav-item nav-link">
                   <img src="/img/leftbaricon/bet-rounds.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/bet-rounds-white.png" alt="" className="sidebar-icon-white"/>
                   Bet Rounds
                </NavLink>
                <NavLink activeClassName="active" to="/bets" className="nav-item nav-link">
                   <img src="/img/leftbaricon/bets.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/bets-white.png" alt="" className="sidebar-icon-white"/>
                   Bets
                </NavLink>
                <NavLink activeClassName="active" to="/block-scan" className="nav-item nav-link">
                   <img src="/img/leftbaricon/block-scan.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/block-scan-white.png" alt="" className="sidebar-icon-white"/>
                   Block Scan
                </NavLink>
                <NavLink activeClassName="active" to="/bots" className="nav-item nav-link">
                   <img src="/img/leftbaricon/bots.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/bots-white.png" alt="" className="sidebar-icon-white"/>
                   Bots
                </NavLink>
                <NavLink activeClassName="active" to="/conversion-rates" className="nav-item nav-link">
                   <img src="/img/leftbaricon/conversion-rates.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/conversion-rates-white.png" alt="" className="sidebar-icon-white"/>
                   Conversion Rates
                </NavLink>
                {role === "super_admin" && (
                <NavLink activeClassName="active" to="/create-admin-user" className="nav-item nav-link">
                   <img src="/img/leftbaricon/create-admin.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/create-admin-white.png" alt="" className="sidebar-icon-white"/>
                   Create Admin
                </NavLink>
                )}
                <NavLink activeClassName="active" to="/deposits" className="nav-item nav-link">
                   <img src="/img/leftbaricon/deposits.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/deposits-white.png" alt="" className="sidebar-icon-white"/>
                   Deposits
                </NavLink>
                <NavLink activeClassName="active" to="/feedbacks" className="nav-item nav-link">
                   <img src="/img/leftbaricon/feedbacks.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/feedbacks-white.png" alt="" className="sidebar-icon-white"/>
                   Feedbacks
                </NavLink>
                <NavLink activeClassName="active" to="/fiatwithdrawal-requests" className="nav-item nav-link">
                   <img src="/img/leftbaricon/withdrawl-requests.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/withdrawl-requests-white.png" alt="" className="sidebar-icon-white"/>
                   Fiat Withdrawal Requests
                </NavLink>
                <NavLink activeClassName="active" to="/players" className="nav-item nav-link">
                   <img src="/img/leftbaricon/players.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/players-white.png" alt="" className="sidebar-icon-white"/>
                   Players
                </NavLink>
                <NavLink activeClassName="active" to="/pool-amounts" className="nav-item nav-link">
                   <img src="/img/leftbaricon/pool-amounts.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/pool-amounts-white.png" alt="" className="sidebar-icon-white"/>
                   Pool Amounts
                </NavLink>
                <NavLink activeClassName="active" to="/pool-operations" className="nav-item nav-link">
                   <img src="/img/leftbaricon/pool-operations.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/pool-operations-white.png" alt="" className="sidebar-icon-white"/>
                   Pool Operations
                </NavLink>
                <NavLink activeClassName="active" to="/Reports" className="nav-item nav-link">
                   <img src="/img/leftbaricon/reports.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/reports-white.png" alt="" className="sidebar-icon-white"/>
                   Reports
                </NavLink>
                <NavLink activeClassName="active" to="/server-client-hashes" className="nav-item nav-link">
                   <img src="/img/leftbaricon/server-client-hashes.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/server-client-hashes-white.png" alt="" className="sidebar-icon-white"/>
                   Server Client Hashes
                </NavLink>
                <NavLink activeClassName="active" to="/swap-currencies" className="nav-item nav-link">
                   <img src="/img/leftbaricon/swap-currencies.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/swap-currencies-white.png" alt="" className="sidebar-icon-white"/>
                   Swap Currencies
                </NavLink>
                <NavLink activeClassName="active" to="/transaction-scan" className="nav-item nav-link">
                   <img src="/img/leftbaricon/transaction-scan.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/transaction-scan-white.png" alt="" className="sidebar-icon-white"/>
                   Transaction Scan
                </NavLink>
                <NavLink activeClassName="active" to="/user-to-pool" className="nav-item nav-link">
                   <img src="/img/leftbaricon/user-to-pool.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/user-to-pool-white.png" alt="" className="sidebar-icon-white"/>
                   User to pool
                </NavLink>
                <NavLink activeClassName="active" to="/wallets" className="nav-item nav-link">
                   <img src="/img/leftbaricon/user-wallets.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/user-wallets-white.png" alt="" className="sidebar-icon-white"/>
                   User Wallets
                </NavLink>
                <NavLink activeClassName="active" to="/withdrawal-requests" className="nav-item nav-link">
                   <img src="/img/leftbaricon/withdrawl-requests.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/withdrawl-requests-white.png" alt="" className="sidebar-icon-white"/>
                   Withdrawal Requests
                </NavLink>
                <NavLink activeClassName="active" to="/withdrawals" className="nav-item nav-link">
                   <img src="/img/leftbaricon/withdrawls.png" alt="" className="sidebar-icon-grey"/>
                   <img src="/img/leftbaricon/withdrawls-white.png" alt="" className="sidebar-icon-white"/>
                   Withdrawals
                </NavLink>
              </div>
            </nav>
          </div>
        )}
        <Routes>
          <Route path="/signin" element={<Signin />} />
          <Route path="/create-admin-user" element={<CreateAdmin />} />
          <Route path="/block-scan" element={<BlockScans />} />
          <Route path="/transaction-scan" element={<TransactionScan />} />
          <Route path="/" element={<Home />} />
          <Route path="/admins" element={<AdminUser />} />
          <Route path="/admin-credit-logs" element={<AdminCreditLogs />} />
          <Route path="/pool-operations" element={<PoolOperations />} />
          <Route path="/admin-details/:id" element={<AdminDetails />} />
          <Route path="/bet-rounds" element={<BetRounds />} />
          <Route path="/round-details/:id" element={<BetRoundDetails />} />
          <Route path="/players" element={<Users />} />
          <Route path="/player-details/:id" element={<UserDetail />} />
          <Route path="/bets" element={<Bets />} />
          <Route path="/bet-details/:id" element={<BetDetail />} />
          <Route path="/bots" element={<Bots />} />
          <Route path="/bot-details/:id" element={<BotDetail />} />
          <Route
            path="/server-client-hashes"
            element={<ServerClientHashes />}
          />
          <Route
            path="/hash-details/:id"
            element={<ServerClientHashDetail />}
          />
          <Route path="/swap-currencies" element={<SwapCurrencies />} />
          <Route path="/swap-details/:id" element={<SwapCurrenciesDetails />} />
          <Route path="/deposits" element={<Deposits />} />
          <Route path="/deposit-details/:id" element={<DepositDetail />} />
          <Route path="/withdrawals" element={<Withdrawals />} />
          <Route path="/withdrawal-requests" element={<WithdrawalRequests />} />
          <Route path="/fiatwithdrawal-requests" element={<FiatWithdrawRequest />} />
          <Route
            path="/withdrawal-details/:id"
            element={<WithdrawalDetails />}
          />
          <Route
            path="/withdrawal-edits/:id"
            element={<WithdrawalApproval />}
          />
          <Route
            path="/fiatwithdrawal-edits/:id"
            element={<FiatWithdrawApproval />}
          />

          <Route path="/pool-amounts" element={<PoolAmounts />} />
          <Route
            path="/pool-amount-details/:id"
            element={<PoolAmountDetail />}
          />
          <Route path="/Reports" element={<Reports />} />
          <Route path="/avatars" element={<Avatars />} />
          <Route path="/avatar-details/:id" element={<AvatarDetail />} />
          <Route path="/conversion-rates" element={<ConversionRates />} />
          <Route
            path="/conversion-details/:id"
            element={<ConversionRateDetails />}
          />
          <Route path="/wallets" element={<UserWallets />} />
          <Route path="/wallet-details/:id" element={<UserWalletDetail />} />
          <Route path="/user-to-pool" element={<UserToPool />} />
          <Route path="/feedbacks" element={<Feedbacks />} />
        </Routes>
      </div>
    </>
  );
};

export default MainBody;
