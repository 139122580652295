import axiosClient from ".";

class UserWalletAPI {
  static getUserWallets(page, filter, idPredicate, filterId, usernamePredicate, filterUsername, filterNetwork, networkPredicate, filterWallet, walletPredicate) {
    return axiosClient.get(`/super_admin/api/user_wallets.json?page=${page}/${filter && `&q[id_${idPredicate}]=${filterId}&q[user_username_${usernamePredicate}]=${filterUsername}&q[currency_name_${networkPredicate}]=${filterNetwork}&q[bc_account_${walletPredicate}]=${filterWallet}`
      }`);
  }
  static viewUserWallets(data) {
    return axiosClient.get(`/super_admin/api/user_wallets/${data.id}.json`);
  }
  static addFakeCredits(userId, data) {
    return axiosClient.put(
      `/super_admin/api/users/fake-credits/${userId}`,
      data
    );
  }
}
export default UserWalletAPI;
