import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AdminUsersAPI from "../api/admin_users";
import { useSelector } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Tooltip } from "@mui/material";
import CustomDate from "./sub_components/date";

const AdminUser = () => {
  const [dataToShow, setDataToShow] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [tempPage, setTempPage] = useState(pageNumber);
  const [filter, setFilter] = useState(false);

  const [filterId, setFilterId] = useState("");
  const [idPredicate, setIdPredicate] = useState("eq");

  useEffect(() => {
    setDataLoading(true);
    AdminUsersAPI.getAdminUser(pageNumber, filter, filterId, idPredicate)
      .then((response) => {
        setDataToShow(response.data.list);
        setTotalPages(response.data.total_pages);
        setDataLoading(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .catch((error) => console.log(error));
  }, [pageNumber, filter, filter && filterId, filter && idPredicate]);

  const increasePage = () => {
    if (tempPage < totalPages) {
      setPageNumber(parseFloat(pageNumber) + 1);
      setTempPage(pageNumber);
      setTempPage(parseFloat(tempPage) + 1);
    }
  };

  const decrPage = () => {
    if (tempPage > 1) {
      setPageNumber(parseFloat(pageNumber) - 1);
      setTempPage(pageNumber);
      setTempPage(parseFloat(tempPage) - 1);
    }
  };
  const handleChange = (event) => {
    setTempPage(event.target.value);
  };

  const assignPage = (e) => {
    if (e.key === "Enter") {
      setPageNumber(e.target.value);
    }
  };
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);

  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Admin Users</h1>
        </div>
        <div className="page-filters">
          <div className="row">
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                Id
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={idPredicate}
                  onChange={(e) => {
                    setIdPredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="gteq">Greater & =</option>
                  <option value="lteq">Less & =</option>
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterId}
                  onChange={(e) => {
                    setFilterId(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                &nbsp;
              </label>
              <div>
                <Tooltip
                  title={filter ? "Filter Enabled" : "Filter Disabled"}
                  placement="right"
                  arrow
                >
                  <button
                    className={`${filter ? "primary-btn-success" : "primary-btn-disable"} primary-btn relative mb-3`}
                    style={{
                      padding: "8px 11px 7px 11px",
                    }}
                    type="button"
                    onClick={() => {
                      filter ? setFilter(false) : setFilter(true);
                    }}
                  >
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        background: filter ? "rgb(59 193 23)" : "rgb(237, 99, 0)",
                        borderRadius: "50%",
                        right: "-3px",
                        top: "-3px",
                      }}
                      className="filter-status-indicator absolute"
                    ></div>
                    {filter ? "Active" : "Inactive"}
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center rounded p-4 table-bg mb-3">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Email</th>
                  <th scope="col">Role</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {dataLoading ? (
                  <tr>
                    <td colspan="5">
                      <div className="loader-container">
                        <div className="lds-ring">
                          <div></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  dataToShow.map((obj) => {
                    return (
                      <tr>
                        <td scope="col">{obj.id}</td>
                        <td scope="col">{obj.email}</td>
                        <td scope="col">
                          {obj.role === "agent"
                            ? "Agent"
                            : obj.role === "super_admin"
                            ? "Super Admin"
                            : obj.role === "manager" && "Manager"}
                        </td>
                        <td scope="col">
                          <CustomDate date={obj.created_at} />
                        </td>
                        <td>
                          <Link
                            to={`/admin-details/${obj.id}`}
                            className="view primary-btn"
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            <div className="pagination-buttons-container width my_row end">
              <div className="next-pre-btn my_row align_center space-between width-15">
                <div className="pre-btn">
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                    className="white"
                    onClick={decrPage}
                  >
                    <ArrowBackIosNewIcon />
                  </button>
                </div>
                <span className="white">
                  <input
                    type="number"
                    value={tempPage}
                    style={{
                      maxWidth: "45px",
                      heigh: "10px",
                      background: "transparent",
                      border: "none",
                      color: "white",
                      textAlign: "center",
                    }}
                    onChange={handleChange}
                    onKeyPress={assignPage}
                  />{" "}
                  / {totalPages}
                </span>
                <div className="next-btn">
                  <button
                    className="white"
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                    onClick={increasePage}
                  >
                    <ArrowForwardIosIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // );
};

export default AdminUser;