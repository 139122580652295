import axiosClient from ".";

class SwapCurrenciesAPI {
  static getSwapCurrencies(page, filter, idPredicate, filterId, usernamePredicate, filterUsername, filterFromNetwork, networkFromPredicate,  filterToNetwork, networkToPredicate, startDate, endDate) {
    return axiosClient.get(`/super_admin/api/swap_currencies.json?page=${page}/${filter && `&q[id_${idPredicate}]=${filterId}&q[user_username_${usernamePredicate}]=${filterUsername}&q[from_network_id_${networkFromPredicate}]=${filterFromNetwork}&q[to_network_id_${networkToPredicate}]=${filterToNetwork}&q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}`
      }`);
  }
  static viewSwapCurrencies(data) {
    return axiosClient.get(`/super_admin/api/swap_currencies/${data.id}.json`);
  }
}
export default SwapCurrenciesAPI;
