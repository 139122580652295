import React, { useEffect, useState } from "react";
import SignInAPI from "../api/signin";
import BasicAlerts from "./Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CreateAdmin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("Super Admin");
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const adminRole = useSelector((state) => state.auth.role);
  const navigate = useNavigate();
  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    }
    if (name === "password") {
      setPassword(value);
    }
    if (name === "role") {
      setRole(value);
    }
  };
  const handleCreateAdmin = () => {
    if (!email || !password || !role) {
      setAlert(true);
      setType("warning");
      setMessage("Fields cannot be empty");
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    } else {
      setLoading(true);
      SignInAPI.createAdminUser({
        admin_user: {
          email: email,
          password: password,
          role:
            role === "Super Admin"
              ? "super_admin"
              : role === "Manager"
              ? "manager"
              : "agent",
        },
      })
        .then((res) => {
          setLoading(false);
          if (res.data.error) {
            setAlert(true);
            setType("error");
            setMessage(res.data.error.email[0]);
            setTimeout(() => {
              setAlert(false);
            }, 3000);
          } else {
            setAlert(true);
            setType("success");
            setMessage(res.data.msg);
            setTimeout(() => {
              setAlert(false);
            }, 3000);
          }
        })
        .catch((err) => {
          setLoading(false);
          setType("error");
          setMessage("Failed to create admin");
          setAlert(true);
          setTimeout(() => {
            setAlert(false);
          }, 3000);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (adminRole !== "super_admin") {
      navigate("/");
    }
  }, []);

  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <div style={{ width: "80%" }} className="alert-container absolute">
            {alert && <BasicAlerts type={alertType} message={message} />}
            {/* {ammountSent && (
              <BasicAlerts type="success" message="Withdrawal In Process" />
            )} */}
          </div>
          <h1>Create Admin User</h1>
        </div>
        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds-detail">
              <tbody>
                <tr>
                  <td width="280">Email</td>
                  <td>
                    <input
                      className="security-phrase-inputs form-control"
                      type="email"
                      name="email"
                      value={email}
                      placeholder="Enter email address"
                      id=""
                      onChange={handleInput}
                    />
                  </td>
                </tr>
                <tr>
                  <td width="280">Password</td>
                  <td>
                    <input
                      className="security-phrase-inputs form-control"
                      type="password"
                      value={password}
                      name="password"
                      onChange={handleInput}
                      placeholder="enter password"
                      id=""
                    />
                  </td>
                </tr>
                <tr>
                  <td width="280">Role</td>
                  <td>
                    <select
                      className="form-control status form-control"
                      value={role}
                      onChange={(e) => {
                        setRole(e.target.value);
                      }}
                    >
                      <option>Super Admin</option>
                      <option>Manager</option>
                      <option>Agent</option>
                    </select>
                  </td>
                </tr>

                <tr>
                  <td width="280"></td>
                  <td
                    style={{
                      gap: "20px",
                    }}
                    className="my_row"
                  >
                    <button
                      style={{
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                      className="buttons-primary"
                      onClick={handleCreateAdmin}
                    >
                      {loading ? "Creating..." : "Create Admin"}
                    </button>
                    {loading && <CircularProgress color="inherit" />}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAdmin;
