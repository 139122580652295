import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import BetRoundsAPI from "../api/bet_rounds";
import { useSelector } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Tooltip } from "@mui/material";
import DatePicker from "react-datepicker";
import CustomDate from "./sub_components/date";
import "react-datepicker/dist/react-datepicker.css";

const BetRounds = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const [dataToShow, setDataToShow] = useState([]);
  const [totalData, setTotalData] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [tempPage, setTempPage] = useState(pageNumber);
  const [filter, setFilter] = useState(false);
  const [filterId, setFilterId] = useState("");
  const [idPredicate, setIdPredicate] = useState("eq");
  const [filterMultiplier, setFilterMultiplier] = useState("");
  const [multiplierPredicate, setMultiplierPredicate] = useState("eq");
  const [filterActive, setFilterActive] = useState(1);
  const [activePredicate, setActivePredicate] = useState("not_null");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    setDataLoading(true);
    BetRoundsAPI.getBetRound(
      pageNumber,
      filter,
      filterId,
      idPredicate,
      filterMultiplier,
      multiplierPredicate,
      filterActive,
      activePredicate,
      startDate,
      endDate
    )
      .then((response) => {
        setDataToShow(response.data.list);
        setTotalPages(response.data.total_pages);
        setDataLoading(false);
        setTotalData(response.data.total_count);
      })
      .catch((error) => console.log(error));
  }, [
    pageNumber,
    filter,
    filter && filterId,
    filter && idPredicate,
    filter && filterMultiplier,
    filter && multiplierPredicate,
    filter && filterActive,
    filter && activePredicate,
    filter && startDate,
    filter && endDate,
  ]);

  // return (
  //   {
  const activeStatus = () => {
    if (activePredicate === "not_false") {
      setFilterActive(1);
    } else if (activePredicate === "false") {
      setFilterActive(1);
    } else if (activePredicate === "not_null") {
      setFilterActive(1);
    }
  };
  const increasePage = () => {
    if (tempPage < totalPages) {
      setPageNumber(parseFloat(pageNumber) + 1);
      setTempPage(pageNumber);
      setTempPage(parseFloat(tempPage) + 1);
    }
  };

  const decrPage = () => {
    if (tempPage > 1) {
      setPageNumber(parseFloat(pageNumber) - 1);
      setTempPage(pageNumber);
      setTempPage(parseFloat(tempPage) - 1);
    }
  };
  const handleChange = (event) => {
    setTempPage(event.target.value);
  };

  const assignPage = (e) => {
    if (e.key === "Enter") {
      setPageNumber(e.target.value);
    }
  };
  return (
    <div className="content">
      <div className="container-fluid pt-4 px-4">
        <div className="page-title">
          <h1>Bet Rounds</h1>
        </div>
        <div className="page-filters mb-0">
          <div className="row">
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                Id
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={idPredicate}
                  onChange={(e) => {
                    setIdPredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="gteq">Greater & =</option>
                  <option value="lteq">Less & =</option>
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterId}
                  onChange={(e) => {
                    setFilterId(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                Multiplier No
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={multiplierPredicate}
                  onChange={(e) => {
                    setMultiplierPredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="gteq">Greater & =</option>
                  <option value="lteq">Less & =</option>
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterMultiplier}
                  onChange={(e) => {
                    setFilterMultiplier(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                Created At
              </label>
              <div className="input-group mb-3">
                <DatePicker
                  placeholderText="From"
                  selected={startDate}
                  onChange={(date) => setStartDate(date ? date : "")}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                &nbsp;
              </label>
              <div className="input-group mb-3">
                <DatePicker
                  placeholderText="To"
                  selected={endDate}
                  onChange={(date) => setEndDate(date ? date : "")}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <label htmlFor="basic-url" className="form-label">
                Active
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text form-control"
                  id="basic-addon3"
                  value={activePredicate}
                  onChange={(e) => {
                    setActivePredicate(e.target.value);
                    activeStatus();
                  }}
                >
                  <option value="not_null">Any</option>
                  <option value="not_false">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
            <div className="col-lg-9 col-xl-9">
              <label htmlFor="" className="form-label">
                &nbsp;
              </label>
              <div>
              <Tooltip
                title={filter ? "Filter Enabled" : "Filter Disabled"}
                placement="bottom"
                arrow
              >
                <button
                  className={`${filter ? "primary-btn-success" : "primary-btn-disable"} primary-btn relative me-3 mb-3`}
                  style={{
                    padding: "8px 11px 7px",
                  }}
                  type="button"
                  onClick={() => {
                    filter ? setFilter(false) : setFilter(true);
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      background: filter ? "rgb(59 193 23)" : "rgb(237, 99, 0)",
                      borderRadius: "50%",
                      right: "-3px",
                      top: "-3px",
                    }}
                    className="filter-status-indicator absolute"
                  ></div>
                  {filter ? "Active" : "Inactive"}
                </button>
              </Tooltip>
              Total BetRounds {totalData}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid pt-4 pb-4 px-4 mb-4">
        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Is Active</th>
                  <th scope="col">Game State</th>
                  <th scope="col">Multiplier No.</th>

                  <th scope="col">Total Bet</th>
                  {/* <th scope="col">Total Cashedout</th> */}

                  <th scope="col">Total Final User Profit</th>
                  <th scope="col">Total Final User Loss</th>
                  <th scope="col">Total BCW Profit / Loss</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {dataLoading ? (
                  <tr>
                    <td colspan="10">
                      <div className="loader-container">
                        <div className="lds-ring">
                          <div></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  dataToShow.map((obj) => {
                    return (
                      <>
                        <tr>
                          <td>{obj.id}</td>
                          <td>
                            {obj.is_active ? (
                              <button
                                type="button"
                                className="btn btn-secondary rounded-pill yes"
                              >
                                Yes
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-secondary rounded-pill no"
                              >
                                No
                              </button>
                            )}
                          </td>
                          <td>{obj.game_state}</td>
                          <td>{obj.multiplier_no}</td>
                          <td>{parseFloat(obj.total_bet_amount).toFixed(2)}</td>
                          <td>{parseFloat(obj.total_profit).toFixed(2)}</td>
                          <td>{parseFloat(obj.total_loss).toFixed(2)}</td>
                          <td>
                            <span
                              style={{
                                color: parseFloat(obj.bcw_stats).toFixed(2)
                                  ? "green"
                                  : "red",
                              }}
                            >
                              {parseFloat(obj.bcw_stats).toFixed(2)}
                            </span>
                          </td>
                          <td>
                            <CustomDate date={obj.created_at} />
                          </td>
                          <td>
                            <Link
                              to={`/round-details/${obj.id}`}
                              className="view  primary-btn"
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      </>
                    );
                  })
                )}
              </tbody>
            </table>
            <div className="pagination-buttons-container width my_row end">
              <div className="next-pre-btn my_row align_center space-between width-15">
                <div className="pre-btn">
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                    className="white"
                    onClick={decrPage}
                  >
                    <ArrowBackIosNewIcon />
                  </button>
                </div>
                <span className="white">
                  <input
                    type="number"
                    value={tempPage}
                    style={{
                      maxWidth: "45px",
                      heigh: "10px",
                      background: "transparent",
                      border: "none",
                      color: "white",
                      textAlign: "center",
                    }}
                    onChange={handleChange}
                    onKeyPress={assignPage}
                  />{" "}
                  / {totalPages}
                </span>
                <div className="next-btn">
                  <button
                    className="white"
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                    onClick={increasePage}
                  >
                    <ArrowForwardIosIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetRounds;