import axiosClient from ".";

class ServerClientHashesAPI {
  static getServerClientHashes(page) {
    return axiosClient.get(
      `/super_admin/api/server_client_hashes.json?page=${page}`
    );
  }
  static viewServerClientHashes(data) {
    return axiosClient.get(
      `/super_admin/api/server_client_hashes/${data.id}.json`
    );
  }
}
export default ServerClientHashesAPI;
