import axiosClient from ".";

class ConversionRateAPI {
  static getConversionRates(page) {
    return axiosClient.get(`/super_admin/api/conversion_rates.json?page=${page}`);
  }
  static viewConversionRate(data) {
    return axiosClient.get(`/super_admin/api/conversion_rates/${data.id}.json`);
  }
}
export default ConversionRateAPI;
