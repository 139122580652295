import React, { useEffect, useState } from "react";
import DepositsAPI from "../api/deposits";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomDate from "./sub_components/date";

const DepositDetail = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const [dataToShow, setDataToShow] = useState([]);
  let params = useParams();

  useEffect(() => {
    DepositsAPI.viewDeposit(params)
      .then((response) => {
        setDataToShow(response.data.deposits);
      })
      .catch((error) => {
        console.log(error);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Deposit # {dataToShow.id}</h1>
        </div>

        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds-detail">
              <tbody>
                <tr>
                  <td width="280">Transaction ID</td>
                  <td>{dataToShow.id}</td>
                </tr>
                <tr>
                  <td width="280">User ID</td>
                  <td>{dataToShow.user_id}</td>
                </tr>
                <tr>
                  <td width="280">Network ID</td>
                  <td>
                    {dataToShow.network_id === "bcd"
                      ? "bcw"
                      : dataToShow.network_id}
                  </td>
                </tr>
                <tr>
                  <td width="280">Topup</td>
                  <td>{dataToShow.top_up}</td>
                </tr>
                <tr>
                  <td width="280">Previous Balance</td>
                  <td>{dataToShow.previous_balance}</td>
                </tr>
                <tr>
                  <td width="280">Transaction Type</td>
                  <td>{dataToShow.transaction_type}</td>
                </tr>
                {dataToShow.tx_hash && (
                  <tr>
                    <td width="280">Tx Hash</td>
                    <td>{dataToShow.tx_hash}</td>
                  </tr>
                )}
                <tr>
                  <td width="280">Topup At</td>
                  <td>
                    <CustomDate date={dataToShow.created_at} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DepositDetail;
