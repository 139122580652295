import axiosClient from ".";

class BotUsersAPI {
  static getBots(
    page,
    filter,
    idPredicate,
    filterId,
    usernamePredicate,
    filterUsername,
    filterNetwork,
    networkPredicate,
    startDate,
    endDate
  ) {
    return axiosClient.get(
      `/super_admin/api/bots.json?page=${page}/${
        filter &&
        `&q[id_${idPredicate}]=${filterId}&q[username_${usernamePredicate}]=${filterUsername}&q[user_wallets_default_wallet_true]=1&q[user_wallets_currency_name_${networkPredicate}]=${filterNetwork}&q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}`
      }`
    );
  }

  static viewBot(data) {
    return axiosClient.get(`/super_admin/api/bots/${data.id}.json`);
  }

  static createBots(data) {
    return axiosClient.post(`/super_admin/api/bots.json`, data);
  }

  static setBetRange(id, data) {
    return axiosClient.patch(
      `/super_admin/api/bots/set_bet_range/${id}.json`,
      data
    );
  }

  static setBotCashout(id, data) {
    return axiosClient.patch(
      `/super_admin/api/bots/set_cashout/${id}.json`,
      data
    );
  }

  static getBotWallets(data) {
    return axiosClient.get(
      `/super_admin/api/bots/show_wallets/${data.id}.json`
    );
  }

  static setActiveWallets(id, data) {
    return axiosClient.patch(
      `/super_admin/api/bots/set_active_wallet/${id}.json`,
      data
    );
  }

  static setActiveBot(id, data) {
    return axiosClient.patch(
      `/super_admin/api/bots/set_bot_can_bet/${id}`,
      data
    );
  }

  static setAllBotActive(data) {
    return axiosClient.patch(`/super_admin/api/bots/activate_bot_bets`, data);
  }

  static setBotUsername(id, data) {
    return axiosClient.patch(
      `/super_admin/api/bots/set_bot_username/${id}`,
      data
    );
  }
  static updateBotAvatar(id, data) {
    return axiosClient.post(`/super_admin/api/bots_profiles/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  static setCurrentPageBot(page, data) {
    return axiosClient.patch(
      `/super_admin/api/bots/active_deactive_current_page_bots.json?page=${page}`,
      data
    );
  }
}

export default BotUsersAPI;
