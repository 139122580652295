import React, { useEffect, useState } from "react";
import SignInAPI from "../api/signin";
import { setAuthData } from "../store/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Signin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  // const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const timeZone = "Asia/Shanghai";
  const handleSignin = () => {
    SignInAPI.signIn({
      sign_in: {
        email: email,
        password: password,
        time_zone: timeZone,
      },
    })
      .then((response) => {
        dispatch(
          setAuthData({
            currentUser: {
              id: response.data.id,
              email: response.data.email,
            },
            token: response.headers.authorization,
            role: response.data.role,
          })
        );

        localStorage.setItem("token", response.headers.authorization);
        navigate("/");
      })
      .catch((error) => console.log(error));
  };
  return (
    <div className="container-fluid">
      <div
        className="row h-100 align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
          <div className="signin-box rounded p-4 p-sm-5 my-4 mx-3">
            <div className="text-center">
              <img src="https://bcw.club/imgs/logo/logo.png" className="mb-4 w-75" alt=""/>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-3">
              <h5 className="white mb-0">Sign In</h5>
            </div>
            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <label htmlFor="floatingInput">Email address</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="password"
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <label htmlFor="floatingPassword">Password</label>
            </div>

            <button
              type="submit"
              className="primary-btn w-100"
              onClick={handleSignin}
            >
              Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
