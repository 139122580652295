import axiosClient from ".";

class WithdrawalRequestAPI {
  static get_withdrawal_requests(
    page,
    filter,
    idPredicate,
    filterId,
    usernamePredicate,
    filterUsername,
    filterNetwork,
    networkPredicate,
    filterHash,
    hashPredicate,
    startDate,
    endDate,
    sessionTokenPredicate
  ) {
    return axiosClient.get(
      `/super_admin/api/withdrawal_requests.json?page=${page}/${
        filter
          ? `&q[id_${idPredicate}]=${filterId}&q[user_username_${usernamePredicate}]=${filterUsername}&q[network_id_${networkPredicate}]=${filterNetwork}&q[public_key_${hashPredicate}]=${filterHash}&q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}&q[token_${sessionTokenPredicate}]=1`
          : ""
      }`,
      {
        headers: {
          Accept: "*/*",
        },
      }
    );
  }
  static view_withdrawal_request(id) {
    return axiosClient.get(`/super_admin/api/withdrawal_requests/${id}.json`);
  }
  static update_withdrawal_request(id, data) {
    return axiosClient.put(`/super_admin/api/withdrawal_requests/${id}`, data);
  }
}

export default WithdrawalRequestAPI;
