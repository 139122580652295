import React from "react";
import BetRoundsAPI from "../api/bet_rounds";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomDate from "./sub_components/date";
const BetRoundDetails = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const [dataToShow, setDataToShow] = useState({});
  const [roundUsers, setRoundUsers] = useState([]);
  let params = useParams();
  useEffect(() => {
    BetRoundsAPI.viewBetRound(params)
      .then((response) => {
        setDataToShow(response.data.bet_round);
        setRoundUsers(response.data.list);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Bet Round # {dataToShow.id}</h1>
        </div>
        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds-detail">
              <tbody>
                <tr>
                  <td>Is Active</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-secondary rounded-pill no"
                    >
                      <td>{dataToShow.is_active ? "True" : "False"}</td>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Game Id</td>
                  <td>{dataToShow.game_id}</td>
                </tr>
                <tr>
                  <td>Game state</td>
                  <td>{dataToShow.game_state}</td>
                </tr>
                <tr>
                  <td>Created at</td>
                  <td>
                    <CustomDate date={dataToShow.created_at} />
                  </td>
                </tr>
                <tr>
                  <td>Multiplier No</td>
                  <td>{dataToShow.multiplier_no}</td>
                </tr>
                <tr>
                  <td>TOTAL BET AMOUNT BTC</td>
                  <td>{dataToShow.fake_total_bet_amount_btc}</td>
                </tr>
                <tr>
                  <td>TOTAL BET AMOUNT BCW</td>
                  <td>{dataToShow.fake_total_bet_amount_bcw}</td>
                </tr>
                <tr>
                  <td>TOTAL BET AMOUNT ETH</td>
                  <td>{dataToShow.fake_total_bet_amount_eth}</td>
                </tr>
                <tr>
                  <td>TOTAL BET AMOUNT IDR</td>
                  <td>{dataToShow.fake_total_bet_amount_idr}</td>
                </tr>
                <tr>
                  <td>TOTAL BET AMOUNT PHP</td>
                  <td>{dataToShow.fake_total_bet_amount_php}</td>
                </tr>
                <tr>
                  <td>TOTAL BET AMOUNT POLYGON</td>
                  <td>{dataToShow.fake_total_bet_amount_polygon}</td>
                </tr>
                <tr>
                  <td>TOTAL BET AMOUNT SOL</td>
                  <td>{dataToShow.fake_total_bet_amount_sol}</td>
                </tr>
                <tr>
                  <td>TOTAL BET AMOUNT THB</td>
                  <td>{dataToShow.fake_total_bet_amount_thb}</td>
                </tr>
                <tr>
                  <td>TOTAL BET AMOUNT TRON</td>
                  <td>{dataToShow.fake_total_bet_amount_tron}</td>
                </tr>
                <tr>
                  <td>TOTAL BET AMOUNT USDT</td>
                  <td>{dataToShow.fake_total_bet_amount_usdt}</td>
                </tr>
                <tr>
                  <td>TOTAL BET AMOUNT WB</td>
                  <td>{dataToShow.fake_total_bet_amount_wb}</td>
                </tr>
                <tr>
                  <td>TOTAL CASHEDOUT BET AMOUNT BCW</td>
                  <td>{dataToShow.fake_total_cashedout_amount_bcw}</td>
                </tr>
                <tr>
                  <td>TOTAL CASHEDOUT BET AMOUNT BTC</td>
                  <td>{dataToShow.fake_total_cashedout_amount_btc}</td>
                </tr>
                <tr>
                  <td>TOTAL CASHEDOUT BET AMOUNT ETH</td>
                  <td>{dataToShow.fake_total_cashedout_amount_eth}</td>
                </tr>
                <tr>
                  <td>TOTAL CASHEDOUT BET AMOUNT PHP</td>
                  <td>{dataToShow.fake_total_cashedout_amount_php}</td>
                </tr>
                <tr>
                  <td>TOTAL CASHEDOUT BET AMOUNT IDR</td>
                  <td>{dataToShow.fake_total_cashedout_amount_idr}</td>
                </tr>
                <tr>
                  <td>TOTAL CASHEDOUT BET AMOUNT Polygon</td>
                  <td>{dataToShow.fake_total_cashedout_amount_polygon}</td>
                </tr>
                <tr>
                  <td>TOTAL CASHEDOUT BET AMOUNT SOL</td>
                  <td>{dataToShow.fake_total_cashedout_amount_sol}</td>
                </tr>
                <tr>
                  <td>TOTAL CASHEDOUT BET AMOUNT TRON</td>
                  <td>{dataToShow.fake_total_cashedout_amount_tron}</td>
                </tr>
                <tr>
                  <td>TOTAL CASHEDOUT BET AMOUNT THB</td>
                  <td>{dataToShow.fake_total_cashedout_amount_thb}</td>
                </tr>
                <tr>
                  <td>TOTAL CASHEDOUT BET AMOUNT USDT</td>
                  <td>{dataToShow.fake_total_cashedout_amount_usdt}</td>
                </tr>
                <tr>
                  <td>TOTAL CASHEDOUT BET AMOUNT WB</td>
                  <td>{dataToShow.fake_total_cashedout_amount_wb}</td>
                </tr>
                <tr>
                  <td>TOTAL PROFIT AMOUNT BCW</td>
                  <td>{dataToShow.fake_total_profit_amount_bcw}</td>
                </tr>
                <tr>
                  <td>TOTAL PROFIT AMOUNT BTC</td>
                  <td>{dataToShow.fake_total_profit_amount_btc}</td>
                </tr>
                <tr>
                  <td>TOTAL PROFIT AMOUNT ETH</td>
                  <td>{dataToShow.fake_total_profit_amount_eth}</td>
                </tr>
                <tr>
                  <td>TOTAL PROFIT AMOUNT IDR</td>
                  <td>{dataToShow.fake_total_profit_amount_idr}</td>
                </tr>
                <tr>
                  <td>TOTAL PROFIT AMOUNT PHP</td>
                  <td>{dataToShow.fake_total_profit_amount_php}</td>
                </tr>
                <tr>
                  <td>TOTAL PROFIT AMOUNT Polygon</td>
                  <td>{dataToShow.fake_total_profit_amount_polygon}</td>
                </tr>
                <tr>
                  <td>TOTAL PROFIT AMOUNT SOL</td>
                  <td>{dataToShow.fake_total_profit_amount_sol}</td>
                </tr>
                <tr>
                  <td>TOTAL PROFIT AMOUNT THB</td>
                  <td>{dataToShow.fake_total_profit_amount_thb}</td>
                </tr>
                <tr>
                  <td>TOTAL PROFIT AMOUNT TRON</td>
                  <td>{dataToShow.fake_total_profit_amount_tron}</td>
                </tr>
                <tr>
                  <td>TOTAL PROFIT AMOUNT USDT</td>
                  <td>{parseFloat(dataToShow.fake_total_profit_amount_usdt).toFixed(2)}</td>
                </tr>
                <tr>
                  <td>TOTAL PROFIT AMOUNT WB</td>
                  <td>{dataToShow.fake_total_profit_amount_wb}</td>
                </tr>
                <tr>
                  <td>TOTAL BET AMOUNT</td>
                  <td>{dataToShow.total_bet_amount}</td>
                </tr>
                <tr>
                  <td>TOTAL CASHOUT BET AMOUNT </td>
                  <td>{dataToShow.total_cashedout_bet_amount}</td>
                </tr>
                <tr>
                  <td>TOTAL CASHOUT PLAYERS</td>
                  <td>{dataToShow.total_cashedout_players}</td>
                </tr>
                <tr>
                  <td>TOTAL LOSS</td>
                  <td>{dataToShow.total_loss}</td>
                </tr>
                <tr>
                  <td>TOTAL PLAYERS</td>
                  <td>{dataToShow.total_players}</td>
                </tr>
                <tr>
                  <td>TOTAL PROFIT</td>
                  <td>{dataToShow.total_profit}</td>
                </tr>
                <tr>
                  <td>Client Hash</td>
                  <td>{dataToShow.client_hash}</td>
                </tr>
                <tr>
                  <td>Server Hash</td>
                  <td>{dataToShow.server_hash}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="container-fluid pt-4 px-4">
        <div className="page-title">
          <h1 className="mb-0">Bets</h1>
        </div>
      </div>
      <div className="container-fluid pt-4 pb-4 px-4 mb-4">
        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds">
              <thead>
                <tr>
                  <th scope="col">Bet ID</th>
                  <th scope="col">User ID</th>
                  <th scope="col">Bet Amount</th>
                  <th scope="col">Network ID</th>
                  <th scope="col">Cashout</th>
                  <th scope="col">Auto Cashout</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Updated At</th>
                  <th scope="col">Profit</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody
                style={{
                  position: "relative",
                  height: "115px",
                }}
              >
                {roundUsers?.length ? (
                  roundUsers?.map((user) => {
                    return (
                      <tr>
                        <td>{user.id}</td>
                        <td>{user.user_id}</td>
                        <td>{user.amount}</td>
                        <td>{user.network_id}</td>
                        <td>{user.cash_out}x</td>
                        <td>{user.auto_cashout}x</td>
                        <td>
                          <CustomDate date={user.created_at} />
                        </td>
                        <td>
                          <CustomDate date={user.updated_at} />
                        </td>
                        <td>{user.profit}</td>
                        <td>{user.win_status ? "Won" : "Lost"}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colspan="10">
                      <img src="/img/empty.png" className="w-auto mt-3 mb-3" alt=""/>
                      <p className="mb-0">Sorry no players</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetRoundDetails;
