import React, { useEffect, useState } from "react";
import ConversionRateAPI from "../api/conversion_rates";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const ConversionRateDetails = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const [dataToShow, setDataToShow] = useState([]);
  let params = useParams();

  useEffect(() => {
    ConversionRateAPI.viewConversionRate(params)
      .then((response) => {
        setDataToShow(response.data.conversion_rates);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Conversion Rate # {dataToShow.id}</h1>
        </div>

        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds-detail">
              <tbody>
                <tr>
                  <td width="280">ID</td>
                  <td>{dataToShow.id}</td>
                </tr>
                <tr>
                  <td width="280">Created At</td>
                  <td>{dataToShow.created_at}</td>
                </tr>
                <tr>
                  <td width="280">Rate</td>
                  <td>{dataToShow.rate}</td>
                </tr>
                <tr>
                  <td width="280">From Currency</td>
                  <td>{dataToShow.from_currency}</td>
                </tr>
                <tr>
                  <td width="280">To Currency</td>
                  <td>{dataToShow.to_currency}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversionRateDetails;
