import axiosClient from ".";

class PlayersAPI {
  static getPlayers(
    pageNumber,
    filter,
    filterId,
    filterName,
    idPredicate,
    namePredicate,
    filterEmail,
    emailPredicate,
    startDate,
    endDate
  ) {
    return axiosClient.get(
      `/super_admin/api/users.json?page=${pageNumber}/${
        filter &&
        `&q[email_${emailPredicate}]=${filterEmail}&q[username_${namePredicate}]=${filterName}&q[id_${idPredicate}]=${filterId}&q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}`
      }`
    );
  }
  static viewPlayer(data) {
    return axiosClient.get(`/super_admin/api/users/${data.id}.json`);
  }
  static disableTwoFactor(id) {
    return axiosClient.get(`/super_admin/api/users/disable_2fa_status/${id}`);
  }
  static changeUserEmail(id, data) {
    return axiosClient.patch(`/super_admin/api/users/edit_email/${id}`, data);
  }
}
export default PlayersAPI;

/// integer_lteq_any
// integer_gteq_any
// integer_eq

// string_contains
// string_eq
// string_start_any
// string_end_any
