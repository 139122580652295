import React, { useState } from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import SecurityPhraseApi from "../api/security_phrase";
import Alert from "./Alert";
import { setSecurityEnabled } from "../store/security_phrase";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  background: "#24262b",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};

export default function SecurityPhrase() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const phraseActive = useSelector((state) => state.phrase.security);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [currentPhrase, setCurrentPhrase] = useState("");
  const [newPhrase, setNewPhrase] = useState("");
  const [confirmPhrase, setConfirmPhrase] = useState("");
  const [invalidCurrent, setInvalidCurrent] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [notMatched, setNotMatched] = useState(false);
  const [empty, setEmpty] = useState(false);
  const handleSecurityPhrase = (e) => {
    const targetName = e.target.name;

    if (newPhrase !== confirmPhrase) {
      setNotMatched(true);
      setTimeout(() => {
        setNotMatched(false);
      }, 2000);
    } else {
      if (newPhrase === confirmPhrase) {
        if (targetName == "update") {
          if (!currentPhrase || !newPhrase || !confirmPhrase) {
            setEmpty(true);
            setTimeout(() => {
              setEmpty(false);
            }, 2000);
          } else {
            SecurityPhraseApi.edit_phrase({
              passphrase: {
                passphrase: confirmPhrase,
                old_passphrase: currentPhrase,
              },
            })
              .then((res) => {
                if (res.data.message == "couldnt find old password") {
                  setInvalidCurrent(true);
                  setTimeout(() => {
                    setInvalidCurrent(false);
                  }, 2000);
                } else {
                  setNewPhrase("");
                  setConfirmPhrase("");
                  setCurrentPhrase("");
                  setIsUpdate(true);
                  setTimeout(() => {
                    setIsUpdate(false);
                  }, 2000);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        } else {
          if (!newPhrase || !confirmPhrase) {
            setEmpty(true);
            setTimeout(() => {
              setEmpty(false);
            }, 2000);
          } else {
            SecurityPhraseApi.create_phrase({
              passphrase: {
                passphrase: newPhrase,
                old_passphrase: currentPhrase,
              },
            })
              .then((res) => {
                setOpen(false);
                setNewPhrase("");
                setConfirmPhrase("");
                SecurityPhraseApi.get_current_phrase()
                  .then((res) => {
                    dispatch(
                      setSecurityEnabled({
                        security: res.data,
                      })
                    );
                  })
                  .catch((err) => {
                    console.log(err);
                  });

               
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      }
    }
  };

  return (
    <>
      <button
        className="primary-btn"
        onClick={handleOpen}
      >
        <i class="fas fa-lock me-1"></i>
        {phraseActive === true
          ? "Update Security Phrase"
          : "Create Security Phrase"}
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="security-phrase-popup">
          <div className="security-phrase-main">
            <div className="security-phrase-inner">
              <div className="security-phrase-content">
                <p className="white">
                  {phraseActive === true ? "Update " : "Create "}a security
                  phrase
                </p>
                {phraseActive == true && (
                  <div className="security-phrase width">
                    <input
                      className="security-phrase-inputs form-control"
                      type="text"
                      placeholder="Enter current security phrase"
                      value={currentPhrase}
                      onChange={(e) => {
                        setCurrentPhrase(e.target.value);
                      }}
                    />
                  </div>
                )}

                <div className="security-phrase width">
                  <input
                    className="security-phrase-inputs form-control"
                    type="text"
                    placeholder="Enter new security phrase"
                    value={newPhrase}
                    onChange={(e) => {
                      setNewPhrase(e.target.value);
                    }}
                  />
                </div>
                <div className="security-phrase width">
                  <input
                    className="security-phrase-inputs form-control"
                    type="text"
                    placeholder="Confirm new security phrase"
                    value={confirmPhrase}
                    onChange={(e) => {
                      setConfirmPhrase(e.target.value);
                    }}
                  />
                </div>
                <div className="security-phrase width justified mb-0">
                  <button
                    name={phraseActive === true ? "update" : "create"}
                    style={{
                      padding: "10px 25px",
                    }}
                    className="buttons-primary"
                    onClick={handleSecurityPhrase}
                  >
                    Confirm
                  </button>
                </div>
                <div
                  
                  className="alert-container mt-2"
                >
                  {invalidCurrent && (
                    <Alert type="error" message="Invalid current phrase." />
                  )}
                  {isUpdate && (
                    <Alert
                      type="success"
                      message="Phrase Updated Successfully"
                    />
                  )}
                  {notMatched && (
                    <Alert
                      type="error"
                      message="Security phrase didn't matched"
                    />
                  )}
                  {empty && (
                    <Alert type="error" message="Fields must be filled" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
