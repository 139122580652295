import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    bets: [],
    total_bet_amount: 0,
    is_loading: false,
    error: null,
};
const betSlice = createSlice({
    name: "bets",
    initialState,
    reducers: {
        fetchBetData: (state, action) => {
            state.is_loading = true;
            state.error = null;
        },
        setAllBetsData: (state, action) => {
            state.bets = action.payload.bets;
            state.is_loading = true;
            state.error = null;
        },
        setSingleBet: (state, action) => {
            const data = action.payload.data;
            state.bets.push(data);
        },
        setTotalBetAmount: (state, action) => {
            state.total_bet_amount = action.payload.total_bet_amount
        },
        updateSingleBet: (state, action) => {
            const data = action.payload.data;
            let index = state.bets.findIndex((bet) => bet.user_id === data.user_id);
            state.bets[index] = data;
        },
        updateBetsForDisplay: (state, action) => {
            state.bets.map((bet) => {
                if (bet.cash_out == 0) {
                    bet.cash_out = "bang";
                    bet.profit = bet.amount;
                }
                return bet;
            });
        },
        clearBetData: (state, action) => {
            state.bets = [];
            state.is_loading = false;
            state.error = null;
        },
    },
});
export const {
    fetchBetData,
    setAllBetsData,
    setSingleBet,
    clearBetData,
    updateSingleBet,
    updateBetsForDisplay,
    setTotalBetAmount
} = betSlice.actions;

export default betSlice.reducer;
