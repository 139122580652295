import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  total_players: 0,
};

const playerSlice = createSlice({
  name: "players",
  initialState,
  reducers: {
    fetchPlayersData: (state, action) => {
      state.total_players = action.payload.total_player;
    },
  },
});

export const { fetchPlayersData } = playerSlice.actions;

export default playerSlice.reducer;
