import { useState, useEffect, useContext } from "react";
import { QRCodeCanvas } from "qrcode.react";

import PoolAmountAPI from "../api/pool_amount";
import Slider from "react-slick";

import "./PoolOperations.css";
import WithdrawComponent from "./WithdrawComponent";
import BasicAlerts from "./Alert";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PoolOperations = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const [deposit, setDeposit] = useState(true);
  const [withdraw, setWIthdraw] = useState(false);

  const [wallets, setWallets] = useState([]);
  useEffect(() => {
    PoolAmountAPI.getCryptoPool().then((res) => {
      setWallets(res.data.pool_operations);
    });
  }, []);

  //   const wallets = useSelector((state) => state.user.wallets);

  const [optionState, setOptionState] = useState("ethereum");
  const [activated, setActivated] = useState(null);
  const [imgURL, setImgURL] = useState(
    "/img/leftbaricon/user-wallets-white.png"
  );

  const [Qr, setQr] = useState("");
  const [PublicKey, setPublicKey] = useState("null");
  const [copy, setCopy] = useState(false);
  const [ammountSent, setAmmountSet] = useState(false);
  const [failed, setFaild] = useState(false);
  const [Polygon, setPolygon] = useState(true);
  const [Solana, setSolana] = useState(false);
  const [Ether, setEther] = useState(false);
  const [Tron, setTron] = useState(false);
  const role = useSelector((state) => state.auth.role);

  const PolygonWallet = wallets.find(
    (currency) => currency.network_id == "polygon"
  );

  const SolanaWallet = wallets.find(
    (currency) => currency.network_id == "solana"
  );

  const EthereumWallet = wallets.find(
    (currency) => currency.network_id == "ethereum"
  );

  const TronWallet = wallets.find((currency) => currency.network_id == "tron");

  const handlePolygon = () => {
    setPolygon(true);
    setSolana(false);
    setEther(false);
    setTron(false);
  };

  const handleSolana = () => {
    setSolana(true);
    setPolygon(false);
    setEther(false);
    setTron(false);
  };
  const handleEther = () => {
    setEther(true);
    setPolygon(false);
    setSolana(false);
    setTron(false);
  };
  const handleTron = () => {
    setTron(true);
    setPolygon(false);
    setSolana(false);
    setEther(false);
  };

  useEffect(() => {
    setActivated(
      wallets.find((currency) => currency.network_id == optionState)
    );
  }, [wallets, optionState]);

  useEffect(() => {
    setPublicKey(activated?.public_key);
  }, [activated, optionState]);

  const enableOperation = (e) => {
    const name = e.target.name;
    if (name === "deposit") {
      setDeposit(true);
      setWIthdraw(false);
    } else {
      setDeposit(false);
      setWIthdraw(true);
    }
  };

  useEffect(() => {
    if (optionState === "ethereum") {
      setImgURL("/img/AcceptedNetworks/ethClr.png");
    }
    if (optionState === "polygon") {
      setImgURL("/img/AcceptedNetworks/polyClr.png");
    }
    if (optionState === "tron") {
      setImgURL("/img/AcceptedNetworks/trxClr.png");
    }
    if (optionState === "solana") {
      setImgURL("/img/AcceptedNetworks/solClr.png");
    }
    if (optionState === "bitcoin") {
      setImgURL("/img/AcceptedNetworks/btcClr.webp");
    }
    if (optionState === "usdt") {
      setImgURL("/img/AcceptedNetworks/usdt.webp");
    }
    if (optionState === "bcw") {
      setImgURL("/img/AcceptedNetworks/Bcw.webp");
    }
  }, [optionState]);

  const dropdownHandle = (e) => {
    setOptionState(e.target.value);
    setCopy(false);
  };

  const handleCopy = (account) => {
    navigator.clipboard.writeText(account);
    setCopy(true);
  };
  const handleClickSelect = (e) => {
    setOptionState(e.target.name);
    setCopy(false);
  };

  useEffect(() => {
    if (optionState === "usdt" && Polygon === true) {
      setQr(PolygonWallet.public_key);
      // alert("Polygon True");
    }
    if (optionState === "usdt" && Solana === true) {
      setQr(SolanaWallet.public_key);
      // alert("Solana True");
    }
    if (optionState === "usdt" && Ether === true) {
      setQr(EthereumWallet.public_key);
      // alert("Ether True");
    }
    if (optionState === "usdt" && Tron === true) {
      setQr(TronWallet.public_key);
      // alert("Tron True");
    }
  }, [optionState, Polygon, Solana, Ether, Tron]);
  //   return null;
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
        className="page-divide"
      >
        <div className="pool-operators-container width">
          <div className="content">
            <div className="container-fluid pt-4 px-4">
              <div className="page-title">
                <div
                  style={{ width: "80%" }}
                  className="alert-container absolute"
                >
                  {failed && (
                    <BasicAlerts type="error" message="Withdrawal Failed" />
                  )}
                  {ammountSent && (
                    <BasicAlerts
                      type="success"
                      message="Withdrawal In Process"
                    />
                  )}
                </div>

                <h1>Pool Operations</h1>
              </div>
              <div className="row g-4 justify-content-center">
                {role === "super_admin" && (
                  <div className="col-sm-5 col-xl-4">
                    <div className="dashboard-summary">
                      <div className="dashboard-summary-arrow">
                        <p className="text">Pool Operations</p>
                      </div>

                      <div
                        style={{
                          padding: "0 30px",
                        }}
                        className="pool-operation-buttons-container width my_row space-between"
                      >
                        <button
                          type="button"
                          name="deposit"
                          className={`btn pool-operations ${
                            deposit && "active-button"
                          }`}
                          onClick={enableOperation}
                        >
                          Deposit
                        </button>
                        <button
                          type="button"
                          name="withdraw"
                          className={`btn pool-operations ${
                            withdraw && "active-button"
                          }`}
                          onClick={enableOperation}
                        >
                          Withdraw
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <div className="operation-container">
                  {/* <QRCodeCanvas
              id="qrCode"
              value={account.public_key}
              size={135}
              bgColor={"#fff"}
              level={"H"}
            /> */}
                  {deposit && (
                    <div className="pool-operations-container width">
                      <div
                        style={{
                          width: "80%",
                          margin: "0 auto",
                        }}
                        className="wallet-crypto-main"
                      >
                        <div className="wallet-crypto-content">
                          <div
                            className="wallet-crypto-coins my_row"
                            style={{
                              flexWrap: "nowrap",
                              gap: "5px",
                              justifyContent: "space-between",
                              overflowX: "auto",
                            }}
                          >
                            {wallets.map((coin, index) => (
                              <div
                                key={index}
                                style={{
                                  background:
                                    activated?.network_id == coin.network_id
                                      ? "#404040"
                                      : "rgb(28 30 34)",
                                  gap: "5px",
                                  padding: "15px  ",
                                  borderRadius: "5px",
                                  width: "100%",
                                  flexWrap: "nowrap",
                                }}
                                className={`${coin.network_id}-coin my_row DepCoinRow justified pointer relative`}
                              >
                                <div className="coin-img-container depCoin-img my_row justify">
                                  <img
                                    src={
                                      coin.network_id == "solana"
                                        ? "/img/AcceptedNetworks/solClr.png"
                                        : coin.network_id == "ethereum"
                                        ? "/img/AcceptedNetworks/ethClr.png"
                                        : coin.network_id == "tron"
                                        ? "/img/AcceptedNetworks/trxClr.png"
                                        : coin.network_id == "wb"
                                        ? "/img/AcceptedNetworks/BetCoin.png"
                                        : coin.network_id == "bitcoin"
                                        ? "/img/AcceptedNetworks/btcClr.webp"
                                        : coin.network_id == "polygon"
                                        ? "/img/AcceptedNetworks/polyClr.png"
                                        : coin.network_id == "usdt"
                                        ? "/img/AcceptedNetworks/usdt.webp"
                                        : coin.network_id == "bcw"
                                        ? "/img/AcceptedNetworks/Bcw.webp"
                                        : ""
                                    }
                                    alt={coin.network_id}
                                  />
                                </div>
                                <div
                                  className={`${coin.network_id}-name-conainer depCoin-name my_row justify`}
                                >
                                  <span
                                    style={{
                                      textTransform: "uppercase",
                                    }}
                                    className="white bold"
                                  >
                                    {coin.network_id == "solana"
                                      ? "sol"
                                      : coin.network_id == "ethereum"
                                      ? "ETH"
                                      : coin.network_id == "tron"
                                      ? "trx"
                                      : coin.network_id == "wb"
                                      ? "wb"
                                      : coin.network_id == "bitcoin"
                                      ? "btc"
                                      : coin.network_id == "polygon"
                                      ? "matic"
                                      : coin.network_id == "usdt"
                                      ? "usdt"
                                      : coin.network_id == "bcw"
                                      ? "bcw"
                                      : ""}
                                  </span>
                                </div>
                                <button
                                  name={coin.network_id}
                                  onClick={handleClickSelect}
                                  style={{
                                    height: "100%",
                                    top: "0",
                                    left: "0",
                                    background: "transparent",
                                    border: "none",
                                  }}
                                  className="absolute width"
                                ></button>
                              </div>
                            ))}
                          </div>

                          <div className="wallet-crypto-coins-dropdown">
                            <div className="wallet-crypto-coins-dropdown-content">
                              <div className="wallet-crypto-dropdown-top my_row space-between">
                                <span className="text_color">
                                  Depost Currency
                                </span>
                              </div>

                              <div className="wallet-crypto-dropdown-bottom width">
                                <div className="wallet-coin-dropdown width-45">
                                  <form className="relative">
                                    <div className="dropDown-Coin-Img absolute">
                                      <img src={imgURL} alt="" />
                                    </div>
                                    <select
                                      style={{
                                        textTransform: "uppercase",
                                      }}
                                      className="coinsSelect"
                                      name="coins"
                                      id="coins"
                                      value={optionState}
                                      onChange={dropdownHandle}
                                    >
                                      {wallets.map((currency, index) => {
                                        return (
                                          <option
                                            key={index}
                                            style={{
                                              textTransform: "uppercase",
                                            }}
                                            value={currency.network_id}
                                          >
                                            {currency.network_id == "polygon"
                                              ? "Matic"
                                              : currency.network_id}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </form>
                                </div>
                              </div>

                              {activated?.network_id === "usdt" && (
                                <div className="wallet-crypto-network-container">
                                  <div className="wallet-crypto-network-content">
                                    <div className="network-wallet-crypto-top">
                                      <span className="text_color">
                                        Choose Network
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        margin: "20px 0",
                                      }}
                                      className="network-wallet-crypto-bottom"
                                    >
                                      <div className="crypto-wallet-network-slider">
                                        <Slider {...settings}>
                                          <div
                                            className={`crypto-wallet-network justified ${
                                              Polygon && "activeNetworkButton"
                                            }`}
                                            onClick={handlePolygon}
                                          >
                                            <span
                                              style={{
                                                textTransform: "uppercase",
                                              }}
                                              className={`${
                                                Polygon
                                                  ? "white"
                                                  : "secondary_text"
                                              } bold`}
                                            >
                                              Polygon
                                            </span>
                                          </div>
                                          <div
                                            className={`crypto-wallet-network justified ${
                                              Solana && "activeNetworkButton"
                                            }`}
                                            onClick={handleSolana}
                                          >
                                            <span
                                              style={{
                                                textTransform: "uppercase",
                                              }}
                                              className={`${
                                                Solana
                                                  ? "white"
                                                  : "secondary_text"
                                              } bold`}
                                            >
                                              Solana
                                            </span>
                                          </div>
                                          <div
                                            className={`crypto-wallet-network justified ${
                                              Ether && "activeNetworkButton"
                                            }`}
                                            onClick={handleEther}
                                          >
                                            <span
                                              style={{
                                                textTransform: "uppercase",
                                              }}
                                              className={`${
                                                Ether
                                                  ? "white"
                                                  : "secondary_text"
                                              } bold`}
                                            >
                                              ETHEREUM
                                            </span>
                                          </div>
                                          <div
                                            className={`crypto-wallet-network justified ${
                                              Tron && "activeNetworkButton"
                                            }`}
                                            onClick={handleTron}
                                          >
                                            <span
                                              style={{
                                                textTransform: "uppercase",
                                              }}
                                              className={`${
                                                Tron
                                                  ? "white"
                                                  : "secondary_text"
                                              } bold`}
                                            >
                                              TRON
                                            </span>
                                          </div>
                                        </Slider>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="wallet-coint-qr-container">
                            <div
                              style={{
                                justifyContent: "space-around",
                              }}
                              className="wallet-qr-content-container my_row"
                            >
                              <div className="wallet-qr-typo-container">
                                <div className="qr-deposit-adress">
                                  <span className="text_color">
                                    Deposit Address
                                  </span>
                                </div>
                                <div className="qr-hash-container">
                                  <span>
                                    {optionState === "usdt" && Polygon === true
                                      ? Qr
                                      : optionState === "usdt" &&
                                        Solana === true
                                      ? Qr
                                      : optionState === "usdt" && Ether === true
                                      ? Qr
                                      : optionState === "usdt" && Tron === true
                                      ? Qr
                                      : PublicKey}
                                  </span>
                                </div>
                                <div className="qr-copy-button-conainer">
                                  <button
                                    className="primary-btn"
                                    style={{
                                      background: copy
                                        ? "linear-gradient(0deg, #529c18 0%, #a9c45d 100%)"
                                        : "linear-gradient(0deg, #18599c 0%, #5d8fc4 100%)",
                                      padding: "10px 20px",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      handleCopy(
                                        optionState === "usdt" &&
                                          Polygon === true
                                          ? Qr
                                          : optionState === "usdt" &&
                                            Solana === true
                                          ? Qr
                                          : optionState === "usdt" &&
                                            Ether === true
                                          ? Qr
                                          : optionState === "usdt" &&
                                            Tron === true
                                          ? Qr
                                          : PublicKey
                                      );
                                    }}
                                  >
                                    {copy ? "Copied" : "Copy"}
                                  </button>
                                  {/* <CopySnackBar wallet_adress={activated.bc_account}/> */}
                                </div>
                              </div>
                              <div
                                style={{
                                  background: "#fff",
                                }}
                                className="wallet-qr-qr-code-container"
                              >
                                <QRCodeCanvas
                                  id="qrCode"
                                  value={
                                    optionState === "usdt" && Polygon === true
                                      ? Qr
                                      : optionState === "usdt" &&
                                        Solana === true
                                      ? Qr
                                      : optionState === "usdt" && Ether === true
                                      ? Qr
                                      : optionState === "usdt" && Tron === true
                                      ? Qr
                                      : PublicKey
                                  }
                                  size={135}
                                  bgColor={"#fff"}
                                  level={"H"}
                                />

                                {/* <img src={QR} alt="QR Code" /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {withdraw && (
                    <WithdrawComponent
                      ammountSent={ammountSent}
                      setAmmountSet={setAmmountSet}
                      failed={failed}
                      setFaild={setFaild}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PoolOperations;
