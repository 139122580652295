import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import WithdrawalsAPI from "../api/withdrawal";
import WithdrawalLimitApi from "../api/withdrawal_limit";
import { useSelector } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Tooltip } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomDate from "./sub_components/date";

const Withdrawals = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const [dataToShow, setDataToShow] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [withdrawalLimit, setWithdrawalLimit] = useState(0);
  const [userWithdrawalLimit, setUserWithdrawalLimit] =
    useState(withdrawalLimit);
  const [limitErr, setLimitSet] = useState(false);
  const [settingLimit, setSettingLimit] = useState(false);
  const [tempPage, setTempPage] = useState(pageNumber);
  const [filter, setFilter] = useState(false);
  const [filterId, setFilterId] = useState("");
  const [idPredicate, setIdPredicate] = useState("eq");
  const [filterUsername, setFilterUsername] = useState("");
  const [usernamePredicate, setUsernamePredicate] = useState("contains");
  const [filterNetwork, setFilterNetwork] = useState("");
  const [networkPredicate, setNetworkPredicate] = useState("cont_all");
  const [filterHash, setFilterHash] = useState("");
  const [hashPredicate, setHashPredicate] = useState("contains");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalData, setTotalData] = useState("");
  const [filterToken, setFilterToken] = useState("");
  const [tokenPredicate, setTokenPredicate] = useState("not_null");
  useEffect(() => {
    WithdrawalLimitApi.get_withdrawal_limit()
      .then((res) => {
        setWithdrawalLimit(res.data.auto_withdrawal_limit);
        setUserWithdrawalLimit(res.data.auto_withdrawal_limit);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setDataLoading(true);
    WithdrawalsAPI.getWithdrawals(
      pageNumber,
      filter,
      idPredicate,
      filterId,
      usernamePredicate,
      filterUsername,
      filterNetwork,
      networkPredicate,
      filterHash,
      hashPredicate,
      filterToken,
      tokenPredicate,
      startDate,
      endDate
    )

      .then((response) => {
        setDataToShow(response.data.list);
        setTotalPages(response.data.total_pages);
        setDataLoading(false);
        setTotalData(response.data.total_count);
      })
      .catch((error) => {
        console.log(error);
      })
      .catch((error) => console.log(error));
  }, [
    pageNumber,
    filter,
    filter && idPredicate,
    filter && filterId,
    filter && usernamePredicate,
    filter && filterUsername,
    filter && filterNetwork,
    filter && networkPredicate,
    filter && filterHash,
    filter && hashPredicate,
    filter && filterToken,
    filter && tokenPredicate,
    filter && startDate,
    filter && endDate,
  ]);

  const increasePage = () => {
    if (tempPage < totalPages) {
      setPageNumber(parseFloat(pageNumber) + 1);
      setTempPage(pageNumber);
      setTempPage(parseFloat(tempPage) + 1);
    }
  };

  const decrPage = () => {
    if (tempPage > 1) {
      setPageNumber(parseFloat(pageNumber) - 1);
      setTempPage(pageNumber);
      setTempPage(parseFloat(tempPage) - 1);
    }
  };
  const handleChange = (event) => {
    setTempPage(event.target.value);
  };

  const assignPage = (e) => {
    if (e.key === "Enter") {
      setPageNumber(e.target.value);
    }
  };

  const handleWithdrawalLimit = (e) => {
    e.preventDefault();

    if (userWithdrawalLimit !== withdrawalLimit) {
      setSettingLimit(true);
      WithdrawalLimitApi.set_withdrawal_limit({
        general_configuration: {
          auto_withdrawal_limit: userWithdrawalLimit,
        },
      })
        .then((res) => {
          setSettingLimit(false);
          setUserWithdrawalLimit(res.data.auto_withdrawal_limit);
        })
        .catch((err) => {
          setSettingLimit(false);

          setLimitSet(true);
        });
    }
  };
  const tokenStatus = () => {
    if (tokenPredicate === "not_false") {
      setFilterToken(1);
    } else if (tokenPredicate === "false") {
      setFilterToken(1);
    } else if (tokenPredicate === "in") {
      setFilterToken([0,1]);
    }
  };
  // const divStyle = {

  //   margin-top: '31px',
  // }

  return (
    <div className="content">
      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="page-title">
          <h1>Withdrawals</h1>
        </div>
        <div className="page-filters">
          <div className="row">
            <div className="col-xl-3 col-lg-6">
              <label htmlFor="basic-url" className="form-label">
                Id
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={idPredicate}
                  onChange={(e) => {
                    setIdPredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="gteq">Greater & =</option>
                  <option value="lteq">Less & =</option>
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterId}
                  onChange={(e) => {
                    setFilterId(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <label htmlFor="basic-url" className="form-label">
                Username
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={usernamePredicate}
                  onChange={(e) => {
                    setUsernamePredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="contains">contains</option>
                  <option value="start">start</option>
                  <option value="end">end</option>
                  {/* <option value="lteq">Less & =</option> */}
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterUsername}
                  onChange={(e) => {
                    setFilterUsername(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <label htmlFor="basic-url" className="form-label">
                Network Id
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={networkPredicate}
                  onChange={(e) => {
                    setNetworkPredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="cont_all">contains</option>
                  <option value="start">start</option>
                  <option value="end">end</option>
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterNetwork}
                  onChange={(e) => {
                    setFilterNetwork(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <label htmlFor="basic-url" className="form-label">
                Tx Hash
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text"
                  id="basic-addon3"
                  value={hashPredicate}
                  onChange={(e) => {
                    setHashPredicate(e.target.value);
                  }}
                >
                  <option value="eq">=</option>
                  <option value="contains">contains</option>
                  {/* <option value="lteq">Less & =</option> */}
                </select>
                <input
                  type="text"
                  className="form-control white"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={filterHash}
                  onChange={(e) => {
                    setFilterHash(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <label htmlFor="basic-url" className="form-label">
                Created At
              </label>
              <div className="input-group mb-3">
                {" "}
                <DatePicker
                  placeholderText="From"
                  selected={startDate}
                  placeholder="from"
                  onChange={(date) => setStartDate(date ? date : "")}
                />
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <label htmlFor="" className="form-label">
                &nbsp;
              </label>
              <div className="input-group mb-3">
                {" "}
                <DatePicker
                  placeholderText="To"
                  selected={endDate}
                  onChange={(date) => setEndDate(date ? date : "")}
                />
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <label htmlFor="basic-url" className="form-label">
                Token
              </label>
              <div className="input-group mb-3">
                <select
                  className="input-group-text form-control"
                  id="basic-addon3"
                  value={tokenPredicate}
                  onChange={(e) => {
                    setTokenPredicate(e.target.value);
                    tokenStatus();
                  }}
                >
                  <option value="in">Any</option>
                  <option value="not_false">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <label htmlFor="" className="form-label">
                &nbsp;
              </label>
              <div>
              <Tooltip
                title={filter ? "Filter Enabled" : "Filter Disabled"}
                placement="right"
                arrow
              >
                <button
                  className={`${filter ? "primary-btn-success" : "primary-btn-disable"} relative primary-btn mb-3`}
                  style={{
                    padding: "8px 11px 7px",
                  }}
                  type="button"
                  onClick={() => {
                    filter ? setFilter(false) : setFilter(true);
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      background: filter ? "rgb(59 193 23)" : "rgb(237, 99, 0)",
                      borderRadius: "50%",
                      right: "-3px",
                      top: "-3px",
                    }}
                    className="filter-status-indicator absolute"
                  ></div>
                  {filter ? "Active" : "Inactive"}
                </button>
              </Tooltip>
              </div>
            </div>
          </div>
          <div className="mb-3">
            Total Withdrawals {totalData}
          </div>
        </div>
        <div className="withdraw-ammount-container">
          <div
            style={{
              margin: ".375rem 1.625rem .375rem 1.25rem",
              fontSize: "!2px",
            }}
            className="withdraw-amount text_color my_row space-between m-0 mb-3"
          >
            <span
              style={{
              }}
            >
              Set User Withdrawal Limit
            </span>
          </div>
          <div className="withdraw-amount-field relative">
            <form className="d-flex mb-4">
              <input
                style={{
                  background: "#17181b",
                  border: "none",
                  boxSizing: "border-box",
                  padding: "20px 209px 20px 30px",
                  width: "80%",
                  position: "relative",
                }}
                className="bold white form-control"
                type="number"
                value={userWithdrawalLimit !== 0 && userWithdrawalLimit}
                placeholder={withdrawalLimit ? "" : "No Limit Set"}
                onChange={(e) => {
                  setUserWithdrawalLimit(e.target.value);
                }}
              />

              <span
                style={{
                  fontWeight: "bold",
                  color: "#fff",
                  position: "absolute",
                  left: "10px",
                  top: "32px",
                  transform: "translate(1px, -12px)",
                }}
              >
                $
              </span>
              {settingLimit ? (
                <button
                  className="buttons-primary"
                  style={{
                    width: "20%",
                    padding: "20px 0 20px 0",
                    border: "none",
                  }}
                  onClick={handleWithdrawalLimit}
                >
                  <div
                    style={{
                      top: "-40px",
                      height: "5px",
                    }}
                    className="lds-ellipsis"
                  >
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </button>
              ) : limitErr ? (
                <button
                  className="primary-btn primary-btn-danger"
                  style={{
                    width: "20%",
                    padding: "20px 0 20px 0",
                    border: "none",
                  }}
                >
                  Failed
                </button>
              ) : (
                <button
                  className="buttons-primary"
                  style={{
                    width: "20%",
                    padding: "20px 0 20px 0",
                    border: "none",
                  }}
                  onClick={handleWithdrawalLimit}
                >
                  Set Limit
                </button>
              )}
            </form>
          </div>
        </div>

        <div className="text-center rounded p-4 table-bg">
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style table-bet-rounds">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Username</th>
                  <th scope="col">Network ID</th>
                  <th scope="col">Token</th>
                  <th scope="col">Wallet Address</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Status</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {dataLoading ? (
                  <tr>
                    <td colspan="9">
                      <div className="loader-container">
                        <div className="lds-ring">
                          <div></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  dataToShow.map((obj, index) => {
                    return (
                      <tr key={index}>
                        <td scope="col">{obj.id}</td>
                        <td>{obj.username}</td>
                        <td>{obj.network_id}</td>
                        <td>{obj.token ? "USDT" : "False"}</td>
                        <td><span className="hash-key-scroll">{obj.public_key}</span></td>
                        <td>{obj.status === "failed" ? "" : obj.amount}</td>
                        <td>
                          <span
                            style={{
                              color:
                                obj.status === "pending" ||
                                obj.status === "failed"
                                  ? "red"
                                  : "white",
                            }}
                          >
                            {obj.status}
                          </span>
                        </td>
                        <td>
                          <CustomDate date={obj.created_at} />
                        </td>
                        <td>
                          <Link
                            to={`/withdrawal-details/${obj.id}`}
                            className="view primary-btn"
                          >
                            View
                          </Link>
                          <Link
                            to={`/withdrawal-edits/${obj.id}`}
                            className="view primary-btn"
                          >
                            Edit
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            <div className="pagination-buttons-container width my_row end">
              <div className="next-pre-btn my_row align_center space-between width-15">
                <div className="pre-btn">
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                    className="white"
                    onClick={decrPage}
                  >
                    <ArrowBackIosNewIcon />
                  </button>
                </div>
                <span className="white">
                  <input
                    type="number"
                    value={tempPage}
                    style={{
                      maxWidth: "45px",
                      heigh: "10px",
                      background: "transparent",
                      border: "none",
                      color: "white",
                      textAlign: "center",
                    }}
                    onChange={handleChange}
                    onKeyPress={assignPage}
                  />
                  / {totalPages}
                </span>
                <div className="next-btn">
                  <button
                    className="white"
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                    onClick={increasePage}
                  >
                    <ArrowForwardIosIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // );
};

export default Withdrawals;
