import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import authReducer from "./auth/index";
import betReducer from "./bet/index";
import phraseReducer from "./security_phrase/index";
import notificationsReducer from "./notifications/index";
import playerReducer from "./player";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  bets: betReducer,
  phrase: phraseReducer,
  notifications: notificationsReducer,
  players: playerReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
const persistor = persistStore(store);
const unsubscribe = store.subscribe(() => {});
export { store, persistor };
