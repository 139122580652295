import axiosClient from ".";

class FiatWithdrawAPI {
  static getWithdrawals() {
    return axiosClient.get("/super_admin/api/withdrawal_fiats.json");
  }
  static viewWithdrawal(id) {
    return axiosClient.get(`/super_admin/api/withdrawal_fiats/${id}.json`);
  }
  static withdrawalStatus(id,data) {
    return axiosClient.put(`/super_admin/api/withdrawal_fiats/${id}.json`,data);
  }

}
export default FiatWithdrawAPI;